import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CircularWithValueLabel from '../../../components/CirculeBar/CirculeBar';

import Timer from "../../../herramientas/Time/setTimer";






function Row(props) {
    console.log(props)
    
  const {row,bandera} = props;
  const [open, setOpen] = React.useState(false);
  let totalRegistrado = 0;
  let totalDisponible = 0;
  let ocupacion = 0;

 
    row.remolquesTransportistas.forEach(element => {
      if(element.disponibilidadRemolques){
      totalRegistrado += element.disponibilidadRemolques.total_registrado;
      totalDisponible += element.disponibilidadRemolques.total_disponible;
      
      }
    });  

    if(totalRegistrado > 0){
        ocupacion = (totalRegistrado - totalDisponible) * 100 / (totalRegistrado)
    }


  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" component="th" scope="row">
          {row.corredor.nombre}
        </TableCell>
        <TableCell align="center">{totalRegistrado}</TableCell>
        <TableCell align="center">
          
          <CircularWithValueLabel value={ocupacion} />
          
          </TableCell>
       
        <TableCell align="right">
       
        <div class="background">	
		
			
    
		
	</div>	
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Tipo Remolque</TableCell>
                    <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Registrado</TableCell>
                    <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Disponibles</TableCell>
                    <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Apartados</TableCell>
                    <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Ocupados</TableCell>
                    <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Tipo</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.remolquesTransportistas.map((historyRow) => (
                    <TableRow key={historyRow.idRemolque.idRemolque}>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center" component="th" scope="row">
                        {historyRow.idRemolque.nombre}
                      </TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">{historyRow.disponibilidadRemolques? historyRow.disponibilidadRemolques.total_registrado : 0}</TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">{historyRow.disponibilidadRemolques? historyRow.disponibilidadRemolques.total_disponible : 0}</TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                      {historyRow.disponibilidadRemolques? historyRow.disponibilidadRemolques.total_apartado : 0 }
                      </TableCell>

                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                      {historyRow.disponibilidadRemolques? historyRow.disponibilidadRemolques.total_ocupado : 0 }
                      </TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                        {historyRow.tipoCorredor == "L" ? "Local" : "Foraneo"  }
                      </TableCell>
                    
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}






export default function Metricas(props) {
    console.log(props)

  const time = new Date();
  const [page, setPage] = React.useState(0);
  const [bandera, setBandera] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleFieldChange = (bandera) => {
    setBandera(bandera)
  };


  time.setHours(props.temporizador);
  time.setMinutes(0);
  time.setSeconds(0);
  
  return (
    <Paper sx={{ width: '100%' }}>
        {props.stor.props[0].nombreCorto}
    <TableContainer sx={{ maxHeight: 440 }}  >
      <Table  aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} />
            {props.stor.heads.map((head,i) => (
                 i == 0  ? 
            <TableCell sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center" >{head}</TableCell>
            : 
            <TableCell  sx={{boxShadow: "rgb(86 166 216) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">{head}</TableCell>
        ))}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {props.stor.props.map((row) => (
            <Row key={row.corredor.id} row={row} bandera={bandera} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     <TablePagination
     rowsPerPageOptions={[5, 10, 20]}
     component="div"
     count={props.stor.props.length}
     rowsPerPage={rowsPerPage}
     page={page}
     onPageChange={handleChangePage}
     onRowsPerPageChange={handleChangeRowsPerPage}
   />

<div align="center">


                 
                </div>
    </Paper>
  );
}