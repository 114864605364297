import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from '../../../../herramientas/Crud_Catalogos';
import { Modelo, NumerosInternos } from ".";

const Chatbot =({ plataforma, stor })=> {
  const [whatsappConfiguracion, setWhatsappConfiguracion]= useState({});
  const [tab, setTab]= useState(1);

  useEffect(() => {
    getfuntion("get", "", "", "whatsapp_configuracion/plataforma/"+plataforma.id,"whatsapp_configuracion");
  }, []);

  const getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "correoPlantillas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            if( stateVar === "whatsapp_configuracion"){
              setWhatsappConfiguracion(returnVal);
            }
            break;
          default:
            break;
        }
      }).catch(err =>{ console.log("Error desconocido ....");  })
  }
  
  return (
    <div>
      {/**
       <WhatsApp
        accion={ this.props.accion }
        elementos= { elementos }
        getfuntion= { this.props.getfuntion}
        stor= { this.props.stor }
        getCorreoPlataforma={ this.state.correoPlataforma }
        />
       */}
      { whatsappConfiguracion.id?
        <div>
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col"></div>
                  <div className="col">
                    <ul className="nav nav-tabs">                      
                      <li className="nav-item">
                        <a className={"nav-link"+ (tab === 2 ? " active":"")} onClick={()=>{ setTab(1) }} > Numeros internos</a>
                      </li>{/*
                      <li className="nav-item">
                        <a className={"nav-link"+ (tab === 1 ? " active":"")} onClick={()=> setTab(2)}>Modelos</a>
                      </li>*/}
                    </ul>
                </div>
                <div className="col"></div>
              </div>
              <div  hidden={tab !== 1}>
                <NumerosInternos
                  stor= { stor }
                  plataforma= { plataforma }
                  whatsappConfiguracion= { whatsappConfiguracion }
                />
              </div>
              {/*
              <div  hidden={tab !== 2}>
                <Modelo
                  stor= { stor }
                  plataforma= { plataforma }
                  whatsappConfiguracion= {whatsappConfiguracion}
                />
              </div>*/}
            </div>
          </div>
        </div>
        :
          <div>
            <h3> Esta plataforma no se encuentra configurada para envio de whatsApp</h3>
          </div>
      }
          
    </div>
  )
}

export default Chatbot
