import React, { useState, useEffect } from "react";
import Select from "react-select";

import FetchData from "./FetchData.react";
import CheckForm from "./CheckForm.react";

export const MenuDashboardNaviera = ({ setLoading, setError, setData, setFechaInicioPadre,setFechaFinPadre, setAlcance, _setPlataforma }) => {
	const idUsuario = JSON.parse(sessionStorage.getItem("currentUser"))[0].usuario.id;
	const idPerfil = JSON.parse(sessionStorage.getItem("currentUser"))[0].perfil.id;

	//Bloques
	const [bloques, setBloques] = useState([]);
	const [bloque, setBloque] = useState({ value: 0, label: "Seleccione un tipo de contador" });
	//Funcionalidades
	const [funcionalidades, setFuncionalidades] = useState([]);
	const [funcionalidad, setFuncionalidad] = useState({
		value: 0,
		label: "Seleccione un contador",
	});
  //Plataformas Navieras
  const [plataformasNavieras, setPlataformasNavieras] = useState([]);
  const [plataformasTN, setPlataformasTN] = useState([]);
  const [plataforma, setPlataforma] = useState({ value: 0, label: "Seleccione una plataforma" });
	//Transportistas
	const [transportista, setTransportista] = useState({
		value: 0,
		label: "Seleccione un transportista",
	});
	const [transportistas, setTransportistas] = useState([]);
	//Fechas
	const [fechaInicio, setFechaInicio] = useState([]);
	const [fechaFin, setFechaFin] = useState([]);
	//Variables check form
	const [errorBloque, setErrorBloque] = useState(false);
	const [errorFuncionalidad, setErrorFuncionalidad] = useState(false);
	const [errorPlataforma, setErrorPlataforma] = useState(false);
	const [errorTransportista, setErrorTransportista] = useState(false);
	const [errorFechaIni, setErrorFechaIni] = useState(false);
	const [errorFechaFin, setErrorFechaFin] = useState(false);

	useEffect(() => {
		FetchData(
			"bloque/usuario/" + idUsuario + "/perfil/" + idPerfil,
			"Bloques",
			setBloques,
			setLoading,
			setError
		);
	}, []);

	useEffect(() => {
		if (bloque.value != 0) {
			FetchData(
				"funcionalidades/bloque/" + bloque.value + "/perfil/" + idPerfil,
				"Funcionalidades",
				setFuncionalidades,
				setLoading,
				setError
			);
			setFuncionalidad({ value: 0, label: "Seleccione un contador " });
		}
	}, [bloque]);

	useEffect(() => {
		if (funcionalidad.value != 0) {
      FetchData(
        "plataformas/usuario/" + idUsuario + "/tipo/1",
        "Plataformas",
        setPlataformasNavieras,
        setLoading,
        setError
      );
      FetchData(
        "plataformas/usuario/" + idUsuario + "/tipo/3",
        "Plataformas",
        setPlataformasTN,
        setLoading,
        setError
      );
			setPlataforma({ value: 0, label: "Seleccione una plataforma" });
			setAlcance(funcionalidad.value);
		}
	}, [funcionalidad]);

	useEffect(() => {
		if (plataforma.value != 0) {
			FetchData(
				"transportistas/plataforma/" + plataforma.value,
				"Transportistas",
				setTransportistas,
				setLoading,
				setError
			);
			setTransportista({ value: 0, label: "Seleccione un transportista" });
			_setPlataforma(plataforma.value);
		}
	}, [plataforma]);

	const check = () => {
		CheckForm(
			{
				idUsuario,
				bloque,
				funcionalidad,
				plataforma,
				transportista,
				fechaInicio,
				fechaFin
			},
			{
				setData,
				setLoading,
				setError,
				setErrorBloque,
				setErrorFuncionalidad,
				setErrorPlataforma,
				setErrorTransportista,
				setErrorFechaIni,
				setErrorFechaFin,
			}
		);
	};

	return (
		<div>
			<div className="row" style={{ textAlign: "center" }}>
				<div className="col-12">
					<h1 style={{ marginTop: "0.5em" }}>Dashboard Naviera</h1>
				</div>
				<div className="col-4">
					<label className="form-label">Tipo de contador</label>
					<Select
						onChange={(opcion) => {
							setBloque(opcion);
						}}
						options={bloques}
						hideSelectedOptions
						value={bloque}
					/>
					{errorBloque ? (
						<div style={{ color: "#dc3545" }}>
							Elija un tipo de contador antes de continuar.
						</div>
					) : null}
				</div>
				<div className="col-4 ">
					<label htmlFor="fechaIni" className="form-label">
						Fecha Inicial
					</label>
					<input
						onChange={(opcion) => {
							setFechaInicio(opcion.target.value);setFechaInicioPadre(opcion.target.value)
						}}
						type="date"
						id="fechaIni"
						name="fechaIni"
					></input>
					{errorFechaIni ? (
						<div style={{ color: "#dc3545" }}>Elija una fecha inicial.</div>
					) : null}
				</div>
				<div className="col-4 ">
					<label htmlFor="fechaFin" className="form-label">
						Fecha fin
					</label>
					<input
						onChange={(opcion) => {
							var fecha = new Date(opcion.target.value);
							fecha.setDate(fecha.getDate() + 1);
							setFechaFin(fecha.toISOString().split('T')[0]);
							setFechaFinPadre(opcion.target.value)
						}}
						type="date"
						id="fechaFin"
						name="fechaFin"
					></input>
					{errorFechaFin ? (
						<div style={{ color: "#dc3545" }}>
							Elija una fecha fin posterior a la inicial.
						</div>
					) : null}
				</div>
			</div>

			{bloque.value != 0 ? (
				<div className="row mt-3" style={{ textAlign: "center" }}>
					<div className="col-4">
						<label className="form-label">Contador</label>
						<Select
							onChange={(opcion) => {
								setFuncionalidad(opcion);
							}}
							options={funcionalidades}
							hideSelectedOptions
							value={funcionalidad}
						/>
						{errorFuncionalidad ? (
							<div style={{ color: "#dc3545" }}>
								Elija un contador antes de continuar.
							</div>
						) : null}
					</div>

					{funcionalidad.value != 0 && !funcionalidad.label.includes("general") ? (
						<div className="col-4">
							<label className="form-label">Plataformas</label>
							<Select
								onChange={(opcion) => {
									setPlataforma(opcion);
								}}
								options={[...plataformasNavieras, ...plataformasTN]}
								hideSelectedOptions
								value={plataforma}
							/>
							{errorPlataforma ? (
								<div style={{ color: "#dc3545" }}>
									Elija una plataforma antes de continuar.
								</div>
							) : null}
						</div>
					) : null}

					{funcionalidad.value != 0 &&
					funcionalidad.label.includes("Tipos") &&
					funcionalidad.label.includes("línea de transporte") ? (
						<div className="col-4">
							<label className="form-label">Transportistas</label>
							<Select
								onChange={(opcion) => {
									setTransportista(opcion);
								}}
								options={transportistas}
								hideSelectedOptions
								value={transportista}
							/>
							{errorTransportista ? (
								<div style={{ color: "#dc3545" }}>
									Elija un transportista antes de continuar.
								</div>
							) : null}
						</div>
					) : null}
				</div>
			) : null}

			<div className="row mt-3">
				<div className="col text-center">
					<button className="btn btn-info" onClick={check}>
						Buscar
					</button>
				</div>
			</div>
		</div>
	);
};
