// @flow
import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Site, RouterContextProvider } from "tabler-react";
//import UsuariosMensajeria from "./socket/UsuariosMensajeria.react";
import { NotificationContainer } from "react-notifications";
import Smartphone from "../src/components/Smartphone/Smartphone";
import "react-notifications/lib/notifications.css";
import DisplayPhoneButton from "./components/Smartphone/DiplayPhoneButton";
import { createPortal } from "react-dom";
import LlamadaModal from "./components/Smartphone/LlamadaModal";
import LlamadaInfoModal from "./components/Smartphone/LlamadaInfoModal";
import { NotificationManager} from 'react-notifications';
import { softphoneContext } from "./context/softphoneContext";
import { NetworkStatus } from "./Helpers";

const navBarItems = JSON.parse(sessionStorage.getItem("navBarItems"));
const user = JSON.parse(sessionStorage.getItem("usuario"));
const perfil = JSON.parse(sessionStorage.getItem("perfil"));

const accountDropdownProps = {
  avatarURL: "./user/user.png",
  name: user
    ? user.persona
      ? user.persona.nombre +
        " " +
        user.persona.aPaterno +
        " " +
        user.persona.aMaterno
      : ""
    : "",
  description: perfil ? perfil.nombre : "",
  options: [
    { icon: "user", value: "Perfil", to: "/perfilusuario" },
    { icon: "log-out", value: "Salir", to: "/logout" },
  ],
};
/*
const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
let topics = ["/topic/users"];
if (currentUser) {
  if (currentUser[0]) {
    topics.push("/topic/users/" + currentUser[0].usuario.username);
  }
}*/

const SiteWrapper = (props) => {
  const softphone = useContext(softphoneContext);
  const [telefonoDisplayed, setTelefonoDisplayed] = useState(false);
  const [showIncommingCall, setShowIncommingCall] = useState(false);
  const [usuarioEntrante, setUsuarioEntrante] = useState("");
  const [enllamada, setEnLlamada] = useState(false);

  const [contestar, setContestar] = useState(false);
  const [ignorar, setIgnorar] = useState(false);
  useEffect(()=>{
    if(Number(softphone.getLlamarClic()) && !telefonoDisplayed){
      setTelefonoDisplayed(true);
    }    
  },[softphone.getLlamarClic()]);
  return (
    <Site.Wrapper
      headerProps={{
        href: "",
        alt: "Sky Angel",
        imageURL: "images/allone.svg",
        navItems: <div class="dropdown"></div>,
        accountDropdown: accountDropdownProps,
      }}
      navProps={{ itemsObjects: navBarItems }}
      routerContextComponentType={withRouter(RouterContextProvider)}
    >
      <audio id="alerta_audio" src="songs/alert.mp3"></audio>
      {/* <UsuariosMensajeria topic={topics} /> */}
      <NotificationContainer />
      <NetworkStatus />
      <div className="container">
        <div className="row">
          {softphone.getValues().id?
            <div className="col-1">
              <DisplayPhoneButton
                phoneDiplayed={telefonoDisplayed}
                updatePhoneDisplayed={() => setTelefonoDisplayed((prev) => !prev)}
              />
            </div>
          : null}
          { softphone.getValues().pausaEstatus?
            <div className="col-1">
              <span
                className="text-primary"
                data-toggle="tooltip"
                data-placement="top"
                title={"Estan pausadas las llamadas entrantes a central("+softphone.getValues().pausaMotivo+")"}
              >
                <span><i className="fa fa-group text-danger"></i></span>
                <span><i className="fa fa-pause ml-1 text-danger"></i></span>
              </span>
            </div>            
          :null}
          { false && softphone.getMovimiento().data && softphone.getMovimiento().data[0] ? softphone.getMovimiento().data[0].id_movimiento: ""}

        </div>
      </div>
      {softphone.getValues().id &&
        createPortal(
          <LlamadaModal
            handleCloseModal={() => setShowIncommingCall(false)}
            showModal={showIncommingCall}
            llamante={usuarioEntrante}
            handleRefuse={() => {
              setIgnorar(true);
              setUsuarioEntrante("");
            }}
            handleAnswer={() => {
              setContestar(true);
              setEnLlamada(true);
            }}
          />,
          document.body
        )}
      {softphone.getValues().id && softphone.getMovimiento().data && softphone.getMovimiento().data.length && false &&// contestar &&
        createPortal(
          <LlamadaInfoModal
            handleCloseModal={() => softphone.setMovimiento({...softphone.getMovimiento(), estatus: false })}
            showModal={softphone.getMovimiento().estatus}
          />,
          document.body
        )
      }
      {softphone.getValues().id && (
        <div
          style={{
            position: "relative",
            display: telefonoDisplayed ? "block" : "none",
          }}
        >
          <Smartphone
            setShowIncommingCall={setShowIncommingCall}
            setUsuarioModal={setUsuarioEntrante}
            contestar={contestar}
            ignorar={ignorar}
            setContestar={setContestar}
            setIgnorar={setIgnorar}
            setEnLlamada={setEnLlamada}
            enllamada={enllamada}
          />
        </div>
      )}
      {props.children}
    </Site.Wrapper>
  );
};

export default SiteWrapper;
