import React, { useState, useEffect } from "react";
import { MessageList } from 'react-chat-elements';
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import "react-chat-elements/dist/main.css";
const WhatsAppChatBot = ({movimiento}) => {
    const [mensajes, setMensajes]= useState([]);
    useEffect(() => {
        obtenerMensajes();
    }, []);

    const obtenerMensajes =()=>{
        getfuntion(
            'get',
            [],
            '',
            "chatbot_conversacion/conversacion/1/"+movimiento.idMovimiento,
            'conversacion'
          );
    }
    const getfuntion = (
        metodo = 'get',
        obj = [],
        id = '',
        catalogo = 'chat',
        stateVar = 'chatbot',
        hiddenModl = ''
      ) => {
        return Crud_Catalogos(
          catalogo,
          'enviar_whatsApp',
          metodo,
          id,
          obj,
          '',
          stateVar,
          hiddenModl,
          []
        )
          .then((returnVal) => {
            /**
             * filtro de variables
             */
            switch (metodo) {
              case 'get':
                if (stateVar === 'conversacion') {
                    let mensajesTemp= [];
                    for( let m of returnVal ){
                        if([1,2,3].includes(m.modelo.tipo)){
                            mensajesTemp.push({
                                position: "left",
                                type: "text",
                                title: "Bot",
                                text: m.texto,
                            });
                            if( m.respuesta ){
                                mensajesTemp.push({
                                    position: "right",
                                    type: "text",
                                    title: "Tranporte",
                                    text:   m.respuesta,
                                });
                            }
                        }
                    }console.log(mensajesTemp);
                    setMensajes(mensajesTemp);
                }
                break;
              default:
                break;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
      
    return (
        <div>
            <h1>WhatsAppChatBot</h1>
            <hr className="mt-6"/>
            <div className="p-5">
                <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={mensajes}
                />
            </div>
        </div>
    );
}
export default WhatsAppChatBot;