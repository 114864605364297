import React, { useState } from "react";
import SiteWrapper from "../../SiteWrapper.react";

import {MenuDashboardNaviera} from "./MenuDashboardNaviera.react";
import {ContentDashboardNaviera} from "./ContentDashboardNaviera.react";
import {LoadingScreen} from "../../components/LoadingScreen";

const DashboardNavieraPage = () => {
	
	//Variables check loading y error
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);

	//const [receivedValue, setReceivedValue] = useState('');
	const [fechaInicioPadre, setfechaInicioPadre] = useState('');
	const handleValueChange = (value) => {
		setfechaInicioPadre(value); // Actualiza el estado con el valor recibido
	};


	const [fechaFinPadre, setfechaFinPadre] = useState('');
	const handleFechaFinPadreChange = (value) => {
		setfechaFinPadre(value); // Actualiza el estado con el valor recibido
	};

	const [alcance, setAlcance] = useState('');
	const handleAlcance = (value) => {
		setAlcance(value); // Actualiza el estado con el valor recibido
	};



	const [plataforma, setPlataforma] = useState('');
	const handlePlataforma = (value) => {
		setPlataforma(value); // Actualiza el estado con el valor recibido
	};

	return (
		<SiteWrapper>
				<div className="container">
					<MenuDashboardNaviera 
						setLoading={setLoading}
						setError={setError}
						setData={setData}
						setFechaInicioPadre={handleValueChange} 
						setFechaFinPadre={handleFechaFinPadreChange}
						setAlcance={handleAlcance}
						_setPlataforma={handlePlataforma}
					/>
					Fecha Inicio: {fechaInicioPadre} 
					Fecha Fin: {fechaFinPadre} 
					Usuario: {JSON.parse(sessionStorage.getItem("currentUser"))[0].usuario.id}
                    Alcance:{alcance}
					Plataforma:{plataforma}
                   

					<ContentDashboardNaviera
						data={data}
						fechaInicio={fechaInicioPadre}
						fechaFin={fechaFinPadre}
						alcance={alcance}
						plataforma={plataforma}
						
					/>
				</div>
			{loading ? (
				<LoadingScreen
					loading={loading}
				/>
			):null}
			
		</SiteWrapper>
	);
};

export default DashboardNavieraPage;
