import React, { useState, useEffect } from "react";
import Dash from "../Dash";
import Desglose from "./Desglose.react";
import * as XLSX from 'xlsx';

const buttonStyle = {
  margin: '10px 0',
};

const Button = ({ label, handleClick }) => (
  <button
    className="btn btn-default"
    style={buttonStyle}
    onClick={handleClick}
  >
    {label}
  </button>
);


function Panel({
  children,
  isActive,
  onShow
}) {
  return (
    <>
      {isActive ? (
        <>{children}</>
      ) : ""}
    </>
  );
}

export const ContentDashboardTerrestre = ({ data }) => {
  const [contador, setContador] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [indices, setIndices] = useState({});
  useEffect(() => {
    let contadorTemp = 0;
    if (data.length !== 0) {
      data.forEach(function (resultado) {
        contadorTemp += resultado.count;
      });
      setContador(contadorTemp);
    } else {
      setContador(0);
    }
  }, [data]);
  
  const toXL = ({userDatas}) => {  
    const filtered = data.map((item) => ({
      valor: item.value,
      conteo: item.count,
      tipo: item.tipo,
    }));
    const wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(filtered)
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, data[0].tipo+" Dashbaord.xlsx")
    //console.log("RES:"+);
    
  };
//
  return (
    <div>
      <div className="row mt-3">
        <div className="ml-auto mr-3">
          <h1>Total {contador}</h1>
        </div>
      </div>
      {data.length > 0 ? <button className="btn btn-info" label="Exportar a Excel" onClick={toXL}> Exportar a Excel </button>:null}

      <Panel isActive={activeIndex === 0} onShow={() => setActiveIndex(0)}>
        <div className="row mt-3">
          {data.length > 0 ? (
            data
              .sort()
              .map((res, index) => (
                <Dash
                  texto={res.value}
                  cantidad={res.count}
                  color={`hsl(24,100%, ${
                    -20 + (100 - (100 * res.count) / contador)
                  }%)`}
                  total={contador}
                  showBar={true}
                  instalados={false}
                  recuperados={false}
                  handleClick={()=>{setIndices({tipo:res.tipo, id:res.id, nombre:res.value, mes:res.mes, plataforma:res.plataforma, transportista: res.transportista}); setActiveIndex(1);} }
                />		    					
              ))
          ) : (
            <div className="alert alert-dark w-100" role="alert">
              No hay resultados que mostrar...
            </div>
          )}
        </div>
      </Panel>  <br/>

      <Panel isActive={activeIndex === 1} onShow={() => setActiveIndex(1)}>
        <div className="row mt-3">
          <div style={{
              right: '0px',
              float: 'left',
              padding: '10px',
              zIndex: '1'
              }}>
              <Button label="Regresar" handleClick={() => {setActiveIndex(0); setIndices({})}}/>
            </div>
          <br/> <br/> <br/> <br/>
          <Desglose index={indices}/>
        </div> 
      </Panel>
    </div>
  );
};
