import React, { Component } from 'react';
import {
  Page,
  Card,
  Grid,
  Form,
  Button,
  Dropdown,
  Table,
  Input,
  Icon,
} from 'tabler-react';
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import { Exportar } from '../../herramientas/Excel';
import Select from 'react-select';
import { MovimientoGpsEvidenciaModal } from '../../components/MovimientoGpsEvidenciaModal/MovimientoGpsEvidenciaModal';
import ModalLay from '../../components/ModalLay/ModalLay';

/** alertas */
import { NotificationManager } from 'react-notifications';
class RecuperarGpsForm extends Component {
  constructor() {
    super();
    this.state = {
      gps: [],
      gpsTemp: [],
      gpsR: [],
      gpsEstatus: [],
      estatus: { label: 'Selecciona estatus', value: 0 },
      opcion: { label: 'Seleccione una opción', value: 0 },
      search: null,
      corredores: [],
      corredor: { value: 0, label: 'Selecciona un corredor' },
      tableheaderItems: [],
      colToFilter: 'nombre',
      searchC: '',
      todos: [],
      descargandoSimbol: '--',
    };
    this.opciones = [
      { value: 0, label: 'Seleccione una opción', api: '' },
      //{ value: 1, label: "Corredores",              api: "corredor" },
      { value: 2, label: 'Transportistas', api: 'transportista' },
      { value: 3, label: 'Placas', api: 'remolque_placa' },
      { value: 4, label: 'Booking', api: 'booking' },
      { value: 5, label: 'Fecha colocación', api: 'fecha_colocacion' },
      { value: 6, label: 'Gps', api: 'gps' },
      //{ value: 7, label: "Cita Programada",         api: "cita_programada" },
    ];
    this.estatus = [
      { value: 0, label: 'Selecciona estatus' },
      { value: 1, label: 'Disponible' },
      { value: 2, label: 'Instalado' },
      { value: 3, label: 'Por recuperar' },
      { value: 4, label: 'Baja definitiva' },
    ];
    this.colToFilter = 'nombre';
    this.columnas = [
      { content: 'RECUPERAR', col: 0 },
      { content: 'EVIDENCIAS', col: 0 },
      { content: 'ID MOV', col: 'id' },
      { content: 'GPS', col: 'nombre' },
      { content: 'CONTENEDOR', col: 'contenedor' },
      { content: 'TRANSPORTISTA', col: 'idTransportista' },
      { content: 'OPERADOR', col: 'operadorNombre' },
      { content: 'PLACA REMOLQUE', col: 'remolquePlaca' },
      { content: 'ECONOMICO REMOLQUE', col: 'economicor' },
      { content: 'CORREDOR', col: 'idCorredor' },
      { content: 'DESTINO', col: 'destino' },
      { content: 'CLIENTE', col: 'idCliente' },
      { content: 'FECHA COLOCACION', col: 'colocacionFecha' },
      { content: 'PLATAFORMA', col: 'idPlataforma' },
      { content: 'SALIDA CLIENTE', col: 'salidaClienteVacio' }, //IMPORTACION
      { content: 'ARRIBO TERMINAL', col: 'arriboTerminalCargado' },
      { content: 'estatus', col: 0 },
    ];
    this.indiceT = 0;
    this.total = -1;
  }
  componentWillMount() {
    this.getfuntion('get', '', '', 'corredores/tipoId/1', 'corredores');
    this.table_headers(true);
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.gpsTodos();
    }
  }

  gpsTodos = () => {
    this.getfuntion(
      'get',
      [],
      '',
      'gps_por_recuperar/gps_estatus/3/pagina/' + this.indiceT + '/total/1000',
      'todos'
    );
  };

  changeEstatus = (event) => {
    this.setState({ gps: [] });
    this.setState({ search: '' });
    this.setState({ estatus: event });
    if (event.value > 0) {
      if (this.state.opcion.value > 0) {
        this.getfuntion(
          'get',
          [],
          '',
          'gps_por_recuperar/' +
            this.state.opcion.api +
            '/' +
            this.state.search +
            '/' +
            event.value +
            '/pagina/0/total/50',
          'gps'
        );
      } else {
        this.getfuntion(
          'get',
          [],
          '',
          'gps_por_recuperar/gps_estatus/' + event.value + '/pagina/0/total/50',
          'gps'
        );
      }
    }
  };
  changeOpcion = (event) => {
    let searchTemp = null;
    this.setState({ search: searchTemp });
    this.setState({ corredor: { value: 0, label: 'Selecciona un corredor' } });
    this.setState({ opcion: event });
    let estatus = this.state.estatus.value > 0 ? this.state.estatus.value : 3;
    if (event.value > 0 && searchTemp) {
      //this.getfuntion("get", [], "", "gps_por_recuperar/"+event.api+"/"+this.state.search+"/"+estatus+"/pagina/0/total/10000", "gps");
    }
  };
  changeGps = (event) => this.setState({ gpsR: event });

  getfuntion = (
    metodo = 'get',
    obj = [],
    id = '',
    catalogo = '',
    stateVar = '',
    hiddenModl = ''
  ) => {
    Crud_Catalogos(
      catalogo,
      'movimientos',
      metodo,
      id,
      obj,
      '',
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case 'post':
            {
              if (stateVar === 'gpsRecuperar') {
                var gpsTem = this.state.gps;
                if (returnVal.OK) {
                  gpsTem.map((gp, index) => {
                    if (obj.gps === gp.idGps) {
                      gpsTem.splice(index, 1);
                    }
                  });
                  this.setState({ gps: gpsTem });
                  this.setState({ gpsR: [] });
                  NotificationManager.info('GPS ' + obj.gps, 'RECUPERADO');
                }
                if (returnVal.Error) {
                  NotificationManager.error('GPS ' + obj.gps, returnVal.Error);
                }
              }
            }
            break;
          case 'get':
            {
              if (stateVar === 'todos') {
                if (this.state.descargandoSimbol === '--') {
                  this.setState({ descargandoSimbol: '/' });
                } else {
                  this.setState({ descargandoSimbol: '--' });
                }
                let todosTem = this.state.todos;
                this.total = returnVal.totalElements;
                todosTem = todosTem.concat(returnVal.content);
                this.setState({ todos: todosTem });
                if (returnVal.totalPages > this.indiceT) {
                  this.indiceT++;
                  this.gpsTodos();
                }
              }
              if (stateVar === 'gps') {
                let gpsTemp = [];
                if (returnVal.content) {
                  returnVal.content.map((g, i) => {
                    if (g) {
                      gpsTemp.push(g);
                    }
                  });
                  this.setState({ gps: gpsTemp });
                }
                if (returnVal.id) {
                  gpsTemp.push(returnVal);
                  this.setState({ gps: gpsTemp });
                }
                if (returnVal.length) {
                  returnVal.map((g, i) => {
                    if (g) {
                      gpsTemp.push(g);
                    }
                  });
                  this.setState({ gps: gpsTemp });
                }
              }
              if (stateVar === 'corredores' && stateVar[0]) {
                let corredoresTemp = [
                  { value: 0, label: 'Selecciona un corredor' },
                ];
                for (let item of returnVal) {
                  corredoresTemp.push({ value: item.id, label: item.nombre });
                }
                this.setState({ corredores: corredoresTemp });
              }
              if (stateVar === 'gpsestatus') {
                this.setState({ gpsEstatus: returnVal });
              }
              if (stateVar === 'gpsRecuperar' && returnVal) {
                this.getfuntion(
                  'get',
                  '',
                  '',
                  'gpsestatus/plataforma/' +
                    obj.idPlataforma.idGg +
                    '/gps/' +
                    obj.idGps,
                  'gpsestatus'
                );
                this.setState({ gpsR: returnVal });
                //NotificationManager.info("GPS "+ obj.gps, "RECUPERADO");
              }
              if (stateVar === 'urlGps') {
                window.open(
                  returnVal.url,
                  'sharer',
                  'width=900,height=600,scrollbars=NO'
                );
              }
            }
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else {
          console.log('Error desconocido ....' + err.response);
        }
      });
  };

  recuperarGPS = () => {
    //console.log(this.state.gpsTemp);
    //console.log(this.state.gpsR);
    var recGps = {
      gps: this.state.gpsR.id,
      plataforma: this.state.gpsTemp.idPlataforma.id,
      estatus: 1,
      idMovimiento: this.state.gpsTemp.id,
    };
    //console.log(recGps);
    this.getfuntion('post', recGps, '', 'gps/asignar_gps', 'gpsRecuperar');
  };
  changesearch = (e) => {
    this.setState({ gps: [] });
    let searchTemp = null;
    switch (this.state.opcion.value) {
      case 1:
        searchTemp = e.value;
        this.setState({ corredor: e });
        break;
      case 2:
        searchTemp = e.target.value;
        break;
      case 3:
        searchTemp = e.target.value;
        break;
      case 4:
        searchTemp = e.target.value;
        break;
      case 5:
        searchTemp = e.target.value;
        break;
      case 6:
        searchTemp = e.target.value;
        break;
      case 7:
        searchTemp = e.target.value;
        break;
      default:
        break;
    }
    this.setState({ search: searchTemp });
    if (this.state.opcion.value === 5 || this.state.opcion.value === 7) {
      searchTemp = searchTemp + ' 00:00:00';
    }
    let estatus = this.state.estatus.value > 0 ? this.state.estatus.value : 3;
    if (this.state.opcion.value !== 1 && searchTemp.length > 3) {
      if (
        ['transportista', 'remolque_placa', 'booking', 'gps'].includes(
          this.state.opcion.api
        )
      ) {
        this.getfuntion(
          'get',
          [],
          '',
          'gps_por_recuperar/' +
            this.state.opcion.api +
            '/' +
            searchTemp +
            '/gpsEstatus/' +
            estatus,
          'gps'
        );
      }
      if (this.state.opcion.api.includes('fecha_colocacion')) {
        this.getfuntion(
          'get',
          [],
          '',
          'gps_por_recuperar/' +
            this.state.opcion.api +
            '/' +
            searchTemp +
            '/' +
            estatus +
            '/pagina/0/total/50000',
          'gps'
        );
      }
    }
  };
  getGps = (gpsT) => {
    this.getfuntion('get', gpsT, '', 'gps/' + gpsT.idGps, 'gpsRecuperar');
    this.setState({ gpsTemp: gpsT });
  };
  //gpsestatus/rastreame/plataforma/' + plataformaId + '/gps/' + gpsRecienteTemp.id
  rastreame = (gps = '') => {
    if (gps !== '') {
      this.getfuntion(
        'get',
        '',
        '',
        'gpsestatus/rastreame/plataforma/' + gps.idPlataforma.id + '/gps/' + gps.idGps,
        'urlGps'
      );
    } else {
      if (this.state.gpsTemp.idGps) {
        this.getfuntion(
          'get',
          '',
          '',
          'gpsestatus/rastreame/plataforma/' + this.state.gpsTemp.idPlataforma.id + '/gps/' + this.state.gpsTemp.idGps,
          'urlGps'
        );
      }
    }
  };
  cancelar = () => this.setState({ gpsR: [] });
  render() {
    return (
      <div class="row">
        <div className="col col-12 col-sm-12 col-md-4 ">
          <Form.Group label="Estatus">
            <Select
              value={this.state.estatus}
              onChange={this.changeEstatus}
              options={this.estatus}
            />
          </Form.Group>
        </div>
        <div className="col col-12 col-sm-12 col-md-4 ">
          <Form.Group label="Opción">
            <Select
              value={this.state.opcion}
              onChange={this.changeOpcion}
              options={this.opciones}
            />
          </Form.Group>
        </div>
        <div className="col col-12 col-sm-12 col-md-4 ">
          {this.state.opcion.value === 1 ? (
            <Form.Group label="Busqueda">
              <Select
                value={this.state.corredor}
                onChange={this.changesearch}
                options={this.state.corredores}
              />
            </Form.Group>
          ) : null}
          {this.state.opcion.value === 2 ||
          this.state.opcion.value === 3 ||
          this.state.opcion.value === 4 ||
          this.state.opcion.value === 6 ? (
            <Form.Group label="Busqueda">
              <Form.Input
                className="mb-3"
                icon="search"
                value={this.state.search}
                placeholder="Buscar..."
                position="append"
                onChange={this.changesearch}
              />{' '}
            </Form.Group>
          ) : null}
          {this.state.opcion.value === 5 || this.state.opcion.value === 7 ? (
            <Form.Group label="Busqueda">
              <input
                type="date"
                id="fech"
                name="fecha"
                placeholder="AAAA-MM-DD"
                onChange={this.changesearch}
              ></input>
            </Form.Group>
          ) : null}
          {!/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
            navigator.userAgent
          ) ? (
            <Form.Group label="Exportar todos">
              {this.state.todos.length === this.total ? (
                <Exportar
                  crud={this.getfuntion}
                  catalogo={'Gps Por recuperar'}
                  data={this.state.todos}
                />
              ) : (
                'Descargando (' +
                this.total +
                '/' +
                this.state.todos.length +
                ') ' +
                this.state.descargandoSimbol
              )}
            </Form.Group>
          ) : null}
        </div>
        <div className="col col-12 col-md-12" hidden={this.state.gpsR.idGg}>
          <Card
            title="GPS"
            options={
              <nav className="navbar navbar-expand-lg navbar-light">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <a>
                      <Exportar
                        crud={this.getfuntion}
                        catalogo={'Gps Por recuperar'}
                        data={this.state.gps
                          .filter((word) =>
                            !Number.isInteger(word[this.colToFilter]) //Si se filtra texto
                              ? this.objectFilter(word[this.colToFilter])
                                  .toUpperCase()
                                  .includes(this.state.searchC.toUpperCase())
                              : this.state.searchC != ''
                              ? parseInt(word[this.colToFilter], 10) ===
                                parseInt(this.state.searchC, 10)
                                ? true
                                : false //Si se filtra numero o boolean
                              : true
                          )
                          .sort(function (a, b) {
                            if (a['colocacionFecha'] > b['colocacionFecha']) {
                              return -1;
                            }
                            if (a['colocacionFecha'] < b['colocacionFecha']) {
                              return 1;
                            }
                            return 0;
                          })}
                      />
                    </a>
                  </li>
                  <li className="nav-item active">
                    <a>
                      {' '}
                      <Form.Input
                        className="lg-12"
                        icon="search"
                        placeholder={this.state.colToFilter}
                        position="append"
                        onChange={this.searching}
                      />{' '}
                    </a>
                  </li>
                </ul>
              </nav>
            }
          >
            <Card.Body>
              {this.state.tableheaderItems ? (
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                  headerItems={this.state.tableheaderItems}
                >
                  <Table.Body>
                    {this.state.gps
                      .filter((word) =>
                        !Number.isInteger(word[this.colToFilter]) //Si se filtra texto
                          ? this.objectFilter(word[this.colToFilter])
                              .toUpperCase()
                              .includes(this.state.searchC.toUpperCase())
                          : this.state.searchC != ''
                          ? parseInt(word[this.colToFilter], 10) ===
                            parseInt(this.state.searchC, 10)
                            ? true
                            : false //Si se filtra numero o boolean
                          : true
                      )
                      .sort(function (a, b) {
                        if (a['colocacionFecha'] > b['colocacionFecha']) {
                          return -1;
                        }
                        if (a['colocacionFecha'] < b['colocacionFecha']) {
                          return 1;
                        }
                        return 0;
                      })
                      .map((gpsT, index) => (
                        <Table.Row>
                          {this.state.estatus.value > 0 ? (
                            this.state.estatus.value === 3 ? (
                              <Table.Col>
                                {' '}
                                <Button
                                  color="primary"
                                  size="sm"
                                  onClick={() => {
                                    this.getGps(gpsT);
                                  }}
                                >
                                  Recuperar
                                </Button>{' '}
                              </Table.Col>
                            ) : (
                              <Table.Col></Table.Col>
                            )
                          ) : (
                            <Table.Col></Table.Col>
                          )}
                          {this.state.estatus.value === 3 ? (
                            <Table.Col>
                              {' '}
                              <ModalLay
                                tamanio={1200}
                                title="Evidencias colocación"
                                name={
                                  <span
                                    style={{
                                      color: '#63e67b',
                                      'font-size': '200%',
                                    }}
                                  >
                                    <i className="fa fa-picture-o" />
                                  </span>
                                }
                                formulario={MovimientoGpsEvidenciaModal}
                                datosForm={{
                                  movimiento_id: gpsT?.id,
                                  gps: gpsT?.nombre,
                                }}
                              />{' '}
                            </Table.Col>
                          ) : (
                            <Table.Col></Table.Col>
                          )}
                          <Table.Col> {gpsT.id} </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.nombre + '(' + gpsT.idGps + ') '}
                            <span
                              className="badge float-right"
                              onClick={() => this.rastreame(gpsT)}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="rastrear gps"
                            >
                              <label
                                style={{ color: 'black', 'font-size': '200%' }}
                              >
                                <Icon name="map-pin" />
                              </label>
                            </span>{' '}
                          </Table.Col>
                          <Table.Col> {gpsT.contenedor} </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.idTransportista
                              ? gpsT.idTransportista.nombreCorto
                              : ''}{' '}
                          </Table.Col>
                          <Table.Col> {gpsT.operadorNombre} </Table.Col>
                          <Table.Col> {gpsT.remolquePlaca} </Table.Col>
                          <Table.Col> {gpsT.economicor} </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.idCorredor
                              ? gpsT.idCorredor.nombreCorto
                              : ''}{' '}
                          </Table.Col>
                          <Table.Col> {gpsT.destino} </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.idCliente ? gpsT.idCliente.nombre : null}{' '}
                          </Table.Col>
                          <Table.Col> {gpsT.colocacionFecha} </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.idPlataforma
                              ? gpsT.idPlataforma.nombre
                              : ''}{' '}
                          </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.idMovimientoTipo
                              ? gpsT.idMovimientoTipo.nombre
                                  .toUpperCase()
                                  .includes('IMPO')
                                ? gpsT.salidaClienteVacio
                                : ''
                              : ''}{' '}
                          </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.idMovimientoTipo
                              ? gpsT.idMovimientoTipo.nombre
                                  .toUpperCase()
                                  .includes('EXPO')
                                ? gpsT.arriboTerminalCargado
                                : ''
                              : ''}{' '}
                          </Table.Col>
                          <Table.Col>
                            {' '}
                            {gpsT.idGpsEstatus
                              ? gpsT.idGpsEstatus.nombre
                              : ''}{' '}
                          </Table.Col>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              ) : (
                <div></div>
              )}
            </Card.Body>
          </Card>
        </div>

        <div className="col col-12 col-md-12" hidden={!this.state.gpsR.idGg}>
          <Card>
            <Card.Header>
              <Card.Title>Detalles</Card.Title>
              <Card.Options>
                {this.state.gpsR.idGg ? (
                  <div>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => this.recuperarGPS()}
                    >
                      Confirmar
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => this.cancelar()}
                    >
                      Cancelar
                    </Button>
                  </div>
                ) : null}
              </Card.Options>
            </Card.Header>
            <Card.Body>
              {this.state.gpsR.idGg ? (
                <Table
                  cards={true}
                  striped={true}
                  responsive={true}
                  className="table-vcenter"
                  headerItems={[
                    { content: 'ID' },
                    { content: 'NOMBRE' },
                    { content: 'MARCA' },
                    { content: 'Bateria' },
                    { content: 'ULTIMO REPORTE' },
                    { content: 'DIRECCIÓN' },
                    { content: 'RASTREAME' },
                  ]}
                >
                  <Table.Body>
                    <Table.Row>
                      <Table.Col> {this.state.gpsR.id} </Table.Col>
                      <Table.Col> {this.state.gpsR.nombre} </Table.Col>
                      <Table.Col> {this.state.gpsR.marca} </Table.Col>
                      <Table.Col>
                        {' '}
                        {this.state.gpsEstatus.nivel_bateria >= 0
                          ? this.state.gpsEstatus.nivel_bateria + ' %'
                          : ''}{' '}
                      </Table.Col>
                      <Table.Col>
                        {' '}
                        {this.state.gpsEstatus.ultimo_reporte
                          ? this.state.gpsEstatus.ultimo_reporte
                          : ''}{' '}
                      </Table.Col>
                      <Table.Col>
                        {' '}
                        {this.state.gpsEstatus.direccion
                          ? '(' +
                            this.state.gpsEstatus.latitud +
                            ', ' +
                            this.state.gpsEstatus.longitud +
                            '). ' +
                            this.state.gpsEstatus.direccion
                          : ''}{' '}
                      </Table.Col>
                      <Table.Col>
                        {' '}
                        <span
                          className="badge"
                          onClick={() => this.rastreame()}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="rastrear gps"
                        >
                          <label
                            style={{ color: 'black', 'font-size': '200%' }}
                          >
                            <Icon name="map-pin" />
                          </label>
                        </span>
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ) : (
                <div>No se ha seleccionado ningun GPS</div>
              )}
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>
        </div>
      </div>
    );
  }
  table_headers = (asc) => {
    let content = [];
    Object.keys(this.columnas).forEach((key, index) => {
      content.push({
        content: (
          <div
            onClick={() => {
              this.sortElements(this.columnas[index].col);
            }}
          >
            {this.columnas[index].content}
            {this.colToFilter === this.columnas[index].col ? (
              <Icon name={asc ? 'chevron-up' : 'chevron-down'} />
            ) : null}
          </div>
        ),
      });
    });
    this.setState({ tableheaderItems: content });
  };
  sortElements = (columna) => {
    let oldCol = this.colToFilter;
    if (this.colToFilter == columna) {
      this.AscDesc = !this.AscDesc;
    } else {
      this.AscDesc = true;
    }
    this.colToFilter = columna;
    this.setState({ colToFilter: columna });
    if (oldCol == columna) {
      this.table_headers(this.AscDesc);
    } else {
      this.table_headers(true);
    }

    var originalArray = this.state.gps;
    if (!originalArray[0]) {
      return;
    }
    if (this.AscDesc) {
      originalArray = originalArray.sort(function (a, b) {
        if (
          typeof a[columna] === 'object' &&
          typeof b[columna] === 'object' &&
          a[columna] !== null &&
          b[columna] !== null
        ) {
          if (a[columna] === null || b[columna] === null) {
            return 1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre > b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre < b[columna].nombre) {
            return -1;
          }
          return 0;
        } else {
          if (a[columna] === null) {
            return -1;
          }
          if (a[columna] > b[columna]) {
            return 1;
          }
          if (a[columna] < b[columna]) {
            return -1;
          }
          return 0;
        }
      });
      //return a[columna]-b[columna] }); //Asecendente
    } else {
      if (typeof originalArray[0][columna] == 'object') {
        originalArray = originalArray.sort(function (a, b) {
          if (a[columna] === null || b[columna] === null) {
            return -1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function (a, b) {
          if (a[columna] === null) {
            return -1;
          }
          if (a[columna] < b[columna]) {
            return 1;
          }
          if (a[columna] > b[columna]) {
            return -1;
          }
          return 0;
        });
      }
      //return b[columna]-a[columna] }); //Descendente
    }
    this.setState({ gps: originalArray });
  };
  searching = (event) => {
    this.setState({ searchC: event.target.value });
  };
  objectFilter = (columna) => {
    if (columna) {
      if (columna.nombre) {
        return columna.nombre;
      } else {
        if (typeof columna === 'object') {
          if (columna.length) {
            switch (this.colToFilter) {
              default:
                if (this.colToFilter) {
                  return columna[columna.length - 1]['nombre'];
                } else {
                  return '';
                }
            }
          } else {
            return '';
          }
        } else {
          return columna;
        }
      }
    } else {
      return '';
    }
  };
}

export default RecuperarGpsForm;
