import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import Crud_Catalogos from '../../herramientas/Crud_Catalogos';
import SiteWrapper from '../../SiteWrapper.react';

const NoticiasTable = () => {
  const [noticias, setNoticias] = useState([]);
  const [selectedNoticia, setSelectedNoticia] = useState(null);
  const [newNoticia, setNewNoticia] = useState({
    noticia: '',
    fechaCreacion: '',
    fechaPublicacion: '',
    fechaExpiracion: ''
  });
  const [error, setError] = useState('');
  const [apiError, setApiError] = useState(null); // Estado para manejar errores de API
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: '', // 'add', 'edit', o 'delete'
  });

  useEffect(() => {
    getNoticias();
  }, []);

  // Función de formato para manejar las fechas con el formato 'YYYY-MM-DD HH:MM:SS'
  const formatDateForDB = (htmlDate) => {
    if (!htmlDate) return null;
    const date = new Date(htmlDate);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}:${String(date.getSeconds()).padStart(2, '0')}`;
  };

  const formatDateForHTML = (dbDate) => {
    if (!dbDate) return '';
    return dbDate.replace(' ', 'T');
  };

  // Manejo de errores en la API
  const handleApiError = (error) => {
    console.error('Error:', error);
    setApiError('Hubo un problema con la operación. Por favor intenta de nuevo.');
  };

  const crudNoticias = (metodo, obj = [], id = "") => {
    return Crud_Catalogos("noticias", "noticias", metodo, id, obj)
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            setNoticias(returnVal);
            break;
          case "post":
          case "put":
          case "delete":
            getNoticias();
            closeModal();
            break;
          default:
            break;
        }
      })
      .catch(handleApiError);
  };

  const getNoticias = () => {
    crudNoticias("get");
  };

  const openModal = (type, noticia = null) => {
    setModalState({
      isOpen: true,
      type,
    });
    if (type === 'edit' || type === 'delete') {
      setSelectedNoticia({
        ...noticia,
        fechaCreacion: formatDateForHTML(noticia.fechaCreacion),
        fechaPublicacion: formatDateForHTML(noticia.fechaPublicacion),
        fechaExpiracion: formatDateForHTML(noticia.fechaExpiracion),
      });
    } else if (type === 'add') {
      const currentDate = new Date();
      const formattedCreationDate = formatDateForDB(currentDate);

      setNewNoticia({
        noticia: '',
        fechaCreacion: formattedCreationDate,
        fechaPublicacion: '',
        fechaExpiracion: '',
      });
    }
  };

  const closeModal = () => {
    setModalState({
      isOpen: false,
      type: '',
    });
    setSelectedNoticia(null);
    setError('');
  };

  const handleSaveEdit = () => {
    // Validación antes de guardar
    if (!selectedNoticia.noticia.trim()) {
      setError("El campo Noticia es obligatorio.");
      return;
    }
    if (!selectedNoticia.fechaPublicacion.trim()) {
      setError("El campo Fecha de Publicación es obligatorio.");
      return;
    }

    const updatedNoticia = {
      ...selectedNoticia,
      fechaCreacion: formatDateForDB(selectedNoticia.fechaCreacion),
      fechaPublicacion: formatDateForDB(selectedNoticia.fechaPublicacion),
      fechaExpiracion: formatDateForDB(selectedNoticia.fechaExpiracion),
    };
    crudNoticias("put", updatedNoticia, updatedNoticia.id);
  };

  const confirmDelete = () => {
    crudNoticias("delete", "", selectedNoticia.id);
  };

  const handleAddNewNoticia = () => {
    if (!newNoticia.noticia.trim()) {
      setError("El campo Noticia es obligatorio.");
      return;
    }
    if (!newNoticia.fechaPublicacion.trim()) {
      setError("El campo Fecha de Publicación es obligatorio.");
      return;
    }

    setError(''); // Resetear el error si no hay problemas
    const noticiaToAdd = {
      ...newNoticia,
      fechaCreacion: formatDateForDB(newNoticia.fechaCreacion),
      fechaPublicacion: formatDateForDB(newNoticia.fechaPublicacion),
      fechaExpiracion: formatDateForDB(newNoticia.fechaExpiracion),
      estatus: true,
    };
    crudNoticias("post", noticiaToAdd);
  };

  return (
    <SiteWrapper>
      <div className="container mt-3">
        <div className="d-flex justify-content-between">
          <h1>Noticias</h1>
          <Button
            className="btn btn-primary mb-3"
            onClick={() => openModal('add')}
          >
            Agregar Noticia
          </Button>
        </div>

        {apiError && <div className="alert alert-danger">{apiError}</div>} {/* Mensaje de error de API */}

        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Noticia</th>
              <th>Fecha de Creación</th>
              <th>Fecha de Publicación</th>
              <th>Fecha de Expiración</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {noticias.map((noticia) => (
              <tr key={noticia.id}>
                <td>{noticia.id}</td>
                <td>{noticia.noticia}</td>
                <td>{noticia.fechaCreacion}</td>
                <td>{noticia.fechaPublicacion}</td>
                <td>{noticia.fechaExpiracion}</td>
                <td>
                  <Button variant="warning" onClick={() => openModal('edit', noticia)}>Editar</Button>
                  <Button variant="danger" onClick={() => openModal('delete', noticia)}>Eliminar</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Modal para agregar nueva noticia */}
        {modalState.isOpen && modalState.type === 'add' && (
          <Modal show={modalState.isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Agregar Noticia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>Noticia</Form.Label>
                  <Form.Control
                    type="text"
                    value={newNoticia.noticia}
                    onChange={(e) => setNewNoticia({ ...newNoticia, noticia: e.target.value })}
                    required
                  />
                  {error && !newNoticia.noticia && <div className="text-danger">{error}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Fecha de Creación</Form.Label>
                  <Form.Control type="text" value={newNoticia.fechaCreacion} readOnly />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Fecha de Publicación</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={newNoticia.fechaPublicacion}
                    onChange={(e) => setNewNoticia({ ...newNoticia, fechaPublicacion: e.target.value })}
                    required
                  />
                  {error && !newNoticia.fechaPublicacion && <div className="text-danger">{error}</div>}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Fecha de Expiración</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={newNoticia.fechaExpiracion}
                    onChange={(e) => setNewNoticia({ ...newNoticia, fechaExpiracion: e.target.value })}
                    required
                  />
                  {error && !newNoticia.fechaExpiracion && <div className="text-danger">{error}</div>}
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>Cerrar</Button>
              <Button variant="primary" onClick={handleAddNewNoticia}>Guardar Noticia</Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Modal para editar noticia */}
        {modalState.isOpen && modalState.type === 'edit' && (
          <Modal show={modalState.isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Editar Noticia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedNoticia && (
                <Form>
                  <Form.Group>
                    <Form.Label>ID</Form.Label>
                    <Form.Control type="text" value={selectedNoticia.id} readOnly />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Noticia</Form.Label>
                    <Form.Control
                      type="text"
                      value={selectedNoticia.noticia}
                      onChange={(e) => setSelectedNoticia({ ...selectedNoticia, noticia: e.target.value })}
                      required
                    />
                    {error && !selectedNoticia.noticia && <div className="text-danger">{error}</div>}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Fecha de Creación</Form.Label>
                    <Form.Control type="text" value={selectedNoticia.fechaCreacion} readOnly />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Fecha de Publicación</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={selectedNoticia.fechaPublicacion}
                      onChange={(e) => setSelectedNoticia({ ...selectedNoticia, fechaPublicacion: e.target.value })}
                      required
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Fecha de Expiración</Form.Label>
                    <Form.Control
                      type="datetime-local"
                      value={selectedNoticia.fechaExpiracion}
                      onChange={(e) => setSelectedNoticia({ ...selectedNoticia, fechaExpiracion: e.target.value })}
                      required
                    />
                  </Form.Group>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>Cerrar</Button>
              <Button variant="primary" onClick={handleSaveEdit}>Guardar Cambios</Button>
            </Modal.Footer>
          </Modal>
        )}

        {/* Modal para eliminar noticia */}
        {modalState.isOpen && modalState.type === 'delete' && (
          <Modal show={modalState.isOpen} onHide={closeModal}>
            <Modal.Header closeButton>
              <Modal.Title>Eliminar Noticia</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>¿Estás seguro de que deseas eliminar la noticia?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>Cancelar</Button>
              <Button variant="danger" onClick={confirmDelete}>Eliminar</Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </SiteWrapper>
  );
};

export default NoticiasTable;
