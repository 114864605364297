import React, { useState, useEffect } from "react";

export const LoadingScreen = ({ loading,mensaje="" }) => {
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShow(true);
        }, 500)

        return () => clearTimeout(timeout);
    }, [show]);

    if (!show) return null;

    return (  
		<div>
            {loading ? (
                 <div className="loading-container">
                    <div className="row">
                    <div class="col-12" style={{textAlign: "lefth"}}>
                    <h1 >{mensaje}</h1>
                    </div>
                    <div class="col-12">
                    <img src="/images/loader.gif"></img>
                    </div>
                   </div>
                   
                </div>
            ) : null}
        </div>
    );
};