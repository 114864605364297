import React, { useState, useEffect } from "react";
import Dash from "../Dash";
import * as XLSX from 'xlsx';
import FetchData from "./FetchData.react";



export const ContentDashboardNaviera = ({ data,fechaInicio,fechaFin,alcance,plataforma }) => {
  const [contador, setContador] = useState([]);

  useEffect(() => {
    let contadorTemp = 0;
    if (data.length !== 0) {
      data.forEach(function (resultado) {
        contadorTemp += resultado.count;
      });
      setContador(contadorTemp);
    } else {
      setContador(0);
    }
  }, [data]);

  const toXL = ({userDatas}) => {  
    const filtered = data.map((item) => ({
      valor: item.value,
      conteo: item.count,
      tipo: item.tipo,
    }));
    const wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(filtered)
    XLSX.utils.book_append_sheet(wb, ws, "MySheet");
    XLSX.writeFile(wb, data[0].tipo+" Dashbaord.xlsx")
    //console.log("RES:"+);
    
  };


  return (
    <div>
      <div className="row mt-3">
        <div className="ml-auto mr-3">
          <h1>Total {contador}</h1>
        </div>
      </div>
      {data.length > 0 ? <button className="btn btn-info" label="Exportar a Excel" onClick={toXL}> Exportar a Excel </button>:null}

      <div className="row mt-3">
        {data.length > 0 ? (
          data
            .sort()
            .map((res, index) => (
              <Dash
                plataforma={plataforma}
                alcance={alcance}
                fechaInicio={fechaInicio}
                fechaFin={fechaFin}
                texto={res.value}
                cantidad={res.count+"<----"}
                color={`hsl(24,100%, ${
                  -20 + (100 - (100 * res.count) / contador)
                }%)`}
                total={contador}
                showBar={true}
                instalados={false}
                recuperados={false}
              />  
            
                        






            ))

        ) : (
          <div className="alert alert-dark w-100" role="alert">
            No hay resultados que mostrar...
          </div>
        )}


      </div>
    </div>
  );
};
