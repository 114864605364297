import React, { Component } from "react";
import { Button, Form, Icon, Grid } from "tabler-react";
import DateTimePicker from "react-datetime-picker";
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import Select from "react-select";
import { Crud_Catalogos } from "../../../../herramientas/Crud_Catalogos";
import { timeToDateFill, timeString, fechaValida } from "../../../../herramientas/DateFormat";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { now } from "jquery";
var reader = new FileReader();
let upImage = null;
class ActualizarHorariosNavieros extends Component {
  constructor(props) {

    console.log(props)
    super(props);
    this.state = {

      terminarServicio:false,
      setOtd:false,
      retardo:false,
      camposLogisticos: [],
      po: "",
      rutasEstatus: [],
      ruta: { value: 0, label: "Selecciona Estatus de ruta" },
      cliente: { value: 0, label: "Selecciona un cliente" },
      clientes: [],
      otds: [],
      otd: { value: 0, label: "Selecciona Otd" },
      sello: "",
      booking: "",
      contenedor: "",
      citaProgramada: "",
      citaProgramadaTerminal: "",
      citaColocacionGps: "",
      estatusActual: "",
      /**Exportaciones */
      retiroContenedor: "",
      arriboClienteVacio: "",
      salidaClienteLleno: "",
      arriboTerminalCargado: "",
      salidaPredioVacio: "",
      citaFechaDespacho: "",
      entradaCiudadPortuaria: "",
      arriboPatioLinea: "",
      salidaTerminalVacio: "",
      /**Importaciones */
      arriboTerminalVacio: "",
      salidaTerminalLleno: "",
      arriboClienteLleno: "",
      salidaClienteVacio: "",
      arriboDirecionCarga: "",
      arriboTerminalVacio2: "",
      asignacionPiloto: "",
      inicioRuta: "",
      inicioRutaVacio: "",
      ingresoCliente: "",
      ingresoBodegaCarga: "",
      despachoPredioCliente: "",
      llegadaFrontera: "",
      salidaFrontera: "",
      arriboAduana: "",
      salidaAduana: "",
      actualizarFull: 0,
      accion: this.props.accion, //'nuevo'// modificar, nuevo
      estaEnfull: false,
      enviar: false,
      //campos nuevos
      transportistas: [],
      transportista: { value: 0, label: "Selecciona Transportista" },
      idMovimientoTipo: { value: 0, label: "No se ha seleccionado plataforma" },
      movimientosTipo: [],
      destino: "",
      idCorredor: { value: 0, label: "No se ha seleccionado plataforma" },
      corredores: [],
    };

    //this.enableSave = this.enableSave.bind(this);
    this.camposLogisticosObject = [];
    this.clientes = [];
    this.campLogistObj = {
      idOperacionEstatus: this.props.movimiento.idOperacionEstatus,
      idRuta: this.props.movimiento.idRuta,
      citaProgramada: this.props.movimiento.citaProgramada,
      citaProgramadaTerminal: this.props.movimiento.citaProgramadaTerminal,
      citaColocacionGps: this.props.movimiento.citaColocacionGps,      
      inicioDeCarga:this.props.movimiento.inicioDeCarga,
      asignacionRampa: this.props.movimiento.asignacionRampa,
      sello: this.props.movimiento.sello,
      po: this.props.movimiento.po,
      booking: this.props.movimiento.booking,
      contenedor: this.props.movimiento.contenedor,
      idTransportista: this.props.movimiento.idTransportista,
      idOtd: this.props.movimiento.idOtd,
      idCliente: this.props.movimiento.idCliente,
      actualizarFull: this.props.movimiento.actualizarFull,
      idMovimientoTipo: this.props.movimiento.idMovimientoTipo,
      idOperacionTipo: this.props.movimiento.idOperacionTipo,
      idRuta2: this.props.movimiento.idRuta2,
      estatusActual: this.props.movimiento.estatusActual,
      /*Exportacion*/
      retiroContenedor: this.props.movimiento.retiroContenedor,
      arriboClienteVacio: this.props.movimiento.arriboClienteVacio,
      salidaClienteLleno: this.props.movimiento.salidaClienteLleno,
      arriboTerminalCargado: this.props.movimiento.arriboTerminalCargado,
      salidaPredioVacio: this.props.movimiento.salidaPredioVacio,
      citaFechaDespacho: this.props.movimiento.citaFechaDespacho,
      entradaCiudadPortuaria: this.props.movimiento.entradaCiudadPortuaria,
      arriboPatioLinea: this.props.movimiento.arriboPatioLinea,
      salidaTerminalVacio: this.props.movimiento.salidaTerminalVacio,
      /**Importacion */
      arriboTerminalVacio: this.props.movimiento.arriboTerminalVacio,
      salidaTerminalLleno: this.props.movimiento.salidaTerminalLleno,
      arriboClienteLleno: this.props.movimiento.arriboClienteLleno,
      salidaClienteVacio: this.props.movimiento.salidaClienteVacio,
      arriboDirecionCarga: this.props.movimiento.arriboDirecionCarga,
      arriboTerminalVacio2: this.props.movimiento.arriboTerminalVacio2,
      asignacionPiloto: this.props.movimiento.asignacionPiloto,
      inicioRuta: this.props.movimiento.inicioRuta,
      inicioRutaVacio: this.props.movimiento.inicioRutaVacio,
      ingresoCliente: this.props.movimiento.ingresoCliente,
      ingresoBodegaCarga: this.props.movimiento.ingresoBodegaCarga,
      despachoPredioCliente: this.props.movimiento.despachoPredioCliente,
      llegadaFrontera: this.props.movimiento.llegadaFrontera,
      salidaFrontera: this.props.movimiento.salidaFrontera,
      arriboAduana: this.props.movimiento.arriboAduana,
      salidaAduana: this.props.movimiento.salidaAduana,
      imagen: null,
      destino: this.props.movimiento.destino,
      idCorredor: this.props.movimiento.idCorredor,
    };
    this.movimiento = [];
    this.validacionString= "";

    this.scrollSpan = React.createRef();

    console.log(this.scrollSpan)
   
    
  }
  componentWillMount = () => {

    //Se valida si existe un retardo a la hora de cita programada para obligar a colocar  la causante OTD 
    if(([1,61].includes(this.props.movimiento.idPlataforma.id)) 
      && this.props.movimiento.citaProgramada != null){
        let dateCita = new Date(this.props.movimiento.citaProgramada);
        let cliente = this.props.movimiento.idMovimientoTipo.id == 1 ? this.props.movimiento.arriboClienteLleno : this.props.movimiento.arriboClienteVacio;   // some mock date
        let dateCliente = new Date(cliente); 
        let millisecondsCita = dateCita.getTime();
        let millisecondsCliente =  dateCliente.getTime();
        if(millisecondsCita < millisecondsCliente){
          this.setState({retardo : true})
          
        }else{
        
          console.log("cliente",cliente)
          if(cliente != null){
            this.setState({setOtd:true})
            this.campLogistObj.idOtd = {id:99 , nombre:"Sin código"}
          }
          
        }
    }
    /////////

    if (this.props.camposLogisticos) {
      for (let cl of this.props.camposLogisticos) {
        if (!this.camposLogisticosObject[cl.nombreCampo])
          this.camposLogisticosObject[cl.nombreCampo] = {};
        this.camposLogisticosObject[cl.nombreCampo][cl.idMovimientoTipo] = cl;
      }
      this.setState({ camposLogisticos: this.props.camposLogisticosNav });
    } else {
      let movTipoTemp = this.props.movimiento.idMovimientoTipo.id;
      if ([3, 270,336].includes(movTipoTemp)) {
        if(![1316].includes(this.props.movimiento.idPlataforma.id)){
          movTipoTemp = 1;
        }
      
      }
      if ( [4, 337].includes(movTipoTemp) ) {
        movTipoTemp = 2;
      }
      this.getfuntion(
        "get",
        "",
        "",
        "camposLogisticos/plataforma/" +
          this.props.movimiento.idPlataforma.id +
          "/mtipo/" +
          movTipoTemp,
        "camposLogisticos"
      );
    }
    if (this.props.movimiento.idOperacionEstatus) {
      if (this.props.movimiento.idOperacionEstatus.id <= 2) {
        let zonaHoraria = this.props.movimiento.idPlataforma.zonaHoraria;
        this.setState({
          enviar:
            zonaHoraria && zonaHoraria.toUpperCase().includes("MEXICO")
              ? true
              : false,
        });
      }
    }
    if (this.props.movimiento.idCliente) {
      let nombre = this.props.movimiento.idCliente.nombre;
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/plataforma/" +
          this.props.movimiento.idPlataforma.id +
          "/pagina/0/total/100" +
          "/cliente?cliente=" +
          encodeURIComponent(nombre),
        "clientes"
      );
    } else {
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/plataforma/" +
          this.props.movimiento.idPlataforma.id +
          "/pagina/0/total/100",
        "clientes"
      );
    }
    this.getfuntion(
      "get",
      "",
      "",
      "otd/plataforma/" + this.props.movimiento.idPlataforma.id,
      "otd"
    );
    this.getfuntion(
      "get",
      "",
      "",
      "rutasEstatus/plataforma/" + this.props.movimiento.idPlataforma.id,
      "rutasEstatus"
    );
  };
  componentWillUpdate = () => {

    if (this.movimiento !== this.props.movimiento) {
      this.movimiento = this.props.movimiento;
      this.setState({ id: this.props.movimiento.id });
      this.setState({ po: this.props.movimiento.po });
      this.setState({ booking: this.props.movimiento.booking });
      this.setState({
        ruta: {
          value: this.props.movimiento.idRuta.id,
          label: this.props.movimiento.idRuta.nombre,
        },
      });
      this.setState({
        cliente: {
          value: this.props.movimiento.idCliente.id,
          label: this.props.movimiento.idCliente.nombre,
        },
      });
      this.setState({
        otd: {
          value: this.props.movimiento.idOtd.id,
          label: this.props.movimiento.idOtd.nombre,
        },
      });
      this.setState({
        transportista: {
          value: this.props.movimiento.idTransportista.id,
          label: this.props.movimiento.idTransportista.nombre,
        },
      });
      this.setState({
        idMovimientoTipo: {
          value: this.props.movimiento.idMovimientoTipo.id,
          label: this.props.movimiento.idMovimientoTipo.nombre,
        },
      });
      this.setState({
        operacionEstatus: this.props.movimiento.idOperacionEstatus.id,
      });
      this.setState({ actualizarFull: this.props.movimiento.actualizarFull });
      this.setState({ sello: this.props.movimiento.sello });
      this.setState({ contenedor: this.props.movimiento.contenedor });
      this.setState({ estatusActual: this.props.movimiento.estatusActual });
      this.setState({
        citaProgramada: this.props.movimiento.citaProgramada
          ? new Date(this.props.movimiento.citaProgramada)
          : null,
      });
      this.setState({
        citaProgramadaTerminal: this.props.movimiento.citaProgramadaTerminal
          ? new Date(this.props.movimiento.citaProgramadaTerminal)
          : null
      });
      this.setState({
        citaColocacionGps: this.props.movimiento.citaColocacionGps
          ? new Date(this.props.movimiento.citaColocacionGps)
          : null
      });
      /** Datos Importacion */
      this.setState({
        arriboTerminalVacio: this.props.movimiento.arriboTerminalVacio
          ? new Date(this.props.movimiento.arriboTerminalVacio)
          : null,
      });
      this.setState({
        salidaTerminalLleno: this.props.movimiento.salidaTerminalLleno
          ? new Date(this.props.movimiento.salidaTerminalLleno)
          : null,
      });
      this.setState({
        arriboClienteLleno: this.props.movimiento.arriboClienteLleno
          ? new Date(this.props.movimiento.arriboClienteLleno)
          : null,
      });
      this.setState({
        salidaClienteVacio: this.props.movimiento.salidaClienteVacio
          ? new Date(this.props.movimiento.salidaClienteVacio)
          : null,
      });
      this.setState({
        arriboDirecionCarga: this.props.movimiento.arriboDirecionCarga
          ? new Date(this.props.movimiento.arriboDirecionCarga)
          : null,
      });
      this.setState({
        arriboTerminalVacio2: this.props.movimiento.arriboTerminalVacio2
          ? new Date(this.props.movimiento.arriboTerminalVacio2)
          : null,
      });
      this.setState({
        asignacionPiloto: this.props.movimiento.asignacionPiloto
          ? new Date(this.props.movimiento.asignacionPiloto)
          : null,
      });
      this.setState({
        inicioRuta: this.props.movimiento.inicioRuta
          ? new Date(this.props.movimiento.inicioRuta)
          : null,
      });

      this.setState({
        inicioRutaVacio: this.props.movimiento.inicioRutaVacio
          ? new Date(this.props.movimiento.inicioRutaVacio)
          : null,
      });
      this.setState({
        ingresoCliente: this.props.movimiento.ingresoCliente
          ? new Date(this.props.movimiento.ingresoCliente)
          : null,
      });
      this.setState({
        ingresoBodegaCarga: this.props.movimiento.ingresoBodegaCarga
          ? new Date(this.props.movimiento.ingresoBodegaCarga)
          : null,
      });

      this.setState({
        despachoPredioCliente: this.props.movimiento.despachoPredioCliente
          ? new Date(this.props.movimiento.despachoPredioCliente)
          : null,
      });
      this.setState({
        llegadaFrontera: this.props.movimiento.llegadaFrontera
          ? new Date(this.props.movimiento.llegadaFrontera)
          : null,
      });
      this.setState({
        salidaFrontera: this.props.movimiento.salidaFrontera
          ? new Date(this.props.movimiento.salidaFrontera)
          : null,
      });
      this.setState({
        arriboAduana: this.props.movimiento.arriboAduana
          ? new Date(this.props.movimiento.arriboAduana)
          : null,
      });
      this.setState({
        salidaAduana: this.props.movimiento.salidaAduana
          ? new Date(this.props.movimiento.salidaAduana)
          : null,
      });
      /* Datos Exportacion */
      this.setState({
        retiroContenedor: this.props.movimiento.retiroContenedor
          ? new Date(this.props.movimiento.retiroContenedor)
          : null,
      });
      this.setState({
        salidaClienteLleno: this.props.movimiento.salidaClienteLleno
          ? new Date(this.props.movimiento.salidaClienteLleno)
          : null,
      });
      this.setState({
        arriboClienteVacio: this.props.movimiento.arriboClienteVacio
          ? new Date(this.props.movimiento.arriboClienteVacio)
          : null,
      });
      this.setState({
        arriboTerminalCargado: this.props.movimiento.arriboTerminalCargado
          ? new Date(this.props.movimiento.arriboTerminalCargado)
          : null,
      });
      this.setState({
        salidaPredioVacio: this.props.movimiento.salidaPredioVacio
          ? new Date(this.props.movimiento.salidaPredioVacio)
          : null,
      });
      this.setState({
        citaFechaDespacho: this.props.movimiento.citaFechaDespacho
          ? new Date(this.props.movimiento.citaFechaDespacho)
          : null,
      });
      this.setState({
        entradaCiudadPortuaria: this.props.movimiento.entradaCiudadPortuaria
          ? new Date(this.props.movimiento.entradaCiudadPortuaria)
          : null,
      });
      this.setState({
        arriboPatioLinea: this.props.movimiento.arriboPatioLinea
          ? new Date(this.props.movimiento.arriboPatioLinea)
          : null,
      });
      this.setState({
        salidaTerminalVacio: this.props.movimiento.salidaTerminalVacio
          ? new Date(this.props.movimiento.salidaTerminalVacio)
          : null,
      });

      this.setState({
        inicioDeCarga: this.props.movimiento.inicioDeCarga
          ? new Date(this.props.movimiento.inicioDeCarga)
          : null,
      });
      this.setState({
        asignacionRampa: this.props.movimiento.asignacionRampa
          ? new Date(this.props.movimiento.asignacionRampa)
          : null,
      });      
      this.setState({
        finDeCarga: this.props.movimiento.finDeCarga
          ? new Date(this.props.movimiento.finDeCarga)
          : null,
      });
      this.setState({
        inicioDescarga: this.props.movimiento.inicioDescarga
          ? new Date(this.props.movimiento.inicioDescarga)
          : null,
      });
      this.setState({
        finDescarga: this.props.movimiento.finDescarga
          ? new Date(this.props.movimiento.finDescarga)
          : null,
      });

      this.setState({
        arriboCliente: this.props.movimiento.arriboCliente
          ? new Date(this.props.movimiento.arriboCliente)
          : null,
      });

      this.setState({
        
        recepcionFolios: this.props.movimiento.recepcionFolios
          ? new Date(this.props.movimiento.recepcionFolios)
          : null,
      });

      this.setState({
        salidaDestino: this.props.movimiento.salidaDestino
          ? new Date(this.props.movimiento.salidaDestino)
          : null,
      });

      this.setState({
        arriboDireccionEntrega: this.props.movimiento.arriboDireccionEntrega
          ? new Date(this.props.movimiento.arriboDireccionEntrega)
          : null,
      });

      this.setState({
        salidaDireccionEntrega: this.props.movimiento.salidaDireccionEntrega
          ? new Date(this.props.movimiento.salidaDireccionEntrega)
          : null,
      });
      this.getfuntion(
        "get",
        [],
        "",
        "movimientosPatio/idMovimiento/" + this.props.movimiento.id,
        "estaEnfull"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "transportistas/plataforma/" + this.props.movimiento.idPlataforma.id,
        "transportistas"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "movimientosTipo/plataforma/" + this.props.movimiento.idPlataforma.id,
        "movimientosTipo"
      );
      this.getfuntion(
        "get",
        "",
        "",
        "corredores/plataforma/" + this.props.movimiento.idPlataforma.id,
        "corredores"
      );
    }
  };
  componentDidMount = () => {
    this.setState({ id: this.props.movimiento.id });
    this.setState({ po: this.props.movimiento.po });
    this.setState({ booking: this.props.movimiento.booking });
    this.setState({
      ruta: {
        value: this.props.movimiento.idRuta.id,
        label: this.props.movimiento.idRuta.nombre,
      },
    });
    this.setState({
      cliente: {
        value: this.props.movimiento.idCliente.id,
        label: this.props.movimiento.idCliente.nombre,
      },
    });
    this.setState({
      otd: {
        value: this.props.movimiento.idOtd.id,
        label: this.props.movimiento.idOtd.nombre,
      },
    });
    this.setState({
      transportista: {
        value: this.props.movimiento.idTransportista.id,
        label: this.props.movimiento.idTransportista.nombre,
      },
    });
    this.setState({
      idMovimientoTipo: {
        value: this.props.movimiento.idMovimientoTipo.id,
        label: this.props.movimiento.idMovimientoTipo.nombre,
      },
    });

    this.setState({
      idCorredor: {
        value: this.props.movimiento.idCorredor.id,
        label: this.props.movimiento.idCorredor.nombre,
      },
    });

    this.setState({
      operacionEstatus: this.props.movimiento.idOperacionEstatus.id,
    });
    this.setState({ actualizarFull: this.props.movimiento.actualizarFull });
    this.setState({ sello: this.props.movimiento.sello });
    this.setState({ contenedor: this.props.movimiento.contenedor });
    this.setState({ estatusActual: this.props.movimiento.estatusActual });
    this.setState({
      citaProgramada: this.props.movimiento.citaProgramada
        ? new Date(this.props.movimiento.citaProgramada)
        : null,
    });
    this.setState({
      citaProgramadaTerminal: this.props.movimiento.citaProgramadaTerminal
        ? new Date(this.props.movimiento.citaProgramadaTerminal)
        : null,
    });
    this.setState({
      citaColocacionGps: this.props.movimiento.citaColocacionGps
        ? new Date(this.props.movimiento.citaColocacionGps)
        : null,
    });
    /** Datos Importacion */
    this.setState({
      arriboTerminalVacio: this.props.movimiento.arriboTerminalVacio
        ? new Date(this.props.movimiento.arriboTerminalVacio)
        : null,
    });
    this.setState({
      salidaTerminalLleno: this.props.movimiento.salidaTerminalLleno
        ? new Date(this.props.movimiento.salidaTerminalLleno)
        : null,
    });
    this.setState({
      arriboClienteLleno: this.props.movimiento.arriboClienteLleno
        ? new Date(this.props.movimiento.arriboClienteLleno)
        : null,
    });
    this.setState({
      salidaClienteVacio: this.props.movimiento.salidaClienteVacio
        ? new Date(this.props.movimiento.salidaClienteVacio)
        : null,
    });
    this.setState({
      arriboDirecionCarga: this.props.movimiento.arriboDirecionCarga
        ? new Date(this.props.movimiento.arriboDirecionCarga)
        : null,
    });
    this.setState({
      arriboTerminalVacio2: this.props.movimiento.arriboTerminalVacio2
        ? new Date(this.props.movimiento.arriboTerminalVacio2)
        : null,
    });
    this.setState({
      asignacionPiloto: this.props.movimiento.asignacionPiloto
        ? new Date(this.props.movimiento.asignacionPiloto)
        : null,
    });
    this.setState({
      inicioRuta: this.props.movimiento.inicioRuta
        ? new Date(this.props.movimiento.inicioRuta)
        : null,
    });

    this.setState({
      inicioRutaVacio: this.props.movimiento.inicioRutaVacio
        ? new Date(this.props.movimiento.inicioRutaVacio)
        : null,
    });
    this.setState({
      ingresoCliente: this.props.movimiento.ingresoCliente
        ? new Date(this.props.movimiento.ingresoCliente)
        : null,
    });
    this.setState({
      ingresoBodegaCarga: this.props.movimiento.ingresoBodegaCarga
        ? new Date(this.props.movimiento.ingresoBodegaCarga)
        : null,
    }); 


    this.setState({
      despachoPredioCliente: this.props.movimiento.despachoPredioCliente
        ? new Date(this.props.movimiento.despachoPredioCliente)
        : null,
    });
    this.setState({
      llegadaFrontera: this.props.movimiento.llegadaFrontera
        ? new Date(this.props.movimiento.llegadaFrontera)
        : null,
    });
    this.setState({
      salidaFrontera: this.props.movimiento.salidaFrontera
        ? new Date(this.props.movimiento.salidaFrontera)
        : null,
    });
    this.setState({
      arriboAduana: this.props.movimiento.arriboAduana
        ? new Date(this.props.movimiento.arriboAduana)
        : null,
    });
    this.setState({
      salidaAduana: this.props.movimiento.salidaAduana
        ? new Date(this.props.movimiento.salidaAduana)
        : null,
    });
    /* Datos Exportacion */
    this.setState({
      retiroContenedor: this.props.movimiento.retiroContenedor
        ? new Date(this.props.movimiento.retiroContenedor)
        : null,
    });
    this.setState({
      salidaClienteLleno: this.props.movimiento.salidaClienteLleno
        ? new Date(this.props.movimiento.salidaClienteLleno)
        : null,
    });
    this.setState({
      arriboClienteVacio: this.props.movimiento.arriboClienteVacio
        ? new Date(this.props.movimiento.arriboClienteVacio)
        : null,
    });
    this.setState({
      arriboTerminalCargado: this.props.movimiento.arriboTerminalCargado
        ? new Date(this.props.movimiento.arriboTerminalCargado)
        : null,
    });
    this.setState({
      salidaPredioVacio: this.props.movimiento.salidaPredioVacio
        ? new Date(this.props.movimiento.salidaPredioVacio)
        : null,
    });
    this.setState({
      citaFechaDespacho: this.props.movimiento.citaFechaDespacho
        ? new Date(this.props.movimiento.citaFechaDespacho)
        : null,
    });
    this.setState({
      entradaCiudadPortuaria: this.props.movimiento.entradaCiudadPortuaria
        ? new Date(this.props.movimiento.entradaCiudadPortuaria)
        : null,
    });
    this.setState({
      arriboPatioLinea: this.props.movimiento.arriboPatioLinea
        ? new Date(this.props.movimiento.arriboPatioLinea)
        : null,
    });
    this.setState({
      salidaTerminalVacio: this.props.movimiento.salidaTerminalVacio
        ? new Date(this.props.movimiento.salidaTerminalVacio)
        : null,
    });
    this.setState({
      destino: this.props.movimiento.destino
        ? this.props.movimiento.destino
        : "",
    });
    this.getfuntion(
      "get",
      [],
      "",
      "movimientosPatio/idMovimiento/" + this.props.movimiento.id,
      "estaEnfull"
    );

    console.log(this.state.otd)
    console.log("cambio estatus")
  };
  getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        switch (metodo) {
          case "get":
            {
              if (stateVar === "corredores") {
                this.corredores = [];
                returnVal.map((corredor, index) =>
                  this.corredores.push({
                    value: corredor.id,
                    label: corredor.nombre,
                    data: corredor,
                  })
                );
                this.setState({ corredores: this.corredores });
              }
              if (stateVar === "movimientosTipo") {
                this.movimientoTipos = [];
                returnVal.map((mt, index) =>
                  this.movimientoTipos.push({
                    value: mt.id,
                    label: mt.nombre,
                    data: mt,
                  })
                );
                this.setState({ movimientosTipo: this.movimientoTipos });
              }
              if (stateVar === "transportistas") {
                this.transportistas = [];
                returnVal.map((t, index) => {
                  this.transportistas.push({
                    value: t.id,
                    label: t.nombre,
                    data: t,
                  });
                });
                this.setState({ transportistas: this.transportistas });
              }
              if (stateVar === "estaEnfull") {
                if (returnVal.esFull) {
                  this.setState({ estaEnfull: true });
                }
              }
              if (stateVar === "camposLogisticos") {
                for (let cl of returnVal) {
                  if (!this.camposLogisticosObject[cl.nombreCampo])
                    this.camposLogisticosObject[cl.nombreCampo] = {};
                  this.camposLogisticosObject[cl.nombreCampo][
                    cl.idMovimientoTipo
                  ] = cl;
                }
                this.setState({ camposLogisticos: returnVal });
              }
              if (stateVar === "clientes") {
                this.clientes = [];
                if (returnVal.content.length > 0) {
                  returnVal.content.map((client, index) =>
                    this.clientes.push({
                      value: client.id,
                      label: client.nombre,
                      data: client,
                    })
                  );
                  this.setState({ clientes: this.clientes });
                } else {
                  this.setState({
                    cliente: { value: 0, label: "No hay clientes" },
                  });
                }
              }
              if (stateVar === "otd") {
                let otdsTemp = [];
                otdsTemp.push({ value: 0, label: "Selecciona Otd" });
                returnVal.map((ot, index) =>
                  otdsTemp.push({ value: ot.id, label: ot.nombre, data: ot })
                );
                this.setState({ otds: otdsTemp });
              }
              if (stateVar === "rutasEstatus") {
                let estatusRutasTem = [];
                estatusRutasTem.push({
                  value: 0,
                  label: "Selecciona Estatus de ruta",
                });
                returnVal.map((re, index) =>
                  estatusRutasTem.push({
                    value: re.id,
                    label: re.nombre,
                    data: re,
                  })
                );
                this.setState({ rutasEstatus: estatusRutasTem });
              }
            }
            break;
          case "put":
            {
            }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  VerificarFechasFuturas = (dt) => {
    //True es futura
    const currentTime = new Date();
    if (this.props.movimiento.idPlataforma.zonaHoraria) {
      const timeZonePais = currentTime.toLocaleString("en-US", {
        timeZone: this.props.movimiento.idPlataforma.zonaHoraria,
      });
      return new Date(dt).getTime() > new Date(timeZonePais).getTime()
        ? "No es una fecha valida"
        : false;
    }
  };
  VerificarEstadiaCorto = (cLg, fch) => {
    const antTemp = this.getFechaAnteriorEnSecuencia(cLg);
    if (!antTemp || !antTemp.obj) return "";
    if (!antTemp.val) return "";
    if (fch) {
      const dif = new Date(fch).getTime() - new Date(antTemp.val).getTime();
      if (dif < 1000 * 60 * 30)
        return "Aviso de tiempo corto de estadia (" + timeString(dif) + ")";
    }
    return false;
  };
  getCampLogByClvAndTipMov = (clv, tipoMov) => {
    if (this.camposLogisticosObject[clv])
      if (this.camposLogisticosObject[clv][tipoMov])
        return this.camposLogisticosObject[clv][tipoMov];
    return null;
  };
  getFechaAnteriorEnSecuencia = (cLg) => {
    switch (cLg.nombreCampo) {
      //IMPORTACIONES LOCALES(1)
      case "arribo_terminal_vacio":
        return null;
      case "salida_terminal_lleno":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_terminal_vacio",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.arriboTerminalVacio,
        };
      case "inicio_ruta":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_terminal_lleno",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaTerminalLleno,
        };
        case "inicio_ruta_vacio":
          return null;
          case "ingreso_cliente":
            return null;
            case "ingreso_bodega_carga":
              return null;
      case "arribo_cliente_lleno":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "inicio_ruta",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.inicioRuta,
        };
      case "asignacion_rampa":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_cliente_lleno",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.inicioRuta,
        };
      case "salida_cliente_vacio":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_cliente_lleno",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.arriboClienteLleno,
        };
      case "arribo_terminal_vacio_2":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_cliente_vacio",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaClienteVacio,
        };
      //EXPORTACION LOCAL(2)
      case "salida_predio_vacio":
        return null;
      case "arribo_cliente_vacio":
        if(this.props.movimiento.idPlataforma.id === 530){
          return {
            obj: this.getCampLogByClvAndTipMov(
              "salida_cliente_lleno",
              cLg.idMovimientoTipo
            ),
            val: this.campLogistObj.salidaClienteLleno,
          };
        }else{
          return {
            obj: this.getCampLogByClvAndTipMov(
              "salida_predio_vacio",
              cLg.idMovimientoTipo
            ),
            val: this.campLogistObj.salidaPredioVacio,
          };
        }
      case "salida_cliente_lleno":
        if(this.props.movimiento.idPlataforma.id === 530){
          return false;
        }else{
          return {
            obj: this.getCampLogByClvAndTipMov(
              "arribo_cliente_vacio",
              cLg.idMovimientoTipo
            ),
            val: this.campLogistObj.arriboClienteVacio,
          };
        }
      case "arribo_terminal_cargado":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_cliente_lleno",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaClienteLleno,
        };
      case "salida_terminal_vacio":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_terminal_cargado",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.arriboTerminalCargado,
        };
      //IMPORTACIONES CROSS BORDER(3)
      case "arribo_terminal_vacio":
        return null;
      case "salida_terminal_lleno":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_terminal_vacio",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.arriboTerminalVacio,
        };
      case "inicio_ruta":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_terminal_lleno",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaTerminalLleno,
        };
      case "llegada_frontera":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "inicio_ruta",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.inicioRuta,
        };
      case "salida_frontera":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "llegada_frontera",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.llegadaFrontera,
        };
      case "arribo_cliente_lleno":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_frontera",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaFrontera,
        };
      case "salida_cliente_vacio":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_cliente_lleno",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.arriboClienteLleno,
        };
      case "arribo_terminal_vacio_2":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_cliente_vacio",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaClienteVacio,
        };
      //EXPORTACION CROSS BORDER
      case "salida_predio_vacio":
        return null;
      case "arribo_cliente_vacio":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_predio_vacio",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaPredioVacio,
        };
      case "salida_cliente_lleno":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_cliente_vacio",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.arriboClienteVacio,
        };
      case "llegada_frontera":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_cliente_lleno",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaClienteLleno,
        };
      case "salida_frontera":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "llegada_frontera",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.llegadaFrontera,
        };
      case "arribo_terminal_cargado":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "salida_frontera",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.salidaFrontera,
        };
      case "salida_terminal_vacio":
        return {
          obj: this.getCampLogByClvAndTipMov(
            "arribo_terminal_cargado",
            cLg.idMovimientoTipo
          ),
          val: this.campLogistObj.arriboTerminalCargado,
        };
      default:
        return null;
    }
  };

  VerificarFechasSecuencia = (cLg, fch) => {
    const fchFutura = this.VerificarFechasFuturas(fch);
    if (fchFutura) return fchFutura;
    if (["salida_predio_vacio"].includes(cLg.nombreCampo)) {
      //No se valida secuencia
    } else {
      const antTemp = this.getFechaAnteriorEnSecuencia(cLg);
      if (!antTemp || !antTemp.obj) return "";
      if (!antTemp.val) return "No se ha capturado fecha " + antTemp.obj.nombre;
      if (fch) {
        const dtemp = new Date(fch);
        const dtemp2 = new Date(antTemp.val);
        const dif = dtemp.getTime() - dtemp2.getTime();
        if (dif < 0)
          return (
            "La fecha " +
            cLg.nombre +
            " no puede ser anterior a " +
            antTemp.obj.nombre
          );
      }
    }
  };

  changeInicioRutaVacio = (date) => {
    
    this.campLogistObj.inicioRutaVacio = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ inicioRutaVacio: date })
      : this.setState({ inicioRutaVacio: date });
  };

  // changeIngresoCliente = (event) => {
  //   this.MovimientosObject.ingresoCliente = event.target.value;
  //   this.setState({ ingresoCliente: event.target.value });
  // };
  changeIngresoCliente = (date) => {
    this.campLogistObj.ingresoCliente = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ ingresoCliente: date })
      : this.setState({ ingresoCliente: date });
  };

  changeIngresoBodegaCarga = (date) => {
    this.campLogistObj.ingresoBodegaCarga = timeToDateFill(
      new Date(date).getTime()
    );
    date != null
      ? this.setState({ ingresoBodegaCarga: date })
      : this.setState({ ingresoBodegaCarga: date });
  };



  changePo = (event) => {
    this.setState({ po: event.target.value });
    this.campLogistObj.po = event.target.value;
  };
  changeBooking = (event) => {
    this.setState({ booking: event.target.value });
    this.campLogistObj.booking = event.target.value;
  };
  changeRuta = (event) => {
    this.setState({ ruta: event });
    this.campLogistObj.idRuta = event.data;
  };
  changeCliente = (event) => {
    
    this.setState({ cliente: event });
    this.campLogistObj.idCliente = event.data;
  };
  changeOtd = (event) => {
    
    if(this.state.retardo && (![0,99].includes(event.value))){
      this.setState({retardo:false})
    }
    this.setState({ otd: event });
    this.campLogistObj.idOtd = event.data;
  };
  changeTransportista = (event) => {
    this.setState({ transportista: event });
    this.campLogistObj.idTransportista = event.data;
  };
  changeMovimientoTipo = (event) => {
    this.setState({ idMovimientoTipo: event });
    this.campLogistObj.idMovimientoTipo = event.data;
  };

  changeDestino = (event) => {
    this.setState({ destino: event.target.value });
    this.campLogistObj.destino = event.target.value;
  };

  changeCorredor = (event) => {
    this.corredores.map((elemento, index) => {
      if (event.value == elemento.data.id) {
        this.campLogistObj.idCorredor = elemento.data;
      }
    });
    this.setState({ idCorredor: event });
  };

  changeSello = (event) => {
    this.setState({ sello: event.target.value });
    this.campLogistObj.sello = event.target.value;
  };
  changeContenedor = (event) => {
    this.setState({ contenedor: event.target.value });
    this.campLogistObj.contenedor = event.target.value;
  };
  changeActFull = (event) => {
    this.setState({ actualizarFull: event.target.checked ? 1 : 0 });
    this.campLogistObj.actualizarFull = event.target.checked ? 1 : 0;
  };
  changeEstatusActual = (event) => {
    this.setState({ estatusActual: event.target.value });
    this.campLogistObj.estatusActual = event.target.value;
  };
  onChangeDate = (date) => {
    this.setState({ citaProgramada: date });
    this.campLogistObj.citaProgramada = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeCitaProgramadaTerminal = (date) => {
    this.setState({ citaProgramadaTerminal: date });
    this.campLogistObj.citaProgramadaTerminal = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeCitaColocacionGps = (date) => {
    this.setState({ citaColocacionGps: date });
    this.campLogistObj.citaColocacionGps = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboTerminalVacio = (date) => {
    this.setState({ arriboTerminalVacio: date });
    this.campLogistObj.arriboTerminalVacio = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };   
  /** Exportacion */
  onChangeRetiroContenedor = (date) => {
    this.setState({ retiroContenedor: date });
    this.campLogistObj.retiroContenedor = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeCitaFechaDespacho = (date) => {
    this.setState({ citaFechaDespacho: date });
    this.campLogistObj.citaFechaDespacho = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeEntradaCiudadPortuaria = (date) => {
    this.setState({ entradaCiudadPortuaria: date });
    this.campLogistObj.entradaCiudadPortuaria = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboPatioLinea = (date) => {
    this.setState({ arriboPatioLinea: date });
    this.campLogistObj.arriboPatioLinea = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  /**Importacion */
  onChangeArriboDirecionCarga = (date) => {
    this.setState({ arriboDirecionCarga: date });
    this.campLogistObj.arriboDirecionCarga = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeAsignacionDePiloto = (date) => {
    this.setState({ asignacionPiloto: date });
    this.campLogistObj.asignacionPiloto = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeDespachoPredioCliente = (date) => {
    this.setState({ despachoPredioCliente: date });
    this.campLogistObj.despachoPredioCliente = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeArriboAduana = (date) => {
    this.setState({ arriboAduana: date });
    this.campLogistObj.arriboAduana = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };
  onChangeSalidaAduana = (date) => {
    this.setState({ salidaAduana: date });
    this.campLogistObj.salidaAduana = date
      ? timeToDateFill(new Date(date).getTime())
      : date;
  };

  guardarMovimiento = () => {
    if (this.state.accion === "modificar") this.crud_put();
    else this.crud_post();
    this.props.cerrarModal();
  };
  //metodo = "get", obj = [], id = "",               catalogo = "",                                                 stateVar = "", hiddenModl = "", enviar
  crud_put = () => {
    this.props.getfuntion(
      "put",
      this.campLogistObj,
      this.props.movimiento,
      "navieraMovimientos/updateFechas/" + this.props.movimiento.id,
      "updateFechas",
      "",
      this.state.enviar
    );
  };

  componentDidUpdate(prevProps) {

    if(this.state.retardo && this.state.terminarServicio){
      this.scrollSpan.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  

  terminarMovimiento = () => {

    if(this.state.retardo){
      this.setState({terminarServicio:true})

    }else{
    
        let opEstatus = this.props.stor.operacionesEstatus;
        for (let item of opEstatus) {
          if (item.nombre.toUpperCase().includes("TERMINADO")) {
            this.campLogistObj.idOperacionEstatus = item;
          }
        }

        confirmAlert({
          title: "Atención",
          message:
            "¿Esta seguro de terminar el movimiento(" +
            this.props.movimiento.id +
            ") ?"+( this.validacionString.length? ", Se encontro lo siguente: "+this.validacionString:"" ),
          buttons: [
            {
              label: "Confirmar",
              onClick: () => {
                this.props.getfuntion(
                  "put",
                  this.campLogistObj,
                  this.props.movimiento,
                  "navieraMovimientos/updateFechas/" + this.props.movimiento.id,
                  "updateFechas",
                  "",
                  false
                );
              },
            },
            {
              label: "Cancelar",
              onClick: () => {
                return false;
              },
            },
          ],
        });
    }    
  };

  eliminarMovimiento = () => {
    let opEstatus = this.props.stor.operacionesEstatus;
    for (let item of opEstatus) {
      if (item.nombre.toUpperCase().includes("ELIMINADO")) {
        this.campLogistObj.idOperacionEstatus = item;
      }
    }
    confirmAlert({
      title: "Atención",
      message:
        "¿Esta seguro de eliminar el movimiento(" +
        this.props.movimiento.id +
        ") ?",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => {
            this.props.getfuntion(
              "put",
              this.campLogistObj,
              this.props.movimiento,
              "navieraMovimientos/updateFechas/" + this.props.movimiento.id,
              "updateFechas",
              "",
              false
            );
          },
        },
        {
          label: "Cancelar",
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };
  fileOnloadNew = (e) => {
    document.querySelector('img[name="prev"]').src = reader.result;
  };
  onChangeImage = (event) => {
    try {
      upImage = event.target.files[0];
      reader.onload = this.fileOnloadNew;
      reader.readAsDataURL(upImage);
      this.campLogistObj.imagen = upImage;
      this.props.setImagen(upImage);
    } catch (error) {}
  };
  getCamposLogisticos = () => {
    let camposLogist = [];
    if (!this.state.camposLogisticos) {
      return;
    }
    const PropiedadByclave = {
      /**
       * Importacion
       */
      arribo_terminal_vacio: "arriboTerminalVacio",
      salida_terminal_lleno: "salidaTerminalLleno",
      arribo_cliente_lleno: "arriboClienteLleno",
      asignacion_rampa: "asignacionRampa",
      salida_cliente_vacio: "salidaClienteVacio",
      arribo_direcion_carga: "arriboDirecionCarga",
      arribo_terminal_vacio_2: "arriboTerminalVacio2",
      asignacion_piloto: "asignacionPiloto",
      inicio_ruta: "inicioRuta",
      inicio_ruta_vacio: "inicioRutaVacio",
      ingreso_cliente: "ingresoCliente",
      ingreso_bodega_carga: "ingresoBodegaCarga",      
      despacho_predio_cliente: "despachoPredioCliente",
      llegada_frontera: "llegadaFrontera",
      salida_frontera: "salidaFrontera",
      arribo_aduana: "arriboAduana",
      salida_aduana: "salidaAduana",
      arribo_direccion_entrega : "arriboDireccionEntrega",
      salida_direccion_entrega : "salidaDireccionEntrega",

      //Tipo Distribuccion Samsung SDS Requerimiento unico
      inicio_de_carga: "inicioDeCarga",
      fin_de_carga:   "finDeCarga",
      inicio_de_descarga:"inicioDescarga",
      fin_de_descarga:"finDescarga",
      recepcion_de_folios:"recepcionFolios",
      salida_de_destino:"salidaDestino",      

      /**
       * Exportacion
       */
      retiro_contenedor: "retiroContenedor",
      salida_predio_vacio: "salidaPredioVacio",
      arribo_cliente_vacio: "arriboClienteVacio",
      salida_cliente_lleno: "salidaClienteLleno",
      arribo_terminal_cargado: "arriboTerminalCargado",
      salida_terminal_vacio: "salidaTerminalVacio",
      cita_fecha_despacho: "citaFechaDespacho",
      entrada_ciudad_portuaria: "entradaCiudadPortuaria",
      arribo_patio_linea: "arriboPatioLinea",
    };
    this.validacionString= !this.state.citaProgramada? "No se ha capturado fecha Inicio de ruta": "";
    this.state.camposLogisticos.map((cLog, index) => {
      let validacionString= "";
      switch (cLog.nombreCampo) {
        /**
         * Importacion
         */
        default:
          if(["llegada_frontera", "salida_frontera"].includes(cLog.nombreCampo)
            && [1,2].includes(this.props.movimiento.idMovimientoTipo.id) ){
              break;
          }
          if(["arribo_patio_linea", "inicio_ruta"].includes(cLog.nombreCampo)
            && [336, 337].includes(this.props.movimiento.idMovimientoTipo.id)
            && this.props.movimiento.idPlataforma.id === 3
          ){
              break;
          }
          if(this.state[PropiedadByclave[cLog.nombreCampo]]){
            validacionString= this.VerificarFechasSecuencia(
                                cLog,
                                this.state[PropiedadByclave[cLog.nombreCampo]] );
          }
          if( !this.state[PropiedadByclave[cLog.nombreCampo]] ){
            validacionString= "No se ha capturado fecha "+ cLog.nombre
          }
          if( validacionString && validacionString.length ){
            this.validacionString=
              this.validacionString
              +(this.validacionString.length ? ", ":"")
              +validacionString;
          }
          camposLogist.push(
            <Grid.Col md={4} lg={4}>
              <Form.Group label={cLog.nombre}>
                <DateTimePicker
                  format="y-MM-dd h:mm:ss a"
                  value={this.state[PropiedadByclave[cLog.nombreCampo]]}
                  onChange={(date) => {
                    this.setState({
                      [PropiedadByclave[cLog.nombreCampo]]: date,
                    });
                    this.campLogistObj[PropiedadByclave[cLog.nombreCampo]] =
                      date ? timeToDateFill(new Date(date).getTime()) : date;
                  }}
                />
              </Form.Group>
              <label className={"font-weight-bold"+(!this.state[PropiedadByclave[cLog.nombreCampo]]?"text-secondary":"text-danger")}>
                {validacionString}
              </label>
              <label className="font-weight-bold text-danger">
                {["salida_cliente_vacio", "salida_cliente_lleno"].includes(
                  cLog.nombreCampo
                )
                  ? this.state[PropiedadByclave[cLog.nombreCampo]] &&
                    this.VerificarEstadiaCorto(
                      cLog,
                      this.state[PropiedadByclave[cLog.nombreCampo]]
                    )
                  : null}
              </label>
            </Grid.Col>
          );
          break;
      }
    });
    return camposLogist;
  };

  _KeyDown_clientes = (e) => {
    let valid = "QWERTYUIOPASDFGHJKLLÑZXCVBNM-_().1234567890ÁÉÍÓÚ";
    let texto = "";
    let buscar = true;
    if (valid.toUpperCase().includes(e.key.toUpperCase())) {
      texto = e.target.value + e.key;
      for (let item of this.state.clientes) {
        if (item.label.toUpperCase().includes(texto.toUpperCase())) {
          buscar = false;
          break;
        }
      }
    }
    if (e.key !== " " && texto && buscar) {
      this.getfuntion(
        "get",
        "",
        "",
        "cliente/plataforma/" +
          this.props.movimiento.idPlataforma.id +
          "/pagina/0/total/100" +
          "/cliente?cliente=" +
          encodeURIComponent(texto),
        "clientes"
      );
    }
  };
  changeEnviar = (e) => {
    this.setState({ enviar: e.target.checked });
  };
  render() {
    return (
      <div>
         {/*console.log(this.state)*/} 
        <Grid.Row>
          <Grid.Col>
            <h1>
              {this.props.movimiento.idMovimientoTipo.nombre.toUpperCase()},
              Movimiento: {this.props.movimiento.id}
            </h1>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="PO(WO)">
              <Form.Input
                name="po"
                type="text"
                value={this.state.po}
                onChange={this.changePo}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Booking">
              <Form.Input
                name="booking"
                type="text"
                value={this.state.booking}
                onChange={this.changeBooking}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Sello">
              <Form.Input
                name="sello"
                type="text"
                value={this.state.sello}
                onChange={this.changeSello}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Contenedor">
              <Form.Input
                name="contenedor"
                type="text"
                value={this.state.contenedor}
                onChange={this.changeContenedor}
              />
            </Form.Group>
          </Grid.Col>
          {this.state.estaEnfull ? (
            <Grid.Col md={4}>
              <Form.Group label="Actualizar automaticamente movimiento en full">
                <Form.Switch
                  name="actualizarFull"
                  checked={this.state.actualizarFull}
                  onChange={this.changeActFull}
                />
              </Form.Group>
            </Grid.Col>
          ) : null}
          {[61, 292].includes(this.props.movimiento.idPlataforma.id) ? (
            <Grid.Col md={4}>
              <Form.Group label="Estatus Actual">
                <Form.Input
                  name="estatusActual"
                  type="text"
                  value={this.state.estatusActual}
                  onChange={this.changeEstatusActual}
                />
              </Form.Group>
            </Grid.Col>
          ) : null}
          <Grid.Col md={4}>
            <Form.Group label="Cita programada(Cliente)">
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                onChange={this.onChangeDate}
                value={this.state.citaProgramada}
              />
            </Form.Group>
            <label className="font-weight-bold text-danger">
              { this.state.citaProgramada? "": "No se ha capturado cita programada"}
            </label>
          </Grid.Col>
          {[61,1316].includes(this.props.movimiento.idPlataforma.id) ? (
          <Grid.Col md={4}>
            <Form.Group label="Cita programada(Terminal)">
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                onChange={this.onChangeCitaProgramadaTerminal}
                value={this.state.citaProgramadaTerminal}
              />
            </Form.Group>
            <label className="font-weight-bold text-danger">
              { this.state.citaProgramadaTerminal? "": "No se ha capturado cita programada terminal"}
            </label>
          </Grid.Col>
          ):null}
          <Grid.Col md={4}>
            <Form.Group label="Cita colocación Gps">
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                onChange={this.onChangeCitaColocacionGps}
                value={this.state.citaColocacionGps}
              />
            </Form.Group>
          </Grid.Col>
          {[61,1316].includes(this.props.movimiento.idPlataforma.id) ? (
            [270].includes(this.props.movimiento.idMovimientoTipo.id) ? (
          <Grid.Col md={4}>
            <Form.Group label="Arribo terminal vacio">
              <DateTimePicker
                format="y-MM-dd h:mm:ss a"
                onChange={this.onChangeArriboTerminalVacio}
                value={this.state.arriboTerminalVacio}
              />
            </Form.Group>
            <label className="font-weight-bold text-danger">
              { this.state.arriboTerminalVacio? "": "No se ha capturado el arribo terminal vacio"}
            </label>
          </Grid.Col>
            ):null
          ):null}
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group isRequired label="Estatus ruta">
              <Select
                value={this.state.ruta}
                onChange={this.changeRuta}
                options={this.state.rutasEstatus}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group isRequired label="Cliente">
              <Select
                value={this.state.cliente}
                onChange={this.changeCliente}
                options={this.state.clientes}
                isLoading={this.state.clientes.length == 0 ? true : false}
                onKeyDown={this._KeyDown_clientes}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4} className={this.state.retardo && this.state.terminarServicio ? "error" : ""}>
          <span ref={this.scrollSpan}></span>
            <Form.Group isRequired label="Otd" className={"c-label"}>
              <Select
                value={this.state.setOtd ? {value: 99, label: "Sin Código" } : this.state.otd}
                isDisabled={this.state.setOtd}
                onChange={this.changeOtd}
                options={this.state.otds}
                className={"selectOTD"}
                
              />
            {this.state.retardo && this.state.terminarServicio ? (
             <div className="error-message">
              <span className="error-text">
                Se ha detectado un retraso en la hora de llegada a la cita programada, favor de seleccionar la causante
              </span>
             </div>
            ) : null}
           
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Transportistas">
              <Select
                value={this.state.transportista}
                onChange={this.changeTransportista}
                options={this.state.transportistas}
                isLoading={this.state.transportistas.length == 0 ? true : false}
              />
            </Form.Group>
          </Grid.Col>

          <Grid.Col md={4}>
            <Form.Group label="Tipo de Movimiento">
              <Select
                value={this.state.idMovimientoTipo}
                onChange={this.changeMovimientoTipo}
                options={this.state.movimientosTipo}
                isLoading={
                  this.state.movimientosTipo.length == 0 ? true : false
                }
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={4}>
            <Form.Group label="Destino">
              <Form.Input
                name="destino"
                type="text"
                value={this.state.destino}
                onChange={this.changeDestino}
                maxlength="30"
              />
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col md={4}>
            <Form.Group label="Corredores">
              <Select
                value={this.state.idCorredor}
                onChange={this.changeCorredor}
                options={this.state.corredores}
                isLoading={this.state.corredores.length == 0 ? true : false}
              />
            </Form.Group>
          </Grid.Col>

          {[61, 292].includes(this.props.movimiento.idPlataforma.id) ? (
            <Grid.Col md={4}>
              <Form.Group label={<Form.Label>Nota</Form.Label>}>
                <Form.Textarea
                  name="comentario"
                  onChange={(e) =>
                    this.props.setComentario(encodeURIComponent(e.target.value))
                  }
                  placeholder="Ingresa aqui una nota.."
                  rows={6}
                />
              </Form.Group>
            </Grid.Col>
          ) : null}
          {[61, 292].includes(this.props.movimiento.idPlataforma.id) ? (
            <div>
              <Grid.Col md={4}>
                <Form.Group label="Imagen">
                  <input
                    type="file"
                    id="imegen"
                    ref={this.fileInput}
                    className="file"
                    accept="image/*"
                    onChange={this.onChangeImage}
                  />
                  <img
                    name="prev"
                    src="images/loadPicture.png"
                    width="50%"
                    align="center"
                    border="0"
                  />
                  <Button
                    target="_blank"
                    size="sm"
                    RootComponent="a"
                    color="primary"
                    pill
                    onClick={(e) => {
                      try {
                        //navigator.permissions.query({ name: "clipboard-read" }).then((result) => {
                        //  if (result.state == "granted" || result.state == "prompt") {
                        navigator.clipboard.read().then((data) => {
                          for (let i = 0; i < data.length; i++) {
                            if (!data[i].types.includes("image/png")) {
                              alert("No parece ser una imagen");
                            } else {
                              data[i].getType("image/png").then((blob) => {
                                upImage = blob;
                                reader.onload = this.fileOnloadNew;
                                reader.readAsDataURL(upImage);
                                var file = new File(
                                  [blob],
                                  "imagen" + new Date().getTime() + ".png"
                                );
                                this.props.setImagen(file);
                              });
                            }
                          }
                        });
                        //  }
                        //});
                      } catch (error) {}
                    }}
                  >
                    <span class="badge">
                      <Icon link={true} name="image" />
                      pegar
                    </span>
                  </Button>
                </Form.Group>
              </Grid.Col>
            </div>
          ) : null}

        </Grid.Row>

        <Grid.Row>{this.getCamposLogisticos()}</Grid.Row>
        <Grid.Row>
          <Grid.Col md={12}>
            <Form.Group label="Enviar correo">
              <Form.Switch
                type="Checkbox"
                checked={this.state.enviar}
                onChange={this.changeEnviar}
              />
            </Form.Group>
            {this.props.destinatarios ? (
              <p>{this.props.destinatarios}</p>
            ) : (
              <p></p>
            )}
            {this.state.enviar && this.props.destinatarios ? (
              this.props.destinatarios.length ? null : (
                <h3>
                  No hay destinatarios a los que enviar Notificación(Arroba
                  azul(Estatus))
                </h3>
              )
            ) : null}
          </Grid.Col>
          <Grid.Col>
            <Form.Group className="text-red" label={ 
                 (this.state.citaProgramada != null && fechaValida(this.state.citaProgramada) === false) 
              || (this.state.citaProgramadaTerminal != null && fechaValida(this.state.citaProgramadaTerminal) === false) 
              || (this.state.arriboTerminalVacio != null && fechaValida(this.state.arriboTerminalVacio) === false)
              || (this.state.retiroContenedor != null && fechaValida(this.state.retiroContenedor) === false)
              || (this.state.arriboClienteVacio != null && fechaValida(this.state.arriboClienteVacio) === false)
              || (this.state.arriboTerminalCargado != null && fechaValida(this.state.arriboTerminalCargado) === false)
              || (this.state.ingresoBodegaCarga != null && fechaValida(this.state.ingresoBodegaCarga) === false)
              || (this.state.ingresoCliente != null && fechaValida(this.state.ingresoCliente) === false)
              || (this.state.inicioRuta != null && fechaValida(this.state.inicioRuta) === false)
              || (this.state.inicioRutaVacio != null && fechaValida(this.state.inicioRutaVacio) === false)              
              || (this.state.salidaPredioVacio != null && fechaValida(this.state.salidaPredioVacio) === false)
              || (this.state.salidaClienteLleno != null && fechaValida(this.state.salidaClienteLleno) === false)
              || (this.state.arriboClienteLleno != null && fechaValida(this.state.arriboClienteLleno) === false)
              || (this.state.arriboDirecionCarga != null && fechaValida(this.state.arriboDirecionCarga) === false)
              || (this.state.arriboPatioLinea != null && fechaValida(this.state.arriboPatioLinea) === false)
              || (this.state.arriboTerminalVacio2 != null && fechaValida(this.state.arriboTerminalVacio2) === false)
              || (this.state.asignacionPiloto != null && fechaValida(this.state.asignacionPiloto) === false)
              || (this.state.citaFechaDespacho != null && fechaValida(this.state.citaFechaDespacho) === false)
              || (this.state.despachoPredioCliente != null && fechaValida(this.state.despachoPredioCliente) === false)
              || (this.state.entradaCiudadPortuaria != null && fechaValida(this.state.entradaCiudadPortuaria) === false)
              || (this.state.llegadaFrontera != null && fechaValida(this.state.llegadaFrontera) === false)
              || (this.state.salidaAduana != null && fechaValida(this.state.salidaAduana) === false)
              || (this.state.salidaClienteVacio != null && fechaValida(this.state.salidaClienteVacio) === false)
              || (this.state.salidaFrontera != null && fechaValida(this.state.salidaFrontera) === false)
              || (this.state.salidaTerminalLleno != null && fechaValida(this.state.salidaTerminalLleno) === false)
              || (this.state.salidaTerminalVacio != null && fechaValida(this.state.salidaTerminalVacio) === false)
              ? <h4>"No puedes actualizar los datos (Botón de Guardar inhabilitado) ya que una fecha esta incorrecta"</h4> : null }>                          
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
        <div className="float-right">
          <span class="badge">                       
            <Button
              disabled={ (this.state.ruta.value === 0 || this.state.cliente.value === 0 )
                || !( (this.state.citaProgramada == null || (this.state.citaProgramada && fechaValida(this.state.citaProgramada) === true ))
                && (this.state.citaProgramadaTerminal == null || (this.state.citaProgramadaTerminal && fechaValida(this.state.citaProgramadaTerminal) === true )) 
                && (this.state.arriboTerminalVacio == null || (this.state.arriboTerminalVacio && fechaValida(this.state.arriboTerminalVacio) === true ))                 
                && (this.state.retiroContenedor == null || (this.state.retiroContenedor && fechaValida(this.state.retiroContenedor) === true ))
                && (this.state.arriboClienteVacio == null || (this.state.arriboClienteVacio && fechaValida(this.state.arriboClienteVacio) === true ))
                && (this.state.arriboTerminalCargado == null || (this.state.arriboTerminalCargado && fechaValida(this.state.arriboTerminalCargado) === true ))
                && (this.state.ingresoBodegaCarga == null || (this.state.ingresoBodegaCarga && fechaValida(this.state.ingresoBodegaCarga) === true ))
                && (this.state.ingresoCliente == null || (this.state.ingresoCliente && fechaValida(this.state.ingresoCliente) === true ))  
                && (this.state.inicioRuta == null || (this.state.inicioRuta && fechaValida(this.state.inicioRuta) === true )) 
                && (this.state.inicioRutaVacio == null || (this.state.inicioRutaVacio && fechaValida(this.state.inicioRutaVacio) === true )) 
                && (this.state.salidaPredioVacio == null || (this.state.salidaPredioVacio && fechaValida(this.state.salidaPredioVacio) === true )) 
                && (this.state.salidaClienteLleno == null || (this.state.salidaClienteLleno && fechaValida(this.state.salidaClienteLleno) === true ))
                && (this.state.arriboClienteLleno == null || (this.state.arriboClienteLleno && fechaValida(this.state.arriboClienteLleno) === true ))
                && (this.state.arriboDirecionCarga == null || (this.state.arriboDirecionCarga && fechaValida(this.state.arriboDirecionCarga) === true ))
                && (this.state.arriboPatioLinea == null || (this.state.arriboPatioLinea && fechaValida(this.state.arriboPatioLinea) === true ))
                && (this.state.arriboTerminalVacio2 == null || (this.state.arriboTerminalVacio2 && fechaValida(this.state.arriboTerminalVacio2) === true ))
                && (this.state.asignacionPiloto == null || (this.state.asignacionPiloto && fechaValida(this.state.asignacionPiloto) === true ))
                && (this.state.citaFechaDespacho == null || (this.state.citaFechaDespacho && fechaValida(this.state.citaFechaDespacho) === true ))
                && (this.state.despachoPredioCliente == null || (this.state.despachoPredioCliente && fechaValida(this.state.despachoPredioCliente) === true ))
                && (this.state.entradaCiudadPortuaria == null || (this.state.entradaCiudadPortuaria && fechaValida(this.state.entradaCiudadPortuaria) === true ))
                && (this.state.llegadaFrontera == null || (this.state.llegadaFrontera && fechaValida(this.state.llegadaFrontera) === true ))
                && (this.state.salidaAduana == null || (this.state.salidaAduana && fechaValida(this.state.salidaAduana) === true ))
                && (this.state.salidaClienteVacio == null || (this.state.salidaClienteVacio && fechaValida(this.state.salidaClienteVacio) === true ))
                && (this.state.salidaFrontera == null || (this.state.salidaFrontera && fechaValida(this.state.salidaFrontera) === true ))
                && (this.state.salidaTerminalLleno == null || (this.state.salidaTerminalLleno && fechaValida(this.state.salidaTerminalLleno) === true ))
                && (this.state.salidaTerminalVacio == null || (this.state.salidaTerminalVacio && fechaValida(this.state.salidaTerminalVacio) === true ))
                )    }
              target="_blank"
              size="sm"
              RootComponent="a"
              color="primary"
              onClick={this.guardarMovimiento}
            >
              <span class="badge">
                <Icon link={true} name="save" />
                Guardar
              </span>
            </Button>
          </span>
          <span class="badge" data-toggle="tooltip" data-placement="top"
            title={this.validacionString}>
            <Button /*
            disabled={
              this.props.movimiento.idPlataforma.zonaHoraria
                ?
                  !this.props.movimiento.idPlataforma.zonaHoraria.toUpperCase().includes("MEXICO")
                    && this.validacionString.length
                    && !this.state.ruta.label.toUpperCase().includes("CANCELADO")
                    && !this.state.ruta.label.toUpperCase().includes("PROGRAMADA")
                  ? true
                  :false
                :false 
            }*/
              target="_blank"
              size="sm"
              RootComponent="a"
              color="success"
              onClick={() => this.terminarMovimiento()}
            >
              <span class="badge">Terminar Movimiento</span>
            </Button>
          </span>
          {this.estaPrivilegioActivo("movimientos_borrar") ? (
            <span className="badge float-right">
              <Button
                target="_blank"
                size="sm"
                RootComponent="a"
                color="danger"
                onClick={() => this.eliminarMovimiento()}
              >
                <span class="badge">
                  <Icon link={true} name="trash" />
                  Eliminar
                </span>
              </Button>
            </span>
          ) : null}
        </div>
      </div>
    );
  }
  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.stor.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };
}
export default ActualizarHorariosNavieros;
