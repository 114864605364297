import React, { Component ,useState,useEffect,ReactDOM, Fragment} from "react";
import SiteWrapper from "../../../SiteWrapper.react";
import {Button, Icon,Dimmer,Table,List} from "tabler-react";
import Select from "react-select";
import ModalLayout from "../../../components/ModalLayout.react.js";
import TablePageable  from "./Table.react";
import TransportistasForm  from "../../../Catalogos/Transportistas/TransportistasForm";
import Call_Otds from "./Call_Otds";
import { NotificationManager} from 'react-notifications';
import Modal from "react-modal";
import CollapsibleTable from "./tableCollapsed"
import { set } from "date-fns";
import { Link } from "@mui/material";



const DetalleRemolques = (props) => {


  const plataforma = JSON.parse(sessionStorage.getItem("plataforma")).id;
  const usuario  = JSON.parse(sessionStorage.getItem("usuario")).id
  const transportista  = JSON.parse(sessionStorage.getItem("usuario")).id
  const [listaUnidades, setListaUnidades] = useState([]);
  const [time, setTime] = useState(Date.now());
  const [listaColumns,setListaColumns] = useState();
   //Carga de pantalla de carga
  const [isloading, setisLoading] = useState(false);
  const [data,setData] =  useState();
  const [dataT,setDataT] =  useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTIsOpen, setModalTIsOpen] = useState(false);
  const [idArrastreColumna,setIdArrastreColumna] =  useState({col: "", Desc: true});
  const [bitacoraTableHeader,setBitacoraTableHeader] =  useState([]);
  const [bitacora,setBitacora]= useState([]);
  const [loadingEntregas,setLoadingEntregas] = useState(false);
  const [hiddenModals,setHiddenModals] = useState(false);
  const [listaCorredores, setListaCorredores] = useState([]);
  const [corredor, setCorredor] = useState([]);
  const [listaUnidadesT, setListaUnidadesT] = useState([]);


  
  const openModal = () => {
    setModalIsOpen(true);
  };

  const openModalT = () => {
    setModalTIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  const closeModalT = () => {
    setModalTIsOpen(false);
  };

  const getMetricas = (idTransportista,nombreCorto)=>{

    return (
      <Button  size="sm" outline color="primary"  name="toggle" value={idTransportista}
         onClick={(e)=> updatePropsT(idTransportista)} >{nombreCorto}</Button>
      )
   // getfuntion("get",{ids:idTransportista},{}, "disponibilidadRemolquesTransportistas/remolques/transportistas",5);
  }
  let dataTemp;

  const updateProps = (newValue,lista,unidades) =>{

    openModal()
  let newValor = {listaRemolques:unidades,data:newValue,tiposRemolques:lista};
  let datosForm={
    accion: "modificar",
    isEnable:"agregar",
    plataforma: {plataformaTipo:{id:plataforma}},
    getfuntion: getfuntion,
    tipo:2,
    stor: {privilegios_object:[],plataforma:plataforma,usuario:usuario,props:newValor},
    elementos:{}};
    setData(datosForm)

    //console.log(newValue)
   
   
    
    
 }

 const updatePropsT = (transportista) =>{

  getfuntion("get",{ids:transportista},{}, "disponibilidadRemolquesTransportistas/remolques/transportistas/metricas",5);

}

 function BitacoraView ()
 {
  return (
    <div >
          <ModalLayout
            tamanio={"1200px"}
            title={"Bitacora de arrastre "}
            name={<Button pill size="sm" outline color="primary" 
            ><Icon name="list" />{"Bitacora de cambios ("+ ")"}</Button>}
            hiddenModal={hiddenModals} Fclose={hiddenmodal}
            formulario={
              <Dimmer active={loadingEntregas} loader>
                <Table cards={false} striped={false} responsive={true} className="table-vcenter" style={{color:"black", "font-size": "80%"}} > {/** headerItems={ state.incidenciatableheader} */}
                  <Table.Header>
                    {bitacoraTableHeader.map((item, i) =>
                        <Table.ColHeader key={i}>
                          {item.content}
                        </Table.ColHeader>)}
                  </Table.Header>{/* incidenciaColumna* */}
                  <Table.Body>
                    {
                  
                  //se ocultan bitacora de gps(7) ejp: Gps reportando sin velocidad
                  bitacora.length > 0&&  
                  bitacora.map((e, index) =>
                        <Table.Row >
                          <Table.Col>{e.idBitacoraMonitoreo}</Table.Col>
                          <Table.Col>{e.descripcion}</Table.Col>
                          <Table.Col>{e.idUsuario}</Table.Col>
                          <Table.Col>{e.accionFecha}</Table.Col>
                                                                            
                        </Table.Row>
                      )}
                    
                  </Table.Body>
                </Table>
              </Dimmer>                                    
            }
          />
        </div>
        )

 }
 
 
  
  useEffect(() => {
    
    getfuntion("get",{},{}, "localidades",3)

    getfuntion("get",{id:plataforma},{}, "remolques/plataforma",2);


    // 
   
  
  },[data])

  const button = ((row)=>
  
    (<>
    <Button  size="sm" outline color="primary"  name="toggle" value={row.id}
       
       onClick={(e)=> updateProps(row)} ><Icon name="plus" /></Button>
    </>)
  )

  const getColumns = (afterData,lista)=>{

    
    let arrayColumns =  [];
    afterData.forEach(element => {
      if(element.remolquesTransportistas.findIndex((remolque)=> remolque.disponibilidadRemolques != null) != -1){
            element.metricas = getMetricas(element.idTransportista,element.nombreCorto);
            element.button = button(element)
            arrayColumns.push(element)
      }
      
      
    });

    
   setListaUnidades(arrayColumns)
  }


  const getColumnsBitacora = (lista)=>{
    let arrayColumns = [
      { columna: "corredor.id", label: "Id" },
      { columna: "corredor.nombre", label: "Localidad" },
      { columna: "fechaCreacion", label: "Hora" }

    ]
  
    lista.forEach((remolque)=>{
      arrayColumns.push({columna:"",label:remolque.nombre})
    });
    // arrayColumns.push({ columna: (<Html tiposRemolques={lista} updateProps={updateProps} />),label:"Actualizar"});
    arrayColumns.push( { columna: "Actualizar", label: "Actualizar",
     
      form: (e)=> <Button pill size="sm" outline color="primary" type="Checkbox" name="toggle" value="option1"
     // checked={ incidencias.filter(i => i.incidencia.id === e.id  ).length }
      onClick={()=> updateProps(e,lista)} ><Icon name="edit" /></Button>
    })
    arrayColumns.push({ columna:"comentarios",label:"Comentarios"})

    setListaColumns(arrayColumns)
  }

 const table_headers_bitacora = (asc, col) => {
  //console.log(col)
    const columsTemp=[
      { col:"idBitacoraMonitoreo", label: "Evento" },
      { col:"descripcion", label: "Detalle" },
      { col:"idUsuario", label: "N0mbre Usuario" },
      { col:"accionFecha", label: "Fecha" }
    ];
    let columnaTemp=[];
    for(let c of columsTemp){//incidenciaColumna: {col: "fechaCreado", Desc: true},
      columnaTemp.push({
        content: (
          <div  onClick={() =>  sortArrastre(c.col) } >
            { c.label }
            { col === c.col ? ( <Icon name={asc ? "chevron-down":"chevron-up"} />) : null}
          </div>
        ),
      });
    }
    setBitacoraTableHeader(columnaTemp);
  };


 const sortArrastre = (columna= idArrastreColumna.col) => {
    let oldCol = idArrastreColumna.col;
    let AscDescTemp= idArrastreColumna.Desc;
    if (oldCol === columna) {
      AscDescTemp = !AscDescTemp;
    } else {
      AscDescTemp = true;
    }

    setIdArrastreColumna ({col: columna, Desc: AscDescTemp});
    if (oldCol === columna) {
      table_headers_bitacora( AscDescTemp, columna );
    } else {
      table_headers_bitacora(true, columna);
    }

    var originalArray = [...bitacora];
    if (!originalArray[0]) return;

    if (AscDescTemp) {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return -1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] < b[columna]) {
            return 1;
          }
          if (a[columna] > b[columna]) {
            return -1;
          }
          return 0;
        });
      }
      //return a[columna]-b[columna] }); //Asecendente
    } else {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] === null || b[columna] === null) {
            return 1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre > b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre < b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function(a, b) {
          if (a[columna] > b[columna]) {
            return 1;
          }
          if (a[columna] < b[columna]) {
            return -1;
          }
          return 0;
        });
      }
    }
    setBitacora(originalArray);
  }

  const milliseconds = (h, m, s) => ((h*60*60+m*60+s)*1000);


  useEffect(() => {
    const interval = setInterval(() =>{
     
      listaUnidades.forEach((item)=>{
        item.remolquesTransportistas.forEach((remolques)=>{
          if(remolques.disponibilidadRemolques.total_apartado > 0){

            let apartado  = remolques.apartado;
            
            let caducidad = remolques.disponibilidadRemolques.fechaApartado;

            if(caducidad !=  null){
              let JSON_REQUEST = [];
              let dateCaducidad = new Date(caducidad); // some mock date
              let millisecondsCaducidad = dateCaducidad.getTime(); 

              
  
              let  millisecondsApartado = milliseconds(apartado.split(":")[0],apartado.split(":")[1],0);
              let dateNow   = new Date();
              let millisecondsNow = dateNow.getTime();
              let milisecondsValidate  = millisecondsCaducidad + millisecondsApartado;
  
              if(millisecondsNow > milisecondsValidate){

                JSON_REQUEST.push({
                  "idDisponibilidadTransportistaRemolque" : remolques.disponibilidadRemolques.id,
                  "total_disponible":remolques.disponibilidadRemolques.total_disponible + remolques.disponibilidadRemolques.total_apartado,
                  "total_apartado":0,
                  "total_ocupado":remolques.disponibilidadRemolques.total_ocupado,
                  "comentarios":remolques.disponibilidadRemolques.comentarios
            
                });

                getfuntion("put",JSON_REQUEST,"","disponibilidadRemolquesTransportistas")
                console.log("Actualizo apartados")
              }
  
  
            };

            

          }
         
        })
      })
    }
    ,1000);
    return () => {
      clearInterval(interval);
    };
  }, [listaUnidades]);

  const getfuntion = (
    metodo = "get",
    params,
    id = "",
    endPoint,
    type,
    afterData
  ) => {
    Call_Otds(
      endPoint,
      metodo,
      id,
      params,

    )
      .then((returnVal) => {
        setisLoading(false);
        switch (metodo) {
          case "get":
            {
              if (returnVal && type == 1 ) {
         
                getColumns(returnVal,"")
                //getfuntion("get",{id:plataforma},{}, "remolques/plataforma",2,returnVal);
                //getfuntion("get",{},{},"arrastreLog/idUsuario/"+usuario + "/idTransportista/"+ returnVal[0].remolquesTransportistas[0].idTransportistaLocalidades.idTransportista+  "/plataformatipo/1",4); 
                getfuntion("get",{},{},"arrastreLog/localidad/"+ returnVal[0].corredor.id,4); 

               
              }
              if(returnVal && type  == 2){
                getColumnsBitacora(afterData,returnVal.remolquesTipos)
              }

              if(returnVal && type== 3){


                let corredoresTemp = [];
                Object.keys(returnVal).forEach((key) => corredoresTemp.push({ value: returnVal[key].id, label: returnVal[key].nombre} )); 
                setListaCorredores(corredoresTemp)
                
              }

              if(returnVal && type== 4){
                setBitacora(returnVal);

                setTimeout(() => {
                table_headers_bitacora(true, "fechaCreado");
                }, 100);
              }

              if(returnVal && type == 5){
                let datosForm={
                  accion: "nuevo",
                  isEnable:"Metricas",
                  plataforma: {plataformaTipo:{id:plataforma}},
                  getfuntion: getfuntion,
                  tipo:3,
                  stor: {privilegios_object:[],plataforma:plataforma,usuario:usuario,props:returnVal,heads:["Localidad","Total Remolques","% Ocupacion"]},
                  elementos:{}};
                setDataT(datosForm)
                openModalT()
               
              }
              setisLoading(true);
            }
            break;
          case "put":{
            NotificationManager.info("Guardado correctamente");
            let Localidad =  returnVal[0].idTransportistaRemolque.idTransportistaLocalidades.idLocalidad;
            getfuntion("get",{ids:Localidad},{}, "disponibilidadRemolquesTransportistas/remolques/localidades",1);
          } 
          break; 
          default:
            break;
        }
      })
      .catch((err) => {
        setisLoading(false);
        if (err.response) {
          console.log(err.response);
        } else {
        console.log("Error desconocido ...." + err);
        }
      });
  };

  const hiddenmodal = () => {
  
    setHiddenModals(!hiddenModals );
  };

  const handleChange = (e)=>{
   
    setCorredor(e)
    getfuntion("get",{ids:e.value},{}, "disponibilidadRemolquesTransportistas/remolques/localidades",1);
    getfuntion("get",{},{},"arrastreLog"+ "/idTransportista/"+ e.value+  "/plataformatipo/1",4); 
  
  }
  

  return (
    <SiteWrapper>

      

          <div className="container remolques-container">

          {<div className="col-12">
                {listaUnidades && listaUnidades.length > 0 ? ( 
               
                <BitacoraView/>

                
              ):null} </div>} 

          <div className="selector-localidad">
          <List.Group>
             <List.GroupItem action icon="user"   className="operador-flex"> Selecciona a una localidad:<Select className="select-operador" name="idTransportista" value={corredor } defaultValue={corredor} onChange={(e,a)=>{ handleChange(e); }} options={listaCorredores} />    
              </List.GroupItem>      
          </List.Group>
          </div>
          
          <div className="row">
          {listaUnidades && listaUnidades.length > 0 ? ( 
          <CollapsibleTable data={listaUnidades} temporizador={22} heads={["LT","Localidad","Total Remolques","% Ocupacion","Registrar"]} ></CollapsibleTable>
        ):
        <div align="center" className="notfound">

  <>
  <p align="center" className="titulo">{corredor != '' ? "No se han cargado remolques para este correedor el dia de hoy" : "Selecciona una localidad"}</p>
  <div id="mundo">
     <div id="coche">
       <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExYXEzaGcyc3ZiM3puYWluNm8ycTk5cjNrYXdwajd2OGxoczJncXpveCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/JZLzh2I7wlYQAt84Xp/giphy.gif" alt="">
     </img>
       <div id="smoke"></div>
     </div>
     
   </div>
  </>
                 
                </div>
        
        }      
          
            

            <Modal
            closeTimeoutMS={50}
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="Modal"
            overlayClassName="Overlay"
            style={
              props.resumirModal
                ? {
                    overlay: { display: "none" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
                : {
                    overlay: { display: "block" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
            }
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"> {"Agregar"}</h4>
                <div class="btn-group">
                  {["Modificar", "Nuevo"].includes("Agregar") && (
                    <button
                      type="button"
                      className="btn btn-primary fa fa-minus"
                      onClick={props.funcionReanudar}
                    />
                  )}
                  <button
                    type="button"
                    className="btn btn-danger fa fa-close outline pill"
                    onClick={closeModal}
                  />
                </div>
              </div>
              <div className="modal-body">
                <TransportistasForm {...data} cerrarModal={closeModal} />
              </div>
            </div>
          </Modal>


          <Modal
            closeTimeoutMS={50}
            isOpen={modalTIsOpen}
            onRequestClose={closeModalT}
            className="Modal"
            overlayClassName="Overlay"
            style={
              props.resumirModal
                ? {
                    overlay: { display: "none" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
                : {
                    overlay: { display: "block" },
                    content: {
                      position: "relative",
                      top: "60px",
                      width:
                        window.screen.width > 900
                          ? "90%"
                          : window.screen.width - 20,
                      height: "auto",
                      margin: "auto",
                    },
                  }
            }
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title"> {"Agregar"}</h4>
                <div class="btn-group">
                  {["Modificar", "Nuevo"].includes("Agregar") && (
                    <button
                      type="button"
                      className="btn btn-primary fa fa-minus"
                      onClick={props.funcionReanudar}
                    />
                  )}
                  <button
                    type="button"
                    className="btn btn-danger fa fa-close outline pill"
                    onClick={closeModalT}
                  />
                </div>
              </div>
              <div className="modal-body">
                <TransportistasForm {...dataT} cerrarModal={closeModalT} />
              </div>
            </div>
          </Modal>
      
              </div>
            </div>

            
				  {/* </div> */}
    </SiteWrapper>
      
    )
}
export default DetalleRemolques;
