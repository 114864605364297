import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from "../../herramientas/Crud_Catalogos";
import TabsLayout from "../../components/TabsLayout.react";
import {
  Plataforma,
  Corredores,
  Otd,
  Navieras,
  ClientesNavieros,
  Operacionestipo,
  Operacionesestatus,
  RutasEstatus,
  Movimientostipo,
  Clientes,
  Transportista,
  Alertas,
  Correo,
  Actualizacion,
  CamposLogisticos,
  Variables,
  Cuentas,
  Chatbot
} from "./Formularios";
/**
 * Terrestres
 */
import {
  MultiDestinos,
  GpsFijo,
  TiposIncidencias,
  TransportistasTerrestres,
  EtaConfiguracion,
} from "./Formularios";
import { from } from "rxjs";
import TarifarioSubasta from "./Formularios/TarifarioSubasta";

class PlataformasForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      currentTap: 0,
      id: "",
      accion: this.props.accion, //'nuevo'// modificar,
      Otd: [],
      ready: false,
    };
    this.tabs = [];

    /**
     * Tab's
     */
    this.Plataforma = {      
      title: this.props.elementos.nombre,
      icon: "layout",
      panel: (
        <Plataforma 
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.OperacionesTipo = {
      title: "Operaciones Tipo",
      icon: "sliders",
      panel: (
        <Operacionestipo
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.OperacionesEstatus = {
      title: "Operaciones Estatus",
      icon: "bar-chart-2",
      panel: (
        <Operacionesestatus
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.RutasEstatus = {
      title: "Rutas Estatus",
      icon: "send",
      panel: (
        <RutasEstatus
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Transportista = {
      title: "Transportista",
      icon: "truck",
      panel: (
        <Transportista
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.elementos}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Correo = {
      title: "Notificaciones",
      icon: "send",
      panel: (
        <Correo
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Alertas = {
      title: "Alertas",
      icon: "truck",
      panel: (
        <Alertas
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.CamposLogisticos = {
      title: "Campos Logisticos",
      icon: "list",
      panel: (
        <CamposLogisticos
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.variables = {
      title: "Variables",
      icon: "list",
      panel: (
        <Variables
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Actualizacion = {
      title: "Actualizaciones Automaticas",
      icon: "rotate-cw",
      panel: (
        <Actualizacion
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.cuentas = {
      title: "Cuentas",
      icon: "user",
      panel: (
        <Cuentas
          accion={this.props.accion}
          plataforma={this.props.elementos}
          stor={this.props.stor}
        />
      ),
      close: false,
    };
    this.chatbot = {
      title: "Chatbots",
      icon: "users",
      panel: (
        <Chatbot
          accion={this.props.accion}
          plataforma={this.props.elementos}
          stor={this.props.stor}
        />
      ),
      close: false,
    };
    /**
     * Navieras
     */
    this.Corredores = {
      title: "Corredores",
      icon: "git-merge",
      panel: (
        <Corredores
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Otd = {
      title: "Otd",
      icon: "alert-triangle",
      panel: (
        <Otd
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Navieras = {
      title: "Navieras",
      icon: "map",
      panel: (
        <Navieras
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.TipoMovimiento = {
      title: "Tipo Movimiento",
      icon: "crop",
      panel: (
        <Movimientostipo
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.Clientes = {
      title: "Clientes",
      icon: "user",
      panel: (
        <Clientes
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          plataforma={this.props.elementos}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.tarifarioSubastas = {
      title: "Tarifario Localidades",
      icon: "user",
      panel: (
        <TarifarioSubasta
          accion={this.props.accion}
          plataforma={this.props.elementos}
          stor={this.props.stor}
        />
      ),
      close: false,
    };
    this.ClientesNavieros = {
      title: "Clientes Solicitante.",
      icon: "user",
      panel: (
        <ClientesNavieros
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    /**
     * Terrestres
     */
    this.MultiDestinos = {
      title: "Destinos Terrestres",
      icon: "map-pin",
      panel: (
        <MultiDestinos
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.gpsFijo = {
      title: "Gps Fijo",
      icon: "crosshair",
      panel: (
        <GpsFijo
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.TiposIncidencias = {
      title: "Incidencias",
      icon: "crosshair",
      panel: (
        <TiposIncidencias
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.TransportistasTerrestres = {
      title: "Transportistas",
      icon: "truck",
      panel: (
        <TransportistasTerrestres
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
    this.EtaConfiguracion = {
      title: "Eta",
      icon: "truck",
      panel: (
        <EtaConfiguracion
          accion={this.props.accion}
          elementos={this.props.elementos}
          getfuntion={this.props.getfuntion}
          stor={this.props.stor}
          tipoPlataforma={
            this.props.elementos.plataformaTipo
              ? this.props.elementos.plataformaTipo.nombre
              : ""
          }
          cerrarModal={this.props.cerrarModal}
        />
      ),
      close: false,
    };
  }
  componentDidMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
    } else {
    }
  };
  componentWillMount = () => {
    if (this.state.accion !== "nuevo") {
      this.setState({ id: this.props.elementos.id });
      switch (this.props.elementos.plataformaTipo.nombre.toUpperCase()) {
        case "NAVIERA":
          {
            this.tabs = [
              this.Plataforma,
              this.Corredores,
              this.TipoMovimiento,
              this.Navieras,
              this.cuentas,              
              this.Otd,
              this.ClientesNavieros,
              this.OperacionesTipo,
              this.OperacionesEstatus,
              this.RutasEstatus,
              this.TiposIncidencias,              
              this.Transportista,
              this.Clientes,
              this.tarifarioSubastas,
              this.Correo,
              this.chatbot,
              this.EtaConfiguracion,
              this.Alertas,
              this.CamposLogisticos,
              this.variables,
              this.Actualizacion,
            ];
          }
          break;
        case "TERRESTRE":
          {
            this.tabs = [
              this.Plataforma,
              this.Corredores,
              this.gpsFijo,
              this.MultiDestinos,
              this.OperacionesTipo,
              this.OperacionesEstatus,
              this.RutasEstatus,
              this.TipoMovimiento,
              this.TransportistasTerrestres,
              this.Correo,
              this.EtaConfiguracion,
              this.TiposIncidencias,
              this.Alertas,
              this.CamposLogisticos,
              this.variables,
              this.Actualizacion,
            ];
          }
          break;
        case "TERRESTRE NAVIERA":
          {
            this.tabs = [
              this.Plataforma,
              this.Corredores,
              this.TipoMovimiento,
              this.Navieras,
              this.cuentas,             
              this.Otd,
              this.OperacionesTipo,
              this.OperacionesEstatus,
              this.RutasEstatus,
              this.TiposIncidencias,
              this.Transportista,
              this.Clientes,
              this.tarifarioSubastas,
              this.Correo,
              this.EtaConfiguracion,
              this.Alertas,
              this.CamposLogisticos,
              this.variables,
              this.Actualizacion,
            ];
          }
          break;
        default:
          break;
      }
    } else {
      this.tabs = [this.Plataforma];
      console.log("PLATAFORMA " + this.Plataforma);
    }
  };
  selectedTab = (index) => {
    this.setState({ currentTap: index });
  };
  render() {
    return (      
      <div>        
        {" "}
        {/**<Transportista obj={ this.state.movimiento.idTransportista }/> */}
        <TabsLayout
          tabs={this.tabs}
          selectedTab={this.state.currentTap}
          selectTab={this.selectedTab}
        />
      </div>
    );
  }
}

export default PlataformasForm;
