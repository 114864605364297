import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Icon } from 'tabler-react';
import { Crud_Catalogos } from '../../../herramientas/Crud_Catalogos';


function Row(props) {
    
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.vertical}
        </TableCell>
        <TableCell align="center">{row.delay}</TableCell>
        <TableCell align="center">{row.ontime}</TableCell>
        <TableCell align="center">{row.totalMov}</TableCell>
        <TableCell align="center">
       
        <div class="background">	
		
			
          { row.porcent >= 93 ?
				  <div id="circulo1" class="color1"></div>
          : row.porcent < 93 && row.porcent > 80 ? 
				  <div id="circulo2" class="color2"></div>
          :
				  <div id="circulo3" class="color3"></div>
       }
      {row.percent} 
		
	</div>	
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                Movimientos por Vertical
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                  
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Booking</TableCell>
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align='center'>WO</TableCell>
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Cita Programada</TableCell>
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Cliente</TableCell>
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Transportista</TableCell>
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">Estatus</TableCell>
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="center">ETA</TableCell>
                    <TableCell sx={{boxShadow: "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset" , backgroundColor:"#3979f94d"}} align="right">Tracking</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.idMovimiento} sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}}>
                      
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">{historyRow.booking}</TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">{historyRow.po}</TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                        {historyRow.citaProgramada}
                      </TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                        {historyRow.cliente}
                      </TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                        {historyRow.idTransportista}
                      </TableCell>
                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                        {historyRow.status}
                      </TableCell>

                      <TableCell sx={{boxShadow: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px"}} align="center">
                        {historyRow.etaCalculadas?.eta}
                      </TableCell>

                      <TableCell>
                      <span
                        className="badge"
                        onClick={() => abrirmodalGps(historyRow)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={'Ver tracking'}
                      >
                        <label style={{ color: 'black', 'font-size': '200%' }}>
                          <Icon name="map-pin" />
                        </label>
                      </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const getfuntion = (
  metodo = 'get',
  obj = [],
  id = '',
  catalogo = '',
  stateVar = '',
  hiddenModl = '',
  enviar
) => {
  return Crud_Catalogos(
    catalogo,
    'movimientos',
    metodo,
    id,
    obj,
    '',
    stateVar,
    hiddenModl,
    []
  )
    .then((returnVal) => {
    

      window.open(
        returnVal.url,
        obj.idGg,
        'width=900,height=600,scrollbars=NO'
      );
    
    })};


const abrirmodalGps = (data) => {

  console.log(data)

  if(data.movimientoGps[0]){
    let idGGps =  data.movimientoGps[0].gps;
    if (data.idPlataformaGG && idGGps) {
      getfuntion(
        'get',
        idGGps,
        '',
        'navieraMovimientos/plataforma/' +
        data.idPlataformaGG +
          '/gps/' +
          idGGps.idGg,
        'urlGps'
      );
    }
  }

   
 
};




export default function CollapsibleTable(props) {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <Paper sx={{ width: '100%' }}>
    <TableContainer sx={{ maxHeight: 440 }}  >
      <Table stickyHeader aria-label="sticky table">

      
        <TableHead  >
          <TableRow>
            
            <TableCell  sx={{ backgroundColor:"#3979f94d",boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"}} align="right" />
            {props.heads.map((head,i) => (
                 i == 0  ? 
            <TableCell  sx={{ backgroundColor:"#3979f94d",boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"}} align="">{head}</TableCell>
            : 
            <TableCell   sx={{ backgroundColor:"#3979f94d",boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset"}}  align="center">{head}</TableCell>
        ))}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((row) => (
            <Row key={row.corredor} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
     <TablePagination
     rowsPerPageOptions={[5, 10, 20]}
     component="div"
     count={props.data.length}
     rowsPerPage={2}
     page={rowsPerPage}
     onPageChange={handleChangePage}
     onRowsPerPageChange={handleChangeRowsPerPage}
   />
    </Paper>
  );
}