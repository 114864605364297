import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Card,
  Table,
  Form,
  Alert,
} from "tabler-react";
import { Crud_Catalogos } from "../../herramientas/Crud_Catalogos";
import { timeToDateFill } from "../../herramientas/DateFormat";
import Select from "react-select";
import {
  NotificationManager,
} from "react-notifications";
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
const estilo = {
  color: "red",
  size: 100,
  "border-width": "5px",
  padding: "0px",
  "margin-top": "1.0em",
};
let gpsAgregadoOld= [];
let gpsAgregadosFullOld= [];
let guardando= false;
const steps = ["Guardando unidad, remolque, operador", "Guardando gps's", "Comprobando unidad, remolque, operador", "Comprobando gps's"];
const DatosMovimientoGpsForm =({ movimientos, movimiento, idMovimiento, usuario, plataformaid, refetch})=> {  
  const [saveProgress, setSaveProgress]= useState({activeStep: null, isStepFailed: null, stepFailedMessage: ""});
  const [saveProgressFull, setSaveProgressFull]= useState({activeStep: null, isStepFailed: null, stepFailedMessage: ""});
  const [datosGps, setDatosGps]= useState({});
  const [disabledButon, setDisabledButon]= useState(false);
  const [esFull, setEsFull]= useState(false);
  //idRemolqueFull: { value: 0, label: "Seleccione una Opción" },  
  const [movimientosPatios, setMovimientosPatios]= useState(
    {
      idMovimiento: null,
      economicor: "",//*
      economicot: "",//*
      idRemolque: { idRemolque: 0 },
      operadorNombre: "",
      operadorContacto: "",
      operadorLicencia: "",
      tractorModelo: "",
      tractorMarca: "",
      tractorColor: "",
      enProtocolo: true,
      tractorPlaca: "",
      esFull: "",
      remolquePlaca: "",//*
      colocacionFecha: null,
      observaciones: "",
      idLocalidad: null,
      idUsuario: usuario,
    }
  );
  //MOVIMIENTO PRINCIPAL
  const [movimientoPrimario, setMovimientoPrimario]= useState({ });//po1
  const [movimientoFull, setMovimientoFull]= useState({ });//po1
  const [gpsAgregado, setGpsAgregado]= useState([]);
  const [gpsList, setGpsList]= useState([]);
  const [gps, setGps]= useState({ value: 0, label: "Seleccione un GPS", data: null });//idGps
  const [tipoColocacion, setTipoColocacion]= useState({ value: 0, label: "Seleccione una opción", data_: null });//tipoGps
  const [remolques, setRemolques]= useState([]);
  const [idRemolque, setIdRemolque]= useState({ value: 0, label: "Seleccione una Opción" });//idRemolque
  const [controlador, setControlador]= useState({
    value: usuario.id,
    label:
      usuario.nombre +
      " " +
      usuario.aPaterno +
      " " +
      usuario.aMaterno,
      data: usuario
  });//usuario
  
  
  //MOVIMIENTO SECUNDARIO
  const [movimientoSecundario, setMovimientoSecundario]= useState({ value: 0, label: "Seleccione un Movimiento", data: null});//po2, idMovFull
  const [gpsAgregadosFull, setGpsAgregadosFull]= useState([]);//gpsAgregadosFull
  const [gpsFull, setGpsFull]= useState({ value: 0, label: "Seleccione un GPS", data: null });//idGpsFull
  const [tipoColocacionFull, setTipoColocacionFull]= useState({ value: 0, label: "Seleccione una opción", data_: null });//tipoGpsFull

  
  const [movimientosSinGps, setMovimientosSinGps]= useState([]);//movimientosSinGps
  const [gpsGateEtiquetas, setGpsGateEtiquetas]= useState([]);//gpsGateEtiquetas null
  const [updateMSG, setUpdateMSG]= useState(false);//updateMSG
  const [gpstipocolocaciones, setGpstipocolocaciones]= useState([]);//gpstipocolocacion
  const [usuarios, setUsuarios]= useState([
    { value: 0, label: "Seleccione una opción" },
    {
      value: usuario.id,
      label:
        usuario.nombre +
        " " +
        usuario.aPaterno +
        " " +
        usuario.aMaterno,
      data: usuario
    },
  ]);//usuarios
  
  
  const [movimientosPatiosFull, setMovimientosPatiosFull]= useState(
    {
      idMovimiento: null,//verificar
      economicor: "",//*
      economicot: "",
      idRemolque: { idRemolque: 0 },
      operadorNombre: "",
      operadorContacto: "",
      operadorLicencia: "",
      tractorModelo: "",
      tractorMarca: "",
      tractorColor: "",
      enProtocolo: true,
      tractorPlaca: "",
      esFull: "",
      remolquePlaca: "",//*
      colocacionFecha: null,
      observaciones: "",//*
      idLocalidad: null,
      idUsuario: usuario,
    }
  );
  useEffect(()=>{
    gpsAgregadoOld= [];
    gpsAgregadosFullOld= [];
    guardando= false;
    let movSinGpsTem = [];
    for( let m of movimientos ){
      if (idMovimiento !== m.idMovimiento
        && movimiento.movimientoTipo === m.movimientoTipo
        && movimiento.corredor === m.corredor
        && (!movimiento.esFull || !movimiento.esFull.length) 
        && movSinGpsTem.filter(msg=> Number(msg.value) === m.idMovimiento).length === 0
        ) {
        movSinGpsTem.push({
          value: m.idMovimiento,
          label: m.idMovimiento + " - " + m.po + " - " + m.contenedor,
          data: m,
        });
      }
    }
    setMovimientosSinGps(movSinGpsTem);
    // primario obtener movimiento 
    getfuntion( "get", [], "", "navieraMovimientos/" + movimiento.idMovimiento, "movimiento" );
    /**
     * Catalogos
     */
    getfuntion(
      "get",
      [],
      "",
      "gpsGateEtiquetas/"+plataformaid+"/PLATAFORMA/" + plataformaid,
      "gpsGateEtiquetas"
    );
    getfuntion("get", [], "", "remolquesTipo", "remolquesTipo"); //Gps gpstipocolocacion 
    getfuntion("get", [], "", "gpsTipoColocacion", "gpstipocolocacion"); //Gps gpstipocolocacion    
    getfuntion("get", [], "", "gps/idestatus/1", "gpsDisponible"); //Gps Disponibles      
     // full obtener movmiento, patio, gps  
  },[]);

  useEffect(()=>{
    if (movimientoPrimario.id) {
      if (!movimientoPrimario.idPlataforma.zonaHoraria)
        movimientoPrimario.idPlataforma.zonaHoraria = "";
      // primario obtener patio
      if(!movimientosPatios.id){
        getfuntion("get", [], "", "movimientosPatio/idMovimiento/" + idMovimiento, "movimientosPatio" );
      }
      if(!guardando){
        cargaGps(movimientoPrimario);
      }
    }
  },[movimientoPrimario]);

  const cargaGps= async( mov= {})=>{
    // primario obtener gps
    if(!mov.id)
      return;
    let gpsAgregadoTemp= [];
    for(let mg of mov.movimientoGps){
      let datosGpsTemp= {};
      try{
        await getfuntion( "get", [], "", "gpsestatus/plataforma/"+mov.idPlataforma.idGg+"/gps/" + mg.gps.id, "datosGps" ).then((resp) => {
          datosGpsTemp= resp;
        });
      }catch(err){
        console.log("no se ha recuperado el estatus del gps "+mg.gps.nombre);
      }
      
      
      gpsAgregadoTemp.push({
        id: mg.gps.id,
        nombre: mg.gps.nombre,
        tipoid: mg.gpsTipo,
        tipo: mg.gpsTipo.nombre,
        direccion: datosGpsTemp.direccion? datosGpsTemp.direccion: "",
        nivelbateria: datosGpsTemp.nivel_bateria? datosGpsTemp.nivel_bateria: "",
        ultimo_reporte: datosGpsTemp.ultimo_reporte? datosGpsTemp.ultimo_reporte: "",
        gps: mg.gps,
        mov: mov,
      });
    }
    gpsAgregadoOld= gpsAgregadoTemp;
    setGpsAgregado(gpsAgregadoTemp);
    if(guardando){
      setSaveProgress({ ...saveProgress, activeStep: 4  });
      if(!Number(movimientosPatios.esFull)){
        //refrescar();
        NotificationManager.success("Guardado", "Guardado" );
        guardando = false;// disabledButon es false, se inicia evento guardar
        setDisabledButon(false);
      }
    }
  }

  useEffect(()=>{
    if (movimientoFull.id) {
      if (!movimientoFull.idPlataforma.zonaHoraria)
      movimientoFull.idPlataforma.zonaHoraria = "";      
      // secundario obtener patio    
      //getfuntion("get", [], "", "movimientosPatio/idMovimiento/" + movimientoFull.id, "movimientosPatioFull" ); 
      
      //if(!movimientosPatiosFull.id){alert("es nuevo")
        getfuntion("get", [], "", "movimientosPatio/idMovimiento/" + movimientoFull.id, "movimientosPatioFull" );
        const mptFullTemp= movimientosSinGps.filter(ms=> ms.value === movimientoFull.id);
        setMovimientoSecundario(mptFullTemp[0]? mptFullTemp[0]: { value: 0, label: "Seleccione un Movimiento", data: null});
      //}
      if(!guardando){
        cargaGpsFull(movimientoFull);
      }
    }
  },[movimientoFull]);

  const cargaGpsFull= async( mov= {})=>{
    // primario obtener gps
    if(!mov.id)
      return;
    let gpsAgregadoTemp= [];
    for(let mg of mov.movimientoGps){
      let datosGpsTemp= {};
      try{
        await getfuntion( "get", [], "", "gpsestatus/plataforma/"+mov.idPlataforma.idGg+"/gps/" + mg.gps.id, "datosGps" ).then((resp) => {
          datosGpsTemp= resp;
        });
      }catch(err){
        console.log("no se ha recuperado el estatus del gps "+mg.gps.nombre);
      }
      
      gpsAgregadoTemp.push({
        id: mg.gps.id,
        nombre: mg.gps.nombre,
        tipoid: mg.gpsTipo,
        tipo: mg.gpsTipo.nombre,
        direccion: datosGpsTemp.direccion? datosGpsTemp.direccion: "",
        nivelbateria: datosGpsTemp.nivel_bateria? datosGpsTemp.nivel_bateria: "",
        ultimo_reporte: datosGpsTemp.ultimo_reporte? datosGpsTemp.ultimo_reporte: "",
        gps: mg.gps,
        mov: mov,
      });
    }
    gpsAgregadosFullOld= gpsAgregadoTemp;
    setGpsAgregadosFull(gpsAgregadoTemp);
    if(guardando){
      await new Promise(r => setTimeout(r, 5000));
      setSaveProgressFull({ ...saveProgress, activeStep: 4  });
      //refrescar();
      NotificationManager.success("Guardado", "Guardado" );
      guardando = false;// disabledButon es false, se inicia evento guardar
      setDisabledButon(false);
    }
  }
  
  useEffect(()=>{
    if(movimientosPatios.id && guardando){
      cargaGps(movimientoPrimario);
    }
    if( movimientosPatiosFull.idMovimiento){
      setMovimientosPatiosFull(
        {...movimientosPatiosFull,
          economicot: movimientosPatios.economicot,
          idRemolque: movimientosPatios.idRemolque,
          operadorNombre: movimientosPatios.operadorNombre,
          operadorContacto: movimientosPatios.operadorContacto,
          operadorLicencia: movimientosPatios.operadorLicencia,
          tractorModelo: movimientosPatios.tractorModelo,
          tractorMarca: movimientosPatios.tractorMarca,
          tractorColor: movimientosPatios.tractorColor,
          enProtocolo: movimientosPatios.enProtocolo,
          tractorPlaca: movimientosPatios.tractorPlaca,
          colocacionFecha: movimientosPatios.colocacionFecha,
          idLocalidad: movimientosPatios.idLocalidad,
          idUsuario: movimientosPatios.idUsuario,
        });
    }
  },[movimientosPatios]);

  useEffect(()=>{
    if(movimientosPatiosFull.id && guardando){
      cargaGpsFull(movimientoFull);
    }
  },[movimientosPatiosFull]);

  const buscarFull= async (id)=>{
    await getfuntion( "get", [], "", "navieraMovimientos/" +  id, "movimientoFull" );
    if(guardando){
      setSaveProgressFull({ ...saveProgress, activeStep: 2  });
      await new Promise(r => setTimeout(r, 2000));
    }  
  }
  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "movimientos",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    ).then((returnVal) => {
      switch (metodo) {
        case "post":
          {
            if (stateVar === "movimientosPatios") {
            }
            if (stateVar === "movimientoGp") {
              return true;
            }
            if (stateVar === "asignar_gps") {
              return true;
            }
          }
          break;
        case "get":
          {
            if (stateVar === "remolquesTipo") {
              let remolquesTipoTem = [{ value: 0, label: "Seleccione una opción" }, ];
              for( let r of returnVal ){
                remolquesTipoTem.push({ value: r.idRemolque, label: r.nombre, data: r });
              }
              setRemolques(remolquesTipoTem);
            }
            if (stateVar === "gpsGateEtiquetas") {
              setGpsGateEtiquetas(returnVal);
              setUpdateMSG(true);//Valida Etiquetas(De no estar la  equieteta no se ra posible configurar)
            }
            if (stateVar === "gpstipocolocacion") {
              let gpstipocolocacionTem = [{ value: 0, label: "Seleccione una opción" }, ];
              for( let gtc of returnVal ){
                gpstipocolocacionTem.push({ value: gtc.id, label: gtc.nombre, data: gtc });
              }
              setGpstipocolocaciones(gpstipocolocacionTem);
            }
            if (stateVar === "usuarios") {
              let usuariosTem = [{ value: 0, label: "Seleccione una opción" }];
              returnVal.map((u, index) =>
                usuariosTem.push({
                  value: u.id,
                  label: u.nombre + " " + u.aPaterno + " " + u.aMaterno,
                  data: u,
                })
              );
              setUsuarios(usuariosTem);
            }
            if (stateVar === "movimiento") {
              setMovimientoPrimario(returnVal);
              return returnVal;
            }
            if (stateVar === "gpsestatus") {
              return returnVal;
            }
            if (stateVar === "movimientoFull") {              
              setMovimientoFull(returnVal);
              return returnVal;
            }
            if (stateVar === "movimientosPatio") {
              let patioTemp= {...returnVal};
              if( patioTemp.id ){
                if(guardando){
                  setSaveProgress({ ...saveProgress, activeStep: 3  });
                }
                setMovimientosPatios({...returnVal });
                setIdRemolque( { value: returnVal.idRemolque.idRemolque, label: returnVal.idRemolque.nombre, data: returnVal.idRemolque } );                
                if(Number(patioTemp.esFull)){
                  setEsFull(true);
                  buscarFull(Number(patioTemp.esFull));
                }
              }else{
                setMovimientosPatios({...movimientosPatios, idLocalidad: movimientoPrimario.idLocalidad, idMovimiento: idMovimiento });
                setMovimientosPatiosFull({...movimientosPatiosFull, idLocalidad: movimientoPrimario.idLocalidad });
              }
              return returnVal;
            }
            if (stateVar === "movimientosPatioFull") {
              if( returnVal.id ){
                if(guardando){
                  setSaveProgressFull({ ...saveProgress, activeStep: 3  });
                }
                setMovimientosPatiosFull({...returnVal });
              }
              return returnVal;
            }
            if (stateVar === "gpsDisponible") {
              let gpsListTemp= [{ value: 0, label: "Seleccione un GPS", data: null }];
              returnVal.map((g, index) => {
                gpsListTemp.push({ value: g.id, label: g.nombre, data: g, });
              });
              setGpsList(gpsListTemp);
              //Gps Instalado por ahora solo se mostrarn los gps marcados como disponibles
              //getfuntion("get", [], "", "gps/idestatus/2", "gpsAsignado"); //Gps Instalado
              //getfuntion("get", [], "", "gpsMobile/idGpsEstatus/2", "gpsAsignado"); //Gps Disponibles
            }
            if (stateVar === "gpsAsignado") {
              let gpsListTemp = [...gpsList];
              returnVal.map((g, index) => {
                gpsListTemp.push({ value: g.id, label: g.nombre, data: g, });
              });
              setGpsList(gpsListTemp);
            }
            if (stateVar === "datosGps") {//revisar
              setDatosGps(returnVal);
              return returnVal;
            }
          }
          break;
        default:
          break;
      }
    });
  };

  const reset=()=>{
    setMovimientosPatiosFull(
      {
        idMovimiento: null,//verificar
        economicor: "",//*
        economicot: "",
        idRemolque: { idRemolque: 0 },
        operadorNombre: "",
        operadorContacto: "",
        operadorLicencia: "",
        tractorModelo: "",
        tractorMarca: "",
        tractorColor: "",
        enProtocolo: true,
        tractorPlaca: "",
        esFull: "",
        remolquePlaca: "",//*
        colocacionFecha: null,
        observaciones: "",//*
        idLocalidad: null,
        idUsuario: usuario,
      }
    );
    setMovimientosPatios(
      {
        idMovimiento: null,
        economicor: "",//*
        economicot: "",//*
        idRemolque: { idRemolque: 0 },
        operadorNombre: "",
        operadorContacto: "",
        operadorLicencia: "",
        tractorModelo: "",
        tractorMarca: "",
        tractorColor: "",
        enProtocolo: true,
        tractorPlaca: "",
        esFull: "",
        remolquePlaca: "",//*
        colocacionFecha: null,
        observaciones: "",
        idLocalidad: null,
        idUsuario: usuario,
      }
    );

    setMovimientoPrimario({});
    setMovimientoFull({});


    gpsAgregadosFullOld= [];
    setGpsAgregadosFull(gpsAgregadosFullOld);

    gpsAgregadoOld= [];
    setGpsAgregado(gpsAgregadoOld);
    setEsFull(false);
  }
  const recuperarGPS = (gpsAgregadoRec) => {
    const recGps = { gps: gpsAgregadoRec.id, plataforma: gpsAgregadoRec.mov.idPlataforma.id, estatus: 1, idMovimiento: gpsAgregadoRec.mov.id };
    getfuntion("post", recGps, "", "gps/remover_activo", "removerActivo").then(
      (gpsEstatus) => {}
    );
  };
  const agregarGPS = async () => {//Se manda arroba morada con nuevos requerimientos del usuario

    NotificationManager.info("Guardando datos ... ", "Guardar");    
    setSaveProgress({ ...saveProgress, activeStep: 0 });
    
    if (guardando) {//ya se ha dado un click
      return true;
    }
    guardando = true;// disabledButon es false, se inicia evento guardar
    setDisabledButon(true);

    if ( !movimientosPatios.idRemolque["idRemolque"] ) {
      setSaveProgress({ ...saveProgress, isStepFailed: 0, stepFailedMessage: "Tipo de plataforma requerido"  });
      NotificationManager.warning(
        "Tipo de plataforma requerido ..!",
        "Atención"
      );
      guardando = false;// disabledButon es false, se inicia evento guardar
      setDisabledButon(false);
      return;
    }

    //verifica si hay movmiento full
    if (  esFull && !Number(movimientosPatiosFull.esFull) ) {
        setSaveProgress({ ...saveProgress, isStepFailed: 0, stepFailedMessage: "Se ha detectado que quiere hacer full con: " + movimientoSecundario.label + " (pero, no ha completado los datos)"  });
      NotificationManager.warning(
        "Se ha detectado que quiere hacer full con: " + movimientoSecundario.label + " (pero, no ha completado los datos)",
        "Guardar"
      );
      guardando = false;// disabledButon es false, se inicia evento guardar
      setDisabledButon(false);
      return;
    }

    //guardando movimiento patio
    /*
    if ( movimientoSecundario.value ) {
      movimientosPatios.esFull = movimientoSecundario.value;

      movimientosPatiosFull.idMovimiento = movimientoSecundario.value;
      movimientosPatiosFull.esFull = idMovimiento;
    } else {
      movimientosPatios["esFull"] = null;
    }*/

    await getfuntion(
      (movimientosPatios.id? "put": "post"),
      movimientosPatios,
      "",
      "movimientosPatio",
      "movimientosPatios"
    ).catch((err) => {
      setSaveProgress({ ...saveProgress, isStepFailed: 0, stepFailedMessage: "Error al guardar datos del tracto y del operador"  });
      NotificationManager.error(
        "Error al guardar datos del tracto y del operador",
        "Error"
      );
      guardando = false;// disabledButon es false, se inicia evento guardar
      setDisabledButon(false);
      return;
    });

    /**
       * Mov Full
       */
    setSaveProgressFull({ ...saveProgress, activeStep: 0 });
    if(movimientoSecundario.value){
      await getfuntion(
        (movimientosPatiosFull.id? "put": "post"),
        movimientosPatiosFull,
        "",
        "movimientosPatio",
        "movimientosPatiosFull"
      ).catch((err) => {
        NotificationManager.error(
          "Error al guardar datos del tracto y del operador",
          "Error"
        );
        guardando = false;// disabledButon es false, se inicia evento guardar
        setDisabledButon(false);
        return;
      });
    }
    setSaveProgress({ ...saveProgress, activeStep: 1  });
    //Guardando movmiento gps's
    if ( !gpsAgregado.length ) {
      NotificationManager.warning("No se han agregado GPS'S", "Atención");
    }
    //Borrar gps
    for( let viejo of gpsAgregadoOld ) {
      let eliminar = true;
      for (let nuevo of gpsAgregado) {
        if (viejo.id === nuevo.id) 
          eliminar = false;
      }
      if (eliminar) {//Se borrara gps
        recuperarGPS(viejo);
      }
    }
    for (let nuevo of gpsAgregado) {
      let actualizar = false;
      let datoViejo = false;
      for (let viejo of gpsAgregadoOld) {
        if (viejo.id === nuevo.id) {
          datoViejo = true;
          if (viejo.tipoid !== nuevo.tipoid) {
            actualizar = true;
          }
        }
      }
      let movimientosGps = {
        idMovimiento: idMovimiento,
        gps: nuevo.gps,
        gpsTipo: nuevo.tipoid,
        espejo: nuevo.gps.gpsEstatus.id === 1 ? false : true,
      };
      if ( actualizar || !datoViejo ) {
        //Radio operado, sin gps, aqui se vinculan a l mov( No cambia el estatus del gps)(No recibiran alertas, actualizacion automatica, )
        await getfuntion( "post", movimientosGps, "", "movimientoGps", "movimientoGp" ).then((movGps) => {
            NotificationManager.info(
              "Gps: " + nuevo.nombre + " agregado",
              "Guardado"
            );
          })
          .catch((err) => {
            NotificationManager.error(
              "Hubo un error al vincular el gps al movimiento",
              "Error"
            );
        });
        const usuariosLogObject = {
          idBitacoraMonitoreo: 5,
          idUsuario: usuario.id,
          idMovimiento: idMovimiento,
          accionFecha: timeToDateFill( new Date().getTime()),
          descripcion: "Asignacion de GPS: " + nuevo.nombre + "(" + nuevo.id + ")",
          idPlataformaTipo: 1,
        };
        await Crud_Catalogos(
          "usuariosLog",
          "nada",
          "post",
          "",
          usuariosLogObject
        );
      }
      //etiquetado gate
      if ((actualizar || !datoViejo) && nuevo.id > 20) {
        const gpsGG = {
          gps: nuevo.id,
          plataforma: plataformaid,
          estatus: 2,
          idMovimiento: idMovimiento,
        };
        await getfuntion(
          "post",
          gpsGG,
          "",
          "gps/asignar_gps",
          "asignar_gps"
        ).then((respuesta) => {
          //Si se tiene la función de refetch se realiza para volver a cargar la petición principal
          if (refetch) {
            refetch();
          }
        })
        .catch((err) => {
        });
      }
      
    }
    /**
     * Mov Full
     */
    setSaveProgressFull({ ...saveProgress, activeStep: 1  });
    if(movimientoSecundario.value){
      if ( !gpsAgregado.length || !gpsAgregadosFull.length ) {
        NotificationManager.warning("No se han agregado GPS'S", "Atención");
      }
      for( let viejo of gpsAgregadosFullOld ){
        let eliminar = true;
        for (let nuevo of gpsAgregadosFull) {
          if (viejo.id === nuevo.id) {
            eliminar = false;
          }
        }
        if (eliminar) {//Se borrara gps
          recuperarGPS(viejo);
        }
      }
      for (let nuevo of gpsAgregadosFull) {
        let actualizar = false;
        let datoViejo = false;
        for (let viejo of gpsAgregadosFullOld) {
          if (viejo.id === nuevo.id) {
            datoViejo = true;
            if (viejo.tipoid !== nuevo.tipoid) {
              actualizar = true;
            }
          }
        }
        let movimientosGpsFull = {
          idMovimiento: movimientoSecundario.value,
          gps: nuevo.gps,
          gpsTipo: nuevo.tipoid,
          espejo: nuevo.gps.gpsEstatus.id === 1 ? false : true,
        };
        //Radio operado, sin gps, aqui se vinculan a l mov( No cambia el estatus del gps)(No recibiran alertas, actualizacion automatica, )
        await getfuntion(
          "post",
          movimientosGpsFull,
          "",
          "movimientoGps",
          "movimientoGp"
        ).then((movGps) => {
            NotificationManager.info(
              "Gps: " + nuevo.nombre + " agregado",
              "Guardado"
            );
        })
        .catch((err) => {
          NotificationManager.error(
            "Hubo un error al vincular el gps al movimiento",
            "Error"
          );
        });
        const usuariosLogObject = {
          idBitacoraMonitoreo: 5,
          idUsuario: usuario.id,
          idMovimiento: movimientoSecundario.value,
          accionFecha: timeToDateFill( new Date().getTime()),
          descripcion: "Asignacion de GPS: " + nuevo.nombre + "(" + nuevo.id + ")",
          idPlataformaTipo: 1,
        };
        await Crud_Catalogos(
          "usuariosLog",
          "nada",
          "post",
          "",
          usuariosLogObject
        );

        if ((actualizar || !datoViejo) && nuevo.id > 20) {
          const gpsGG = {
            gps: nuevo.id,
            plataforma: plataformaid,
            estatus: 2,
            idMovimiento: movimientoSecundario.value,
          };
          /** Asignar Gps en GG */
          await getfuntion("post", gpsGG, "", "gps/asignar_gps").then((respuestaGG) => {
            //Si se tiene la función de refetch se realiza para volver a cargar la petición principal
            if (refetch) {
              refetch();
            }
          })
          .catch((err) => {
          });
        }
      }
    }
    if( movimientoPrimario.id && [4,1381,6,754,61].includes(movimientoPrimario.idPlataforma.id) && gpsAgregado.length &&
      (  !movimientosPatios.operadorNombre || !movimientosPatios.operadorNombre.length
      || !movimientosPatios.operadorContacto || !movimientosPatios.operadorContacto.length
      || !movimientosPatios.tractorMarca || !movimientosPatios.tractorMarca.length
      || !movimientosPatios.tractorColor || !movimientosPatios.tractorColor.length
      || !movimientosPatios.tractorModelo || !movimientosPatios.tractorModelo.length
      || !movimientosPatios.tractorPlaca || !movimientosPatios.tractorPlaca.length
      || !movimientosPatios.remolquePlaca || !movimientosPatios.remolquePlaca.length
      || !movimientosPatios.economicot || !movimientosPatios.economicot.length)
      ){
        setSaveProgress({ ...saveProgress, isStepFailed: 0, stepFailedMessage: "no se captura datos de operador"  });//activeStep: null, isStepFailed: null
        await getfuntion("post",{}, "", "mail/arrobanaviera/"+movimientosPatios.idMovimiento+"/12", "sendMail");
    }
    if(movimientoSecundario.value && [4,1381,6,754,61].includes(movimientoFull.idPlataforma.id) && gpsAgregadosFull.length &&
      (    !movimientosPatiosFull.operadorNombre || !movimientosPatiosFull.operadorNombre.length
      || !movimientosPatiosFull.operadorContacto || !movimientosPatiosFull.operadorContacto.length
      || !movimientosPatiosFull.tractorMarca || !movimientosPatiosFull.tractorMarca.length
      || !movimientosPatiosFull.tractorColor || !movimientosPatiosFull.tractorColor.length
      || !movimientosPatiosFull.tractorModelo || !movimientosPatiosFull.tractorModelo.length
      || !movimientosPatiosFull.tractorPlaca || !movimientosPatiosFull.tractorPlaca.length
      || !movimientosPatiosFull.remolquePlaca || !movimientosPatiosFull.remolquePlaca.length
      || !movimientosPatiosFull.economicot || !movimientosPatiosFull.economicot.length)
      ){
        setSaveProgress({ ...saveProgress, isStepFailed: 0, stepFailedMessage: "no se captura datos de operador"  });//activeStep: null, isStepFailed: null
        await getfuntion("post",{}, "", "mail/arrobanaviera/"+movimientosPatiosFull.idMovimiento+"/12", "sendMail");
    }
    reset();
    await new Promise(r => setTimeout(r, 2000));
    setSaveProgress({ ...saveProgress, activeStep: 2  });    
    await getfuntion( "get", [], "", "navieraMovimientos/" + movimiento.idMovimiento, "movimiento" );

    
  }
  return (
    <div>
      <div className="row">
        <div className="col">
          <div>
            <div class="row">
              <div className="col col-12 col-sm-12 col-md-12 ">
                {updateMSG ? (
                  <div hidden={gpsGateEtiquetas.id}>
                    <Alert type="danger" icon="alert-octagon">
                      {" "}
                      Pide a sistemas que configure la etiqueta plataforma en
                      gpsgate, para poder asignar gps
                    </Alert>
                  </div>
                ) : null}
              </div>
              <div class="col-12">
                <div class="row">                  
                  <div className="col col-12 col-sm-12 col-md-4 ">
                    <Form.Group label="Usuario que coloca(Para un usuario diferente ingrese nombre)">
                      <Select
                        value={controlador}
                        onChange={(e)=>{
                          setControlador(e);
                          setMovimientosPatios({...movimientosPatios, idUsuario: e.data });
                        }}
                        options={usuarios}
                        onInputChange={(e)=>{
                          if (e.length > 1) {
                            getfuntion(
                              "get",
                              "",
                              "",
                              "personas/nombre_o_apellido_y_perfil?nombre=" +
                                encodeURIComponent(e) +
                                "&perfil=222",
                              "usuarios"
                            );
                          }
                        }}
                      />{" "}
                    </Form.Group>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-4 ">
                    <Form.Group label="En protocolo">
                      <Form.Switch
                        name="enProtocolo"
                        type="Checkbox"
                        label={
                          movimientosPatios.enProtocolo
                            ? "Dentro de protocolo"
                            : "Fuera de protocolo"
                        }
                        onChange={(e)=> setMovimientosPatios({...movimientosPatios, enProtocolo: e.target.checked }) }
                        checked={movimientosPatios.enProtocolo}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-sm-12 col-md-12 ">
                {" "}
                {/*Datos de tractor */}
                <h3>TRACTO</h3>
                <div class="row">
                  <div className="col col-12 col-sm-12 col-md-3 ">
                    <Form.Group label="Modelo Tractor">
                      {" "}
                      <Form.Input
                        name="modeloTractor"
                        type="text"
                        value={movimientosPatios.tractorModelo}
                        onChange={(e)=> setMovimientosPatios({...movimientosPatios, tractorModelo: e.target.value }) }
                      />{" "}
                    </Form.Group>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-3 ">
                    <Form.Group label="Marca Tractor">
                      {" "}
                      <Form.Input
                        name="marcaTractor"
                        type="text"
                        value={movimientosPatios.tractorMarca}
                        onChange={(e)=> setMovimientosPatios({...movimientosPatios, tractorMarca: e.target.value }) }
                      />{" "}
                    </Form.Group>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-3 ">
                    <Form.Group label="Color Tractor">
                      {" "}
                      <Form.Input
                        name="colorTractor"
                        type="text"
                        value={movimientosPatios.tractorColor}
                        onChange={(e)=> setMovimientosPatios({...movimientosPatios, tractorColor: e.target.value }) }
                      />{" "}
                    </Form.Group>                    
                  </div>                  
                  <div className="col col-12 col-sm-12 col-md-3 ">
                    <Form.Group label="Placa Tractor">
                      {" "}
                      <Form.Input
                        name="placaTractor"
                        type="text"
                        value={movimientosPatios.tractorPlaca}
                        onChange={(e)=> setMovimientosPatios({...movimientosPatios, tractorPlaca: e.target.value }) }
                      />{" "}
                    </Form.Group>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-3 ">
                    <Form.Group label="Tipo Plataforma(requerido)">
                      {" "}
                      <Select
                        value={ idRemolque }
                        onChange={(e)=> {
                          setIdRemolque(e);
                          setMovimientosPatios({...movimientosPatios, idRemolque: e.data });
                        }}
                        options={ remolques }
                      />{" "}
                    </Form.Group>
                  </div>
                  <div className="col col-12 col-sm-12 col-md-3 ">
                    <Form.Group label="Economico(Tracto)">
                      {" "}
                      <Form.Input
                        name="economicot"
                        type="text"
                        value={movimientosPatios.economicot}
                        onChange={(e)=> setMovimientosPatios({...movimientosPatios, economicot: e.target.value }) }
                      />{" "}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </div>

            <hr style={estilo} />
            <h3>OPERADOR</h3>

            <div class="row">
              {" "}
              {/* Datos del operador */}
              <div className="col col-12 col-sm-12 col-md-4 ">
                <Form.Group label="Nombre Operador">
                  {" "}
                  <Form.Input
                    name="nombreOperador"
                    type="text"
                    value={movimientosPatios.operadorNombre}
                    onChange={(e)=> setMovimientosPatios({...movimientosPatios, operadorNombre: e.target.value }) }
                  />{" "}
                </Form.Group>
              </div>
              <div className="col col-12 col-sm-12 col-md-4 ">
                <Form.Group label="Medio Contacto">
                  {" "}
                  <Form.Input
                    name="medioContacto"
                    type="text"
                    value={movimientosPatios.operadorContacto}
                    onChange={(e)=> setMovimientosPatios({...movimientosPatios, operadorContacto: e.target.value }) }
                  />{" "}
                </Form.Group>
              </div>
              <div className="col col-12 col-sm-12 col-md-4 ">
                <Form.Group label="Licencia">
                  {" "}
                  <Form.Input
                    name="licencia"
                    type="text"
                    value={movimientosPatios.operadorLicencia}
                    onChange={(e)=> setMovimientosPatios({...movimientosPatios, operadorLicencia: e.target.value }) }
                  />{" "}
                </Form.Group>
              </div>
            </div>

            <hr style={estilo} />

            <div class="row">
              {/** Asignacion de gps */}
              <Card title={<h3>GPS(requerido)</h3>} isCollapsible>
                <Card.Body>
                  <div class="row">
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Placa Remolque">
                        {" "}
                        <Form.Input
                          name="placaRemolque"
                          type="text"
                          value={movimientosPatios.remolquePlaca}
                          onChange={(e)=> setMovimientosPatios({...movimientosPatios, remolquePlaca: e.target.value }) }
                        />{" "}
                      </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Economico (remolque)">
                        {" "}
                        <Form.Input
                          name="economicor"
                          type="text"
                          value={movimientosPatios.economicor}
                          onChange={(e)=> setMovimientosPatios({...movimientosPatios, economicor: e.target.value }) }
                        />{" "}
                      </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-4 ">
                      <Form.Group label="Obseraciones">
                        {" "}
                        <Form.Textarea
                          name="observaciones"
                          onChange={(e)=> setMovimientosPatios({...movimientosPatios, observaciones: e.target.value }) }
                        >{movimientosPatios.observaciones}</Form.Textarea>{" "}
                      </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="ID MOVIMIENTO - PO-CONTENEDOR">
                        {" "}
                        <Form.Input
                          name="movRoot"
                          type="text"
                          value={movimiento.idMovimiento+" - "+movimiento.po+ " - "+ movimiento.contenedor}
                        />{" "}
                      </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="NOMBRE GPS">
                        {" "}
                        <Select
                          value={gps}
                          onChange={(e)=>{
                            setDatosGps({});
                            getfuntion(
                              "get",
                              [],
                              "",
                              "gpsestatus/plataforma/"+movimientoPrimario.idPlataforma.idGg+"/gps/" + e.value,
                              "datosGps"
                            );
                            setGps(e);
                          }}
                          options={gpsList.filter( g=> gpsAgregado.filter( ga => ga.id === g.value).length === 0 || gpsAgregadosFull.filter( ga => ga.id === g.value).length === 0 )}
                        />{" "}
                      </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Form.Group label="TIPO GPS">
                        <Select
                          value={tipoColocacion}
                          onChange={(e)=> setTipoColocacion(e) }
                          options={gpstipocolocaciones}
                        />{" "}
                      </Form.Group>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-3 ">
                      <Button
                        onClick={() => {
                          if ( !gps.data.gpsHardware.id === 0 ) {
                            NotificationManager.error(
                              `No se puede asignar el GPS ${gps.data.nombre}, ya que no cuenta con la información del hardaware, contacte a la persona responsable.`,
                              "Error"
                            );
                            return;
                          }
                          
                          if (
                            gpsAgregado.find((objectGPS) => objectGPS.tipoid.id === tipoColocacion.value)) {
                            NotificationManager.error(
                              `Ya se ha agregado un GPS de tipo ${tipoColocacion.label} al movimiento`,
                              "Error"
                            );
                            return;
                          }

                          if (gpsAgregadosFull.find( (objectGPS) => objectGPS.id === gps.value)) {
                            NotificationManager.error(
                              `No se puede agregar el GPS ${gps.data.nombre}, ya se encuentra asociado a otro movimiento.`,
                              "Error"
                            );
                            return;
                          }
                          let gpsAgregadoTemp= [...gpsAgregado];
                          gpsAgregadoTemp.push({
                            id: gps.value,
                            nombre: gps.label,
                            tipoid: tipoColocacion.data,
                            tipo: tipoColocacion.label,
                            direccion: datosGps.direccion? datosGps.direccion: "",
                            nivelbateria: datosGps.nivel_bateria? datosGps.nivel_bateria: "",
                            ultimo_reporte: datosGps.ultimo_reporte? datosGps.ultimo_reporte: "",
                            gps: gps.data,
                            mov: movimientoPrimario,
                          });
                          setGpsAgregado(gpsAgregadoTemp);
                          setGps({ value: 0, label: "Seleccione un GPS", data: null });
                          setTipoColocacion({ value: 0, label: "Seleccione una opción", data_: null });
                        }}
                        pill
                        color="primary"
                        outline
                        disabled={
                          !gps.value || !tipoColocacion.value
                        }
                      >
                        <i
                          class="fa fa-plus"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="agregar GPS"
                        ></i>
                      </Button>
                    </div>
                    <div className="col col-12 col-sm-12 col-md-12 ">
                      <Table
                        cards={true}
                        striped={true}
                        responsive={true}
                        className="table-vcenter"
                      >
                        <Table.Header align="center">
                          <Table.ColHeader> ID </Table.ColHeader>
                          <Table.ColHeader> NOMBRE GPS </Table.ColHeader>
                          <Table.ColHeader> TIPO GPS </Table.ColHeader>
                          <Table.ColHeader> DATOS GPS </Table.ColHeader>
                          <Table.ColHeader> NIVEL BATERIA GPS </Table.ColHeader>
                          <Table.ColHeader> ULTIMO REPORTE </Table.ColHeader>
                          <Table.ColHeader> BORRAR </Table.ColHeader>
                        </Table.Header>
                        <Table.Body>
                          { gpsAgregado.map((gpsDis, index) => (
                            <Table.Row align="center">
                              <Table.Col> {gpsDis.id} </Table.Col>
                              <Table.Col> {gpsDis.nombre} </Table.Col>
                              <Table.Col> {gpsDis.tipo} </Table.Col>
                              <Table.Col> {gpsDis.direccion} </Table.Col>
                              <Table.Col>
                                {gpsDis.nivelbateria <= "100" &&
                                gpsDis.nivelbateria >= "80" ? (
                                  <Icon prefix="fa" name="battery-4" />
                                ) : null}
                                {gpsDis.nivelbateria <= "79" &&
                                gpsDis.nivelbateria >= "55" ? (
                                  <Icon prefix="fa" name="battery-3" />
                                ) : null}
                                {gpsDis.nivelbateria <= "54" &&
                                gpsDis.nivelbateria >= "41" ? (
                                  <Icon prefix="fa" name="battery-2" />
                                ) : null}
                                {gpsDis.nivelbateria <= "40" &&
                                gpsDis.nivelbateria >= "25" ? (
                                  <Icon prefix="fa" name="battery" />
                                ) : null}
                                {gpsDis.nivelbateria >= "0" &&
                                gpsDis.nivelbateria <= "24" ? (
                                  <Icon prefix="fa" name="battery-0" />
                                ) : null}
                                {gpsDis.nivelbateria} %
                              </Table.Col>
                              <Table.Col> {gpsDis.ultimo_reporte} </Table.Col>
                              <Table.Col>
                                <span
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={
                                    gpsAgregadoOld.filter(go => go.id === gpsDis.id).length?
                                      "Solicita esta acción a la central de monitoreo(Los gps ya guardos, solo pueden removerse por la central)"
                                    : "Remover gps"
                                  }
                                >
                                  <Button
                                    onClick={() =>{
                                      let gpsAgregadoTem= [...gpsAgregado];
                                      gpsAgregadoTem.splice(index, 1);
                                      setGpsAgregado(gpsAgregadoTem);
                                    }}
                                    color="danger"
                                    //disabled={ gpsAgregadoOld.filter(go => go.id === gpsDis.id).length}                                
                                  >
                                    Borrar
                                  </Button>
                                </span>
                              </Table.Col>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </div>
                  <div class="row"></div>
                </Card.Body>
              </Card>
            </div>

            <hr style={estilo} />

            <div class="row">
              {/**  Movimientos en full, debe de tomarse como una nueva asignacion*/}
              <Form.Switch
                type="Checkbox"
                label={esFull ? "Movimiento en full" : "Colocar en full"}
                checked={esFull}
                onChange={(e)=> setEsFull(e.target.checked)}
              />

              {/* <Form.Switch
                type="Checkbox"
                label={dentroDeProtocolo ? "Dentro de protocolo" : "Fuera de protocolo"}
                //checked={dentroDeProtocolo}
                onChange={changeDentroDeProtocolo}
              /> */}

              {esFull ? (
                <Card title={<h3>MOVIMIENTO FULL</h3>} isCollapsible>
                  <Card.Body>
                    <div class="row">
                      <div className="col col-12 col-sm-12 col-md-4">
                        <Form.Group label="ID MOVIMIENTO- PO -CONTENEDOR FULL (requerido)">
                          <Select
                            value={movimientoSecundario}
                            onChange={(e)=>{
                              setMovimientoSecundario(e);                       
                              setMovimientosPatiosFull(
                                {...movimientosPatiosFull,
                                  esFull: movimiento.idMovimiento,
                                  idMovimiento: Number(e.value),
                                  remolquePlaca: "",
                                  economicor: "",
                                  observaciones: "",
                                });
                              setMovimientosPatios({...movimientosPatios, esFull: e.value });
                              getfuntion( "get", [], "", "navieraMovimientos/" +  e.value, "movimientoFull" );
                            }}
                            options={movimientosSinGps}
                            isDisabled={Number(movimientosPatios.esFull)}
                          />
                        </Form.Group>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-4 ">
                        <Form.Group label="Placa Remolque">
                          {" "}
                          <Form.Input
                            name="placaRemolqueF"
                            type="text"
                            value={movimientosPatiosFull.remolquePlaca}
                            onChange={(e)=> setMovimientosPatiosFull({...movimientosPatiosFull, remolquePlaca: e.target.value }) }
                          />{" "}
                        </Form.Group>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-4 ">
                        <Form.Group label="Economico (Remolque)">
                          {" "}
                          <Form.Input
                            name="economicorF"
                            type="text"
                            value={movimientosPatiosFull.economicor}
                            onChange={(e)=> setMovimientosPatiosFull({...movimientosPatiosFull, economicor: e.target.value }) }
                          />{" "}
                        </Form.Group>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-4 ">
                        <Form.Group label="Obseraciones">
                          {" "}
                          <Form.Textarea
                            name="observacionesF"
                            onChange={(e)=> setMovimientosPatiosFull({...movimientosPatiosFull, observaciones: e.target.value }) }
                          >{movimientosPatiosFull.observaciones}</Form.Textarea>{" "}
                        </Form.Group>
                      </div>
                    </div>

                    <hr style={estilo} />
                    <div class="row">
                      <div className="col col-12 col-sm-12 col-md-3 ">
                        <Form.Group label="NOMBRE GPS FULL">
                          <Select
                            value={gpsFull}
                            onChange={(e)=>{
                              getfuntion(
                                "get",
                                [],
                                "",
                                "gpsestatus/plataforma/"+movimientoFull.idPlataforma.idGg+"/gps/" + e.value,
                                "datosGps"
                              );
                              setGpsFull(e);
                            }}
                            options={gpsList.filter( g=> gpsAgregado.filter( ga => ga.id === g.value).length === 0 || gpsAgregadosFull.filter( ga => ga.id === g.value).length === 0 )}
                          />
                        </Form.Group>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-3 ">
                        <Form.Group label="TIPO GPS FULL">
                          {" "}
                          <Select
                            value={tipoColocacionFull}
                            onChange={(e)=> setTipoColocacionFull(e) }
                            options={gpstipocolocaciones}
                          />{" "}
                        </Form.Group>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-3 ">
                        <Button
                          onClick={() => {
                            if ( !gpsFull.data.gpsHardware.id === 0 ) {
                              NotificationManager.error(
                                `No se puede asignar el GPS ${gpsFull.data.nombre}, ya que no cuenta con la información del hardaware, contacte a la persona responsable.`,
                                "Error"
                              );
                              return;
                            }
                            
                            if (
                              gpsAgregadosFull.find((objectGPS) => objectGPS.tipoid.id === tipoColocacionFull.value)) {
                              NotificationManager.error(
                                `Ya se ha agregado un GPS de tipo ${tipoColocacionFull.label} al movimiento`,
                                "Error"
                              );
                              return;
                            }
  
                            if (gpsAgregado.find( (objectGPS) => objectGPS.id === gpsFull.value)) {
                              NotificationManager.error(
                                `No se puede agregar el GPS ${gpsFull.data.nombre}, ya se encuentra asociado a otro movimiento.`,
                                "Error"
                              );
                              return;
                            }
                            let gpsAgregadoTemp= [...gpsAgregadosFull];
                            gpsAgregadoTemp.push({
                              id: gpsFull.value,
                              nombre: gpsFull.label,
                              tipoid: tipoColocacionFull.data,
                              tipo: tipoColocacionFull.label,
                              direccion: datosGps.direccion? datosGps.direccion: "",
                              nivelbateria: datosGps.nivel_bateria? datosGps.nivel_bateria: "",
                              ultimo_reporte: datosGps.ultimo_reporte? datosGps.ultimo_reporte: "",
                              gps: gpsFull.data,
                              mov: movimientoFull,
                            });
                            setGpsAgregadosFull(gpsAgregadoTemp);
                            setGpsFull({ value: 0, label: "Seleccione un GPS", data: null });
                            setTipoColocacionFull({ value: 0, label: "Seleccione una opción", data_: null });
                          }}
                          pill
                          color="primary"
                          outline
                          disabled={
                            !gpsFull.value ||
                            !tipoColocacionFull.value
                          }
                        >
                          <i
                            class="fa fa-plus"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="agregar GPS"
                          ></i>
                        </Button>
                      </div>

                      <div className="col col-12 col-sm-12 col-md-12 ">
                        <Table
                          cards={true}
                          striped={true}
                          responsive={true}
                          className="table-vcenter"
                        >
                          <Table.Header align="center">
                            <Table.ColHeader> ID </Table.ColHeader>
                            <Table.ColHeader> NOMBRE GPS </Table.ColHeader>
                            <Table.ColHeader> TIPO GPS </Table.ColHeader>
                            <Table.ColHeader> DATOS GPS </Table.ColHeader>
                            <Table.ColHeader> NIVEL BATERIA GPS </Table.ColHeader>
                            <Table.ColHeader> ULTIMO REPORTE </Table.ColHeader>
                            <Table.ColHeader> BORRAR </Table.ColHeader>
                          </Table.Header>
                          <Table.Body>
                            { gpsAgregadosFull.map((gpsDis, index) => (
                              <Table.Row align="center">
                                <Table.Col> {gpsDis.id} </Table.Col>
                                <Table.Col> {gpsDis.nombre} </Table.Col>
                                <Table.Col> {gpsDis.tipo} </Table.Col>
                                <Table.Col> {gpsDis.direccion} </Table.Col>
                                <Table.Col>
                                  {gpsDis.nivelbateria <= "100" &&
                                  gpsDis.nivelbateria >= "80" ? (
                                    <Icon prefix="fa" name="battery-4" />
                                  ) : null}
                                  {gpsDis.nivelbateria <= "79" &&
                                  gpsDis.nivelbateria >= "55" ? (
                                    <Icon prefix="fa" name="battery-3" />
                                  ) : null}
                                  {gpsDis.nivelbateria <= "54" &&
                                  gpsDis.nivelbateria >= "41" ? (
                                    <Icon prefix="fa" name="battery-2" />
                                  ) : null}
                                  {gpsDis.nivelbateria <= "40" &&
                                  gpsDis.nivelbateria >= "25" ? (
                                    <Icon prefix="fa" name="battery" />
                                  ) : null}
                                  {gpsDis.nivelbateria >= "0" &&
                                  gpsDis.nivelbateria <= "24" ? (
                                    <Icon prefix="fa" name="battery-0" />
                                  ) : null}
                                  {gpsDis.nivelbateria} %
                                </Table.Col>
                                <Table.Col> {gpsDis.ultimo_reporte} </Table.Col>
                                <Table.Col>
                                  <Button
                                    onClick={() =>{
                                      let gpsAgregadosFullTem= [...gpsAgregadosFull];
                                      gpsAgregadosFullTem.splice(index, 1);
                                      setGpsAgregadosFull(gpsAgregadosFullTem);
                                    }}
                                    color="danger"
                                  >
                                    Borrar
                                  </Button>
                                </Table.Col>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              ) : null}
            </div>          
          </div>
        </div>
        <div className="col-4 ml-6" style={{backgroundColor: "rgba(105, 151, 236, 0.08)"}}>
          <h3 className="float-right"> Vista previa </h3>
          <h5 className="float-left mt-6"> Movimiento 1 </h5>
          <ul className="list-group list-group-flush mt-8">
            <li className="list-group-item"><span className="float-left"></span>idMovimiento<span className="float-right">{movimientosPatios.idMovimiento}</span></li>          
            <li className="list-group-item"><span className="float-left"></span>economicot<span className="float-right">{movimientosPatios.economicot}</span></li>
            <li className="list-group-item"><span className="float-left"></span>idRemolque<span className="float-right">{movimientosPatios.idRemolque? movimientosPatios.idRemolque.nombre: ""}</span></li>
            <li className="list-group-item"><span className="float-left"></span>operadorNombre<span className="float-right">
              {movimientosPatios.operadorNombre && movimientosPatios.operadorNombre.length?
                movimientosPatios.operadorNombre
              : <i className="fa fa-warning text-warning"></i>
              }</span></li>
            <li className="list-group-item"><span className="float-left"></span>operadorContacto<span className="float-right">
              { movimientosPatios.operadorContacto && movimientosPatios.operadorContacto.length?
                movimientosPatios.operadorContacto
                : <i className="fa fa-warning text-warning"></i>
              }</span></li>
            <li className="list-group-item"><span className="float-left"></span>operadorLicencia<span className="float-right">{movimientosPatios.operadorLicencia}</span></li>
            <li className="list-group-item"><span className="float-left"></span>tractorMarca<span className="float-right">
              {movimientosPatios.tractorMarca && movimientosPatios.tractorMarca.length?
                movimientosPatios.tractorMarca
              : <i className="fa fa-warning text-warning"></i>
              }
              </span></li>
            <li className="list-group-item"><span className="float-left"></span>tractorModelo<span className="float-right">
              {movimientosPatios.tractorModelo && movimientosPatios.tractorModelo.length?
                movimientosPatios.tractorModelo
              : <i className="fa fa-warning text-warning"></i>
              }
              </span></li>
            <li className="list-group-item"><span className="float-left"></span>tractorColor<span className="float-right">
              { movimientosPatios.tractorColor && movimientosPatios.tractorColor.length?
                movimientosPatios.tractorColor
              : <i className="fa fa-warning text-warning"></i>
              }
              </span></li>
            <li className="list-group-item"><span className="float-left"></span>tractorPlaca<span className="float-right">
              { movimientosPatios.tractorPlaca && movimientosPatios.tractorPlaca.length?
                movimientosPatios.tractorPlaca
              : <i className="fa fa-warning text-warning"></i>
              }
              </span></li>            
            <li className="list-group-item"><span className="float-left"></span>idLocalidad<span className="float-right">{movimientosPatios.idLocalidad? movimientosPatios.idLocalidad.nombre:""}</span></li>
            <li className="list-group-item"><span className="float-left"></span>idUsuario<span className="float-right">
                {movimientosPatios.idUsuario? (movimientosPatios.idUsuario.nombre+" "+movimientosPatios.idUsuario.aPaterno+" "+movimientosPatios.idUsuario.aMaterno):""}
              </span></li>
            <li className="list-group-item"><span className="float-left"></span>colocacionFecha<span className="float-right">{timeToDateFill(new Date().getTime())}</span></li>
            <li className="list-group-item"><span className="float-left"></span>enProtocolo<span className="float-right">
              { movimientosPatios.enProtocolo
                ? "Dentro de protocolo"
                : "Fuera de protocolo" }
            </span></li>
            <hr style={estilo} />
            <li className="list-group-item"><span className="float-left"></span>esFull<span className="float-right">{esFull? ("Full con: " + movimientosPatios.esFull): "Sencillo" }</span></li>
            <li className="list-group-item"><span className="float-left"></span>economicor<span className="float-right">
              {movimientosPatios.economicor && movimientosPatios.economicor.length?
                movimientosPatios.economicor
              : <i className="fa fa-warning text-warning"></i>
              }
              </span></li>
            <li className="list-group-item"><span className="float-left"></span>remolquePlaca<span className="float-right">
              {movimientosPatios.remolquePlaca && movimientosPatios.remolquePlaca.length?
                movimientosPatios.remolquePlaca
              : <i className="fa fa-warning text-warning"></i>
              }
              </span></li>
            <li className="list-group-item"><span className="float-left"></span>observaciones<span className="float-right">{movimientosPatios.observaciones}</span></li>
            <li className="list-group-item"><span className="float-left"></span>Gps's<span className="float-right">
              {gpsAgregado.map((ga, index)=> <span>{(index > 0 && index < gpsAgregado.length? ", ": "")+ ga.nombre}</span>) }
              </span></li>
            {gpsAgregadoOld.filter(gao => gpsAgregado.filter( ga=> ga.id === gao.id).length === 0 ).length?
              <li className="list-group-item text-danger"><span className="float-left"></span>Se removeran estos Gps's<span className="float-right">
                { gpsAgregadoOld.filter(gao => gpsAgregado.filter( ga=> ga.id === gao.id).length === 0 ).map((ga, index)=>
                  <span>{(index > 0 && index < gpsAgregadoOld.length? ", ": "")+ ga.nombre}</span>
                ) }
              </span></li>
            : null}
          </ul>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={saveProgress.activeStep}>
              {steps.map((etiqueta, index) => {
                const labelProps = {};
                if (index === saveProgress.isStepFailed) {
                  labelProps.optional = (
                    <Typography variant="caption" color="error">
                      {saveProgress.stepFailedMessage}
                    </Typography>
                  );
                  labelProps.error = true;
                }

                return (
                  <Step key={etiqueta}>
                    <StepLabel {...labelProps}>{etiqueta}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          { movimientosPatiosFull.idMovimiento && Number(movimientosPatiosFull.esFull) &&  movimientosPatios.esFull?
            <div>
              <hr style={estilo} />
              <h5 className="float-left mt-2"> Movimiento 2 </h5>
              <br/>
              <ul className="list-group list-group-flush mt-8">
                <li className="list-group-item"><span className="float-left"></span>idMovimiento<span className="float-right">{movimientosPatiosFull.idMovimiento}</span></li>
                <li className="list-group-item"><span className="float-left"></span>esFull<span className="float-right">{esFull? ("Full con: " + movimientosPatiosFull.esFull): "" }</span></li>
                <li className="list-group-item"><span className="float-left"></span>economicor<span className="float-right">
                  { movimientosPatiosFull.economicor && movimientosPatiosFull.economicor.length?
                      movimientosPatiosFull.economicor
                    : <i className="fa fa-warning text-warning"></i>
                    }
                  </span></li>
                <li className="list-group-item"><span className="float-left"></span>remolquePlaca<span className="float-right">
                  { movimientosPatiosFull.remolquePlaca && movimientosPatiosFull.remolquePlaca.length?
                    movimientosPatiosFull.remolquePlaca
                  : <i className="fa fa-warning text-warning"></i>
                  }
                  </span></li>
                <li className="list-group-item"><span className="float-left"></span>observaciones<span className="float-right">{movimientosPatiosFull.observaciones}</span></li>
                <li className="list-group-item"><span className="float-left"></span>Gps's<span className="float-right">                
                  {gpsAgregadosFull.map((ga, index)=> <span>{(index > 0 && index < gpsAgregadosFull.length? ", ": "")+ ga.nombre}</span>) }
                </span></li>
                { gpsAgregadosFullOld.filter(gao => gpsAgregadosFull.filter( ga=> ga.id === gao.id).length === 0 ).length?
                  <li className="list-group-item text-danger"><span className="float-left"></span>Se removeran estos Gps's<span className="float-right">
                    { gpsAgregadosFullOld.filter(gao => gpsAgregadosFull.filter( ga=> ga.id === gao.id).length === 0 ).map((ga, index)=>
                      <span>{(index > 0 && index < gpsAgregadosFullOld.length? ", ": "")+ ga.nombre}</span>
                    ) }
                  </span></li>
                : null}
              </ul>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={saveProgressFull.activeStep}>
                  {steps.map((etiqueta, index) => {
                    const labelProps = {};
                    if (index === saveProgressFull.isStepFailed) {
                      labelProps.optional = (
                        <Typography variant="caption" color="error">
                          {saveProgressFull.stepFailedMessage}
                        </Typography>
                      );
                      labelProps.error = true;
                    }

                    return (
                      <Step key={etiqueta}>
                        <StepLabel {...labelProps}>{etiqueta}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
            </div>            
            : null
          }
        </div>
      </div>
      <br />
        <div className="row">
          <div class="col-12">
            <Form.Group>
              <Button.List align="right">
                <Button
                  onClick={() => {
                    agregarGPS();
                  }}
                  color="primary"
                  disabled={
                    disabledButon ||
                    (
                      [160, 126, 100, 102, 325, 10, 101, 327, 227, 326, 391, 424,].includes(plataformaid)
                        ? !(
                            movimientosPatios.remolquePlaca.length &&
                            movimientosPatios.economicor.length &&
                            //gpsAgregado.length && 
                            (esFull ? movimientoSecundario.value : true)
                          )
                        : !(
                            idRemolque.value &&
                            controlador.value &&
                            //gpsAgregado.length && 
                            (esFull ? movimientoSecundario.value : true)
                          )
                    )
                  }
                >
                  Guardar
                </Button>
              </Button.List>
            </Form.Group>
          </div>
        </div>
    </div>
  );
  /*
  changeDentroDeProtocolo = (event) => {
    setState({ dentroDeProtocolo: event.target.checked });
  };*/
}
export default DatosMovimientoGpsForm;
