import React, { useEffect, useState } from "react";
import { useIsOnline } from 'react-use-is-online';
import ModalLayout from "../../components/ModalLayout.react";

let timeout = 450;
let payload = null;
let clearTimer=null;

const NetworkStatus =()=> {
  const { isOnline, isOffline, error } = useIsOnline();
  const [ openModal, setOpenModal ] = useState(false);
  const [ slowConection, setSlowConection ] = useState(false);

  useEffect(() => {
    handleNetwork();
  }, []);

  useEffect(() => {
    if (isOffline || slowConection) {
      abrirModal();
    }
  }, [isOffline, slowConection]);

  const abrirModal =() => {
    if(!openModal){//alert("clieck: "+ openModal);
      document.getElementById("online9374y6").click();
      setOpenModal(true);
    }
  };

  const handleNetwork = () => {
    payload = null;
    clearTimer=null;
    getPosts();
    setTimeout(() => {      
      handleNetwork();
    }, 1000*10);
  };

  const checkNetwork = () => {
    /* if after half a second payload  variable is still null, 
  it means we are still awaiting the promise to settle */
    if (payload === null) {
      setSlowConection(true);
      console.log("Your internet connection is slow");
      //alert("Your internet connection is slow");
    }else{
      setSlowConection(false);
      console.log("Your internet connection is fast");
    }
  };

  async function getPosts() {    
    let url = `https://one.skynavieros.com/test.json`;//`http://localhost:3000/test.json`;
    /*  we clear any timeout*/
    if(clearTimer !== null) clearTimeout(clearTimer) 
    try {
      /*the callback in setTimeout will be executed regardless of the await keyword below it*/
      clearTimer=setTimeout(checkNetwork, timeout);
      const response = await fetch(url);
      payload = await response.json();
      return payload;
    } catch (error) {
      console.error(error.message);
    }
  }
  const getColor = () => {
    if(isOnline){
      if(slowConection){
        return "text-danger";
      }
      return "text-success";
    }else{
      return "text-secondary";
    }
  };
  return (
    <span className="float-right p-3 mr-6">
      <span data-toggle="tooltip"
                data-placement="top"
                title={ (isOnline ?  "En linea ! " : "Sin internet ! " )+(slowConection? ", Conexión lenta": "") }
      >
        <i className={"fa fa-wifi"+" "+getColor()} style={{fontSize: "30px"}}></i>
      </span>
      <ModalLayout
        id="online9374y6"
        tamanio={"60%"}
        title="Estatus de internet"
        name={<></>}
        hiddenModal={openModal}
        Fclose={()=>{
          setOpenModal(false);
        }}
        formulario={
          <div>
            <i className={"fa fa-wifi"+" "+getColor()} style={{fontSize: "30px"}}></i>
            <span> { isOnline ?  "En linea ! " : "Sin internet ! " } { slowConection && ", Conexión lenta" } </span>
          </div>
        }
      />
    </span>
  );
}
export default NetworkStatus;
