import React, { Component, useRef }                      from "react";
import { Button, Form, Icon, Grid, Card, List } from "tabler-react";
import { Crud_Catalogos, Crud_error }            from '../../../herramientas/Crud_Catalogos';
import NumberInput from '../../../herramientas/Input/NumberInput'
import { number } from "prop-types";


class RemolquesTransportistas extends Component {
  
  constructor(props) {
    console.log(props)
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this)
  }
 

  componentWillMount =() => {
      ///REllenamos los valores de los inputs a setear antes de asignarlos a un total disponible.
      let arrayInputsDisponibles = Array(this.props.stor.props.data.remolquesTransportistas.length).fill(0);
      let arrayInputsReportados = Array(this.props.stor.props.data.remolquesTransportistas.length).fill(0);
      let arrayInputsApartados = Array(this.props.stor.props.data.remolquesTransportistas.length).fill(0);
      let arrayInputsOcupados = Array(this.props.stor.props.data.remolquesTransportistas.length).fill(0);
      this.state = {
                  idTransportista:this.props.stor.props.data.remolquesTransportistas[0].idTransportistaLocalidades.idTransportista,
                  usuario:this.props.stor.usuario,
                  estatus:false,
                  accion:this.props.accion, //'nuevo'// modificar, nuevo
                  FArray:[],
                  hasFocus:false,
                  selected:"",
                  currentTap:0,
                  crudbutonEnable:false,
                  IArrayDisponibles:arrayInputsDisponibles,
                  IArrayReportados:arrayInputsReportados,
                  IArrayApartados:arrayInputsApartados,
                  IArrayOcupado:arrayInputsOcupados,
                  remolquesTransportista:this.props.stor.props.data.remolquesTransportistas,
                  bandera:false,
                  
                
      }; 
      this.handleSubmit = this.handleSubmit.bind(this);
  
  
  }

  myFormato(date) {
    function pad(number) {
      if (number < 10) {
        return "0" + number;
      }
      return number;
    }
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      " " +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds())
    );
  }

  detectedChange = (newArray)=>{


    let camposmodificados = "Localidad: " + this.props.stor.props.data.corredor.nombre;
    let bitacora = 113
    let remolques = this.props.stor.props.data.remolques
    let bandera =  false;

    this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {
            bitacora = 114;
            bandera = true;
             if(newArray[i].total_disponible != typeRemolque.disponibilidadRemolques.total_disponible){

                if (camposmodificados.length > 0) {
                  camposmodificados = camposmodificados + ",  ";
                }
                camposmodificados = camposmodificados +  typeRemolque.idRemolque.nombre+"("+ typeRemolque.tipoCorredor+")"+ ":( ";
                camposmodificados =
                  camposmodificados + "disponibles de " +
                  typeRemolque.disponibilidadRemolques.total_disponible +
                  " a " +
                  newArray[i].total_disponible +
                  ")";
              } 

              if(newArray[i].total_apartado != typeRemolque.disponibilidadRemolques.total_apartado){

                if (camposmodificados.length > 0) {
                  camposmodificados = camposmodificados + ",  ";
                }
                  camposmodificados = camposmodificados +  typeRemolque.idRemolque.nombre+"("+ typeRemolque.tipoCorredor+")"+ ":( ";
                  camposmodificados =
                    camposmodificados + "apartados de " +
                    typeRemolque.disponibilidadRemolques.total_apartado +
                    " a " +
                    newArray[i].total_apartado +
                    ")";
              } 

              if(newArray[i].total_ocupado != typeRemolque.disponibilidadRemolques.total_ocupado){

                if (camposmodificados.length > 0) {
                  camposmodificados = camposmodificados + ",  ";
                }
                  camposmodificados = camposmodificados +  typeRemolque.idRemolque.nombre + "("+ typeRemolque.tipoCorredor+")"+ ":( ";
                  camposmodificados =
                    camposmodificados + "ocupados de " +
                    typeRemolque.disponibilidadRemolques.total_ocupado +
                    " a " +
                    newArray[i].total_ocupado +
                    ")";
              } 

      bandera = false;
    
    })


    if(newArray[0].comentarios != "" && this.props.accion != "modificar" ){

      camposmodificados = camposmodificados +  "  Comentarios:( ";
      camposmodificados =
        camposmodificados +
        "" +
        " a " +
        newArray[0].comentarios +
        ")";

    }

    return { id: bitacora, descripcion: camposmodificados };

  }


  GetInputsDisponibles = ()=>{

      let tempArrayInputs = this.state.IArrayDisponibles; 
      let remolques = this.props.stor.props.data.remolques
        
   return this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {
    if ( !this.state.bandera) {
      tempArrayInputs[i] = typeRemolque.disponibilidadRemolques ?typeRemolque.disponibilidadRemolques.total_disponible: 0;

    }
            return  <div key={i}>

             {/* <NumberInput min={0} max={this.state.IArrayReportados[i]} value={this.state.IArrayDisponibles[i]} name={typeRemolque.idRemolque.nombre}  indice={i}  tipo={"disponibles"} cambio={this.handleChange} />            
             */}
              <Form.Input  type="number" readonly="readonly" value={this.state.IArrayDisponibles[i]}  name={typeRemolque.disponibilidadRemolques.id} className="remolques"   id={typeRemolque.idRemolque}  onChange={e => { this.handleChange(e.target.value,i,"disponibles",this.state.IArrayDisponibles[i]) }} >{this.state.IArrayDisponibles[i]}</Form.Input> 
              </div>
          })
  
  }

  GetInputsOcupados = ()=>{

    let tempArrayInputs = this.state.IArrayOcupado; 
    let remolques = this.props.stor.props.data.remolques
      
 return this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {
  if ( !this.state.bandera) {
    tempArrayInputs[i] = typeRemolque.disponibilidadRemolques ?typeRemolque.disponibilidadRemolques.total_ocupado: 0;

  }
          return  <div key={i}>

            {}

           {/* <NumberInput min={0} max={this.state.IArrayReportados[i]} value={this.state.IArrayDisponibles[i]} name={typeRemolque.idRemolque.nombre}  indice={i}  tipo={"disponibles"} cambio={this.handleChange} />            
           */}
            <Form.Input  type="number"  value={this.state.IArrayOcupado[i]}  name={typeRemolque.disponibilidadRemolques.id} className="remolques"   id={typeRemolque.idRemolque}  onChange={e => { this.handleChange(e.target.value,i,"ocupados",this.state.IArrayOcupado[i]) }} >{this.state.IArrayOcupado[i]}</Form.Input> 
            </div>
        })

}


  GetInputsApartados = ()=>{

    let tempArrayInputs = this.state.IArrayApartados; 
    let remolques = this.props.stor.props.data.remolques
      
 return this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {

  if ( !this.state.bandera) {
    tempArrayInputs[i] = typeRemolque.disponibilidadRemolques ?typeRemolque.disponibilidadRemolques.total_apartado: 0;

  }
          return  <div key={i}>
              {/* <NumberInput min={0} max={this.state.IArrayDisponibles[i]} value={this.state.IArrayApartados[i]} name={"tipo"}  />            
             */}
            <Form.Input  type="number"  value={this.state.IArrayApartados[i]}  name={typeRemolque.disponibilidadRemolques.id} className="remolques"   id={typeRemolque.idRemolque}  onChange={e => { this.handleChange(e.target.value,i,"apartados",this.state.IArrayApartados[i])}} >{this.state.IArrayApartados[i]}</Form.Input>
            </div>
        })

}

  GetInputsReportadas = ()=>{

    let tempArrayInputs = this.state.IArrayReportados; 
    let remolques = this.props.stor.props.data.remolques
        
   return this.props.stor.props.data.remolquesTransportistas.map((typeRemolque, i) => {

    tempArrayInputs[i] = typeRemolque.disponibilidadRemolques ?typeRemolque.disponibilidadRemolques.total_registrado: 0;

            
            return  <div key={i}>
              {this.state.accion === "modificar" ? 
              <Form.StaticText  type="number" >{this.state.IArrayReportados[i]}</Form.StaticText> 
              :  
              null
              }
              </div>
          })
  
  }
  
  handleChange = (value,indice,reportados,max) => {

    // console.log(value)
    // console.log(tipo)
    // console.log(this.state.IArrayDisponibles[indice])
    // let arrayTempInputs = tipo == 'disponibles' ? this.state.IArrayDisponibles : this.state.IArrayReportados;
    // arrayTempInputs[indice]=  value;
   
    // console.log(arrayTempInputs[indice])
    // console.log("first")
    
 
    console.log(reportados)


    let arrayTempInputs;

    // if(reportados == "disponibles"){
    

    //   arrayTempInputs = this.state.IArrayDisponibles 
    //   arrayTempInputs[indice] =  value;
    //   let limite = this.state.IArrayApartados[indice] + this.state.IArrayDisponibles[indice];
    //   if (this.state.IArrayApartados[indice] > 0)this.state.IArrayApartados[indice] = this.state.IArrayReportados[indice] - value;
    //   if( value < 0 || this.state.IArrayApartados[indice] < 0 || (this.state.IArrayReportados[indice] < limite && this.state.IArrayApartados[indice] > 0)
    //     || this.state.IArrayApartados[indice] < 0 || (this.state.IArrayReportados[indice] < limite && this.state.IArrayApartados[indice] == 0)){
    //     return
    //   }
    // }
   
    if(reportados == "apartados"){

     
      
      arrayTempInputs = this.state.IArrayApartados;
      
      this.state.IArrayDisponibles[indice] =  Number(this.state.IArrayReportados[indice]) - (Number(this.state.IArrayOcupado[indice]) + Number(value));
      let limite = Number(this.state.IArrayReportados[indice]) - Number((this.state.IArrayOcupado[indice]));
      if(value < 0 || this.state.IArrayDisponibles[indice] < 0 || limite == 0){
        console.log("entro no")
        return
      }
      arrayTempInputs[indice] =  value;

      
    }

    if(reportados == "ocupados"){

      arrayTempInputs = this.state.IArrayOcupado;
      
      this.state.IArrayDisponibles[indice] =  Number(this.state.IArrayReportados[indice]) - (Number(this.state.IArrayApartados[indice]) + Number(value));
      let limite = Number(this.state.IArrayReportados[indice]) - Number((this.state.IArrayApartados[indice]));
      if(value < 0 || this.state.IArrayDisponibles[indice] < 0 || limite == 0){
        return
      }
      arrayTempInputs[indice] =  value;
      
    }


    
    this.setState({bandera:true})
    let elements = document.getElementsByClassName("remolques");
    let bandera =  false;
  

    for (let i = 0; i < elements.length; i++) {
      
      if(elements[i].value > 0 ){
        bandera = true;
      }
      
  }
  
    
    bandera ? this.setState({crudbutonEnable:true}) : this.setState({crudbutonEnable:false})

 

  }


  


  

  handleSubmit = (event)=>{
  
    event.preventDefault();
    const form  = event.target;
    const data = new FormData(form);
    let JSON_REQUEST = [];
    const idTransportista = this.state.idTransportista;
    const idUsuario       = this.state.usuario;
    const total           = this.state.IArrayApartados.length;
    const accion          = this.props.accion;
    const remolques       = this.props.stor.props.data.remolquesTransportistas;
    

    let i = 0;
    data.forEach(function(value, key){

      console.log(key)
      console.log(value)
        if(key !== 'comentarios' ){
          if(accion === "modificar" && remolques[0].disponibilidadRemolques){
                remolques.map(remolque=>{
                    if(remolque.disponibilidadRemolques.id == key){

                      let indexExiste = JSON_REQUEST.findIndex((item)=> item.idDisponibilidadTransportistaRemolque == Number(key));
                          if(indexExiste != -1){
                            if(JSON_REQUEST[indexExiste].idDisponibilidadTransportistaRemolque == Number(key) && JSON_REQUEST[indexExiste].total_apartado == null ){                  
                              JSON_REQUEST[indexExiste].total_apartado    = Number(value);
                              if(value > 0 && remolque.disponibilidadRemolques.total_apartado != value){
                                let date =  new Date();
                               // fecha_utc = new Date(fecha_utc.getTime() + (fecha_utc.getTimezoneOffset() * 60 * 1000)); 

                               let dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
                               .toISOString()
                               .replace("T" ," ");
                               console.log(dateString)
                                JSON_REQUEST[indexExiste].fechaApartado =  dateString.replace(".000Z","")
                                console.log(JSON_REQUEST[indexExiste].fechaApartado)
                              }
                            }
                            if(JSON_REQUEST[indexExiste].idDisponibilidadTransportistaRemolque == Number(key) && JSON_REQUEST[indexExiste].total_apartado != null ){
                                JSON_REQUEST[indexExiste].total_disponible    = Number(value);
                            }
                          }else{
                            JSON_REQUEST.push({
                              "idDisponibilidadTransportistaRemolque" : Number(key),
                              "total_disponible":null,
                              "total_apartado":null,
                              "total_ocupado":Number(value),
                              "comentarios":data.get('comentarios')
                        
                            });
                            i++;
                          }
                    }
                  
                })
          }

          
        }
        
    });

    
console.log(JSON_REQUEST)

    if(accion === "modificar"){
     this.crud_put(JSON_REQUEST)
    }else{
      this.crud_post(JSON_REQUEST)
    }


    let bitacora = this.detectedChange(JSON_REQUEST);

    let arrastreLogObject = {
      idBitacoraMonitoreo: bitacora.id,
      idUsuario: idUsuario,
      idTransportista: idTransportista,
      accionFecha: this.myFormato(new Date()),
      descripcion: bitacora.descripcion,
      idPlataformaTipo: 1,
      Navigator: false,
    };
    
    Crud_Catalogos(
      "arrastreLog",
      "",
      "post",
      "",
      arrastreLogObject
    );
    
   this.props.closeModal();
  
}

  
  delete()    { this.props.getfuntion("delete", "", this.state.id); }
  crud_put(request) {  this.props.getfuntion("put",request,"","disponibilidadRemolquesTransportistas"); }
  crud_post(request) { this.props.getfuntion("post",request,"","disponibilidadRemolquesTransportistas"); }
 

 
  render() {
  
   const GetInputsLabels = ()=>{
      return(<>
       {this.props.stor.props.data.remolquesTransportistas.map((remolque, i) => {
        return (
          <>
            <Form.Input  disabled value={remolque.idRemolque.nombre + "("+ remolque.tipoCorredor+")"}  />
          </>
        );
      
    })}
      </>)
     
    }

   
    return (
      
      <div className="formRemolques">
 {this.state.accion === "modificar" ? <h1> {this.props.elementos.nombre} </h1> : null}
        <Grid.Row>
          <Grid.Col sm={12} lg={12}>
            <Card title="Remolques de Transportista" isCollapsible isCollapsed={false} body={
                  <Form  onSubmit={this.handleSubmit}  className="FormRemolques">
                    <div className="unidades">
                    <Grid.Col sm={12} lg={12}>
                      
                      <ul>
                          <li>
                            <a href="#">
                              <h2>Comentario</h2>
                              
                              <p ><textarea className="comentariosForm" name="comentarios" value={this.state.remolquesTransportista[0].disponibilidadRemolques.comentarios}></textarea></p>
                                
                            </a>
                          </li>
                        
                    </ul>
                    </Grid.Col>  
                    
                  </div>
                  <Grid.Row>
                    <Grid.Col sm={3} lg={3}>
                      <Form.Group label="Tipo de Remolque">
                     <GetInputsLabels></GetInputsLabels>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col sm={2} lg={2}>
                      <Form.Group  label={<>Reportados<label className="text-danger">*</label></>}>
                      {this.GetInputsReportadas()}
                        
                      </Form.Group>
                    </Grid.Col>
                   
                    {this.state.accion === "modificar" ? 
                    <Grid.Col sm={2} lg={2}>
                      <Form.Group  label={<>Ocupados<label className="text-danger">*</label></>}>
                      {this.GetInputsOcupados()}
                        
                      </Form.Group>
                    </Grid.Col>
                   :null}


                  {this.state.accion === "modificar" ? 
                    <Grid.Col sm={2} lg={2}>
                      <Form.Group  label={<>Apartados<label className="text-danger">*</label></>}>
                      {this.GetInputsApartados()}
                        
                      </Form.Group>
                    </Grid.Col>
                   :null}


                  {this.state.accion === "modificar" ? 
                    <Grid.Col sm={2} lg={2}>
                      <Form.Group  label={<>Disponibles<label className="text-danger">*</label></>}>
                      {this.GetInputsDisponibles()}
                        
                      </Form.Group>
                    </Grid.Col>
                   :null}
                   
                  </Grid.Row>  

                
                  <Grid.Row className="unidades">
                      <Grid.Col sm={12} lg={12}>
                      <div className="float-right">
                                    
                                    <span class="badge">
                                        <Button  size="sm" color="primary" disabled={!this.state.crudbutonEnable}
                                        >
                                        <span class="badge"><Icon  name="save" />{this.props.accion}</span>
                                        </Button>
                                    </span>
                                   
                                  
                                </div>
                    </Grid.Col>
                  </Grid.Row>
                  
                </Form> 
                  
                
                }
            />
          </Grid.Col>
        </Grid.Row>

        
      </div>
    );
  }
}
export default RemolquesTransportistas;
