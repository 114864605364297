import React, { Component } from "react";
import { Grid, Form, Card, Button, Icon } from "tabler-react";
import Monitoreo from "./Monitoreo.react";
var url = new URL(window.location.href);
class MonitoreoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plataformaTipo: 0,
    };
  }

  componentWillMount = () => {};
  componentDidMount = () => {};
  componentDidUpdate = () => {};
  render() {
    return (
      <Monitoreo
        mtipo={parseInt(url.searchParams.get("mtipo"))}
        gpsTipo={parseInt(url.searchParams.get("gpsTipo") ?? 0) }
      />
    );
  }
}
export default MonitoreoPage;
