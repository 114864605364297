import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";

const enviar = (accion, payload, setData, setLoading, setError) => {
	let resultados = [];
	if (payload === "") {
		setData([]);
		setLoading(false);
		setError(false);
		return;
	}
	switch (accion) {
		case "Inicio":
			setLoading(true);
			setError(false);
			break;
		case "Error":
			setLoading(false);
			setError(true);
			break;
		case "Resultados":
			setLoading(false);
			setError(false);
			setData(payload);
			break;
		case "Plataformas":
			payload.map((plataforma, index) =>
				resultados.push({
					value: plataforma.id_plataforma,
					label: plataforma.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Bloques":
			payload
				.filter((bloque) => bloque.nombre.includes("Reportes "))
				.map((bloque, index) =>
					resultados.push({
						value: bloque.id,
						label: bloque.nombre,
					})
				);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Columnas checked":
			payload.map((resultado, index) => resultados.push(resultado.idFuncionalidades));
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Funcionalidades":
			payload.map((resultado, index) =>
				resultados.push({
					value: resultado.id,
					label: resultado.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Tipo de incidencias":
		case "Operacion estatus":
		case "Navieras":
		case "Movimientos tipo":
		case "Columnas":
		case "Alertas tipo":
			payload.map((resultado, index) =>
				resultados.push({
					value: resultado.id,
					label: resultado.nombre,
				})
			);
			setLoading(false);
			setError(false);
			setData(resultados);
			break;
		case "Notificaciones tipo":
				
				payload.map((resultado, index) =>{
					let nombre = '';
					if([3,4,5,6,1].includes(resultado.id)){
						nombre = resultado.id == 3 ? "Estatus(consolidado)" : resultado.id == 4 ? "Retraso" : resultado.id == 5  ?  "ETA" : resultado.id == 1  ? "Incidencias(consolidado)" : "Espera"; 
						resultados.push({
							value: resultado.id,
							label: nombre,
						})
					}
					
				});
				setLoading(false);
				setError(false);
				setData(resultados);
				break;
		default:
			setLoading(false);
			throw new Error();
	}
};

const FetchData = (url, opcion, setData, setLoading, setError) => {
	enviar("Inicio", null, setData, setLoading, setError);
	return Crud_Catalogos(url, "resportesGenerales", "get", "", "", "", opcion, "", [])
		.then((returnVal) => {
			enviar(opcion, returnVal, setData, setLoading, setError);
			return new Promise((resolve) => {
				setTimeout(() => resolve("bien"), 5000);
			});
		})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
			} else {
				console.log("Error desconocido ...." + err);
			}
			enviar("Error", null, setData, setLoading, setError);
			return new Promise((resolve) => {
				setTimeout(() => resolve("mal"), 1000);
			});
		});
};

const PostData = (url, obj, setData, setLoading, setError) => {
	enviar("Inicio", null, setData, setLoading, setError);
	return Crud_Catalogos(url, "resportesGenerales", "post", "", obj, "", "", "", [])
		.then((returnVal) => {})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
			} else {
				console.log("Error desconocido ...." + err);
			}
			enviar("Error", null, setData, setLoading, setError);
		});
};

const DeleteData = (url, id, setData, setLoading, setError) => {
	enviar("Inicio", null, setData, setLoading, setError);
	return Crud_Catalogos(url, "resportesGenerales", "delete", id, "", "", "", "", [])
		.then((returnVal) => {})
		.catch((err) => {
			if (err.response) {
				console.log(err.response);
			} else {
				console.log("Error desconocido ...." + err);
			}
			enviar("Error", null, setData, setLoading, setError);
		});
};

export {FetchData, PostData, DeleteData};
