import React, { useState, useEffect, useContext } from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper, Polygon, Circle, Polyline} from 'google-maps-react';
const MapaEvidencia = ({alarmas, alarma}) => {
  const [alertaMarker, setAlertaMarker] = useState(); 
  useEffect(() => {
    if(alarma) {
      let marcadoresTemp= [];
      for( let a of alarmas ){
        marcadoresTemp.push(
          <Marker
                title={a.servidorFecha+"-> "+a.tipoString}
                name={ "alarma"+a.id }
                position={ { lat: a.latitud, lng: a.longitud }}
                icon={{
                    url: a.id === alarma.id ? "marcadores/red-map-pin.svg": "marcadores/blue-map-pin.svg",
                    //anchor: new google.maps.Point(32, 32),
                    //scaledSize: new google.maps.Size(64, 64)
                  }}
                  onClick={()=> {
                    alert(a.tipoString);
                  }}
          />
        );
      }
      setAlertaMarker(marcadoresTemp);
    } 
	}, []);
  const redondearDecimales = (numero, decimales) => {
    let numeroRegexp = new RegExp('\\d\\.(\\d){' + decimales + ',}');   // Expresion regular para numeros con un cierto numero de decimales o mas
    if (numeroRegexp.test(numero)) {         // Ya que el numero tiene el numero de decimales requeridos o mas, se realiza el redondeo
        return Number(numero.toFixed(decimales));
    } else {
        return Number(numero.toFixed(decimales)) === 0 ? 0 : numero;  // En valores muy bajos, se comprueba si el numero es 0 (con el redondeo deseado), si no lo es se devuelve el numero otra vez.
    }
  }
  const random_rgba =(opacidad= 1)=> {
    var o = Math.round, r = Math.random, s = 255;
    //return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ','+opacidad+ ')';
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ','+opacidad+ ')';
  }
  return (
    <div className="mt-3">
      <div style={{width: '100%'}}>
        <Map
          google={window.google}
          style={{width: '100%', height: '100%'}}
          className={'map'}
          zoom={ 10 }
          initialCenter={{ lat: alarma.latitud, lng: alarma.longitud }}//{{ lat: 19.430763, lng: -99.1680802}}
          center={{ lat: alarma.latitud, lng: alarma.longitud }}
          visible={true}
          onReady={true}
        >          
          {alertaMarker}
          { /*tracks.length &&
            <Polyline
              path={tracks}
              strokeColor={"#0000FF"}//"#0000FF" random_rgba(1)
              strokeOpacity={0.8}
              strokeWeight={2}
            />*/
          }

          {/*geocercas.length? geocercas.filter(g=> g.shapeType === "Polygon").map((g, index)=>
            <Polygon
              title={"Geocerca tringular" }
              paths= {g.polygonShape.vertices}
              strokeColor= "#FF0000"
              strokeOpacity= {0.8}
              strokeWeight= {2}
              fillColor= "#FF0000"
              fillOpacity= {0.35}
              onClick={()=> alert(g.name)}
            />
          ):null */}
          {/*
          <Circle
                radius={g.radius}
                center={g.vertices[0]}
                onMouseover={() => console.log('mouseover')}
                onClick={() => console.log('click')}
                onMouseout={() => console.log('mouseout')}
                strokeColor='transparent'
                strokeOpacity={0}
                strokeWeight={5}
                fillColor={g.color}
                fillOpacity={0.12}
              />
              */}
          {/*
          <InfoWindow onClose={()=>alert("exit")}>
              <div>
              <h1>{"Rio danubio"}</h1>
              </div>
          </InfoWindow>
          */}
        </Map>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyAPxZkM6L2aL0LEW_LyKG2EdMKW-e3-dU4")
  })(MapaEvidencia)


/*
<Polygon
                    paths={g.vertices}
                    strokeColor={g.color === "NEGRO"? "black": "red"}
                    strokeOpacity={0.8}
                    strokeWeight={2}
                    fillColor={g.color === "NEGRO"? "blue": "green"}
                    fillOpacity={0.35} />
*/