import React, { useState, useEffect } from "react";
import { Crud_Catalogos } from '../../../../herramientas/Crud_Catalogos';
import Select from 'react-select';
import { TablePageable } from "../../../../Helpers";

const NumerosInternos =({ plataforma })=> {
  const [chatbots, setChatbots]= useState([]);
  const [chatbot, setChatbot]= useState({ value: 0, label: "Selecciona una opción", data: {} });
  const [telefonos, setTelefonos]= useState([]);
  const [telefono, setTelefono]= useState({});
  useEffect(() => {
    if(plataforma.id){
      getfuntion("get", "", "", "chatbot/plataforma/"+plataforma.id, "chatbot");
    }
  }, []);

  useEffect(() => {
    if(chatbot.data && chatbot.data.idChatbot){
      if(telefonos.length > 0){
        setTelefonos([]);
      }
      clear();
    }
  }, [chatbot]);
  
  const clear =()=>{
    setTelefono({
      idChatbot: chatbot.data.idChatbot,
      telefono: ""
    });
    getfuntion("get", "", "", "chatbot_skyangel_telefonos/"+chatbot.data.idChatbot, "telefonos");
  }

  //chatbot_skyangel_telefonos/{idChatbot}/{telefono}
  const getfuntion = (metodo="get", obj=[], id="", catalogo="", stateVar= "", hiddenModl="") =>{
    return Crud_Catalogos(catalogo, "correoPlantillas", metodo, id, obj,"" , stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
          case "get":
            if( stateVar === "chatbot" && returnVal && returnVal.length > 0 ){
              let chatbotsTemp= [];
              for( let c of returnVal ){
                chatbotsTemp.push({ value: c.idChatbot, label: c.nombre, data: c });
              }
              setChatbots(chatbotsTemp);
            }
            if( stateVar === "telefonos" && returnVal && returnVal.length > 0 ){console.log(returnVal);
              setTelefonos(returnVal);
            }
            break;
          case "post":
            if( stateVar === "telefono" && returnVal.idChatbot ){
              clear();
            }
            break;
          case "delete":
            if( stateVar === "telefono" && returnVal ){
              clear();
            }
            break;
          default:
            break;
        }
      }).catch(err =>{ console.log("Error desconocido ....");  })
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h3>Acontinuacion se listan los chatbots de la plataforma { plataforma.nombre }</h3>
          <div className="form-group">
            <label for="chatbot">Chatbot</label>
            <Select
              value={chatbot}
              onChange={(event) => setChatbot(event)}
              options={chatbots}
            />
            <small id="chatbotHelp" className="form-text text-muted">Selecciona un chatbot.</small>
          </div>
        </div>
      </div>
      { chatbot.value ?
        <hr
          style={{
            height: "2px",
            borderWidth: "0",
            color: "gray",
            backgroundColor: "gray"
            }}
        ></hr>
        : null
      }
      { chatbot.value ?
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label for="telefono">Agregar télefono</label>
              <input type="number" className="form-control" id="telefono"
                placeholder="Ingresa telefono"
                value={telefono && telefono.telefono? telefono.telefono: "" }
                onChange={(e)=>{
                  if(e.target.value.length <= 10){
                    setTelefono({...telefono, telefono: e.target.value });
                  }
                }}
              />
              <small id="telefonoHelp" className="form-text text-muted">Ingresa el numero de telefono</small>
            </div>
          </div>
          <div className="col-2">
            <span>
              { telefono.telefono?
                <span className="btn fa fa-close float-right p-5 text-danger"
                      style={{ fontSize: "20px" }}
                      onClick={()=> clear()}>
                </span>
                : null
              }
              <span className={"btn fa fa-plus float-right p-5 text-"+(telefono.telefono && telefono.telefono.length === 10?"primary": "secondary")}
                data-toggle="tooltip"
                data-placement="top"
                title={telefono.telefono && telefono.telefono.length === 10? "Guardar": "El numero de telefono debe tener 10 digitos"}
                style={{ fontSize: "20px" }}
                onClick={()=>{
                  if(telefono.telefono && telefono.telefono.length === 10){
                    getfuntion("post", telefono, "", "chatbot_skyangel_telefonos", "telefono");
                  }
                }}
              >
              </span>
            </span>
          </div>
        </div>
        : null
      }
      { chatbot.value ?
        <hr
          style={{
            height: "1px",
            borderWidth: "0",
            color: "gray",
            backgroundColor: "gray"
            }}
        ></hr>
        : null
      }
      { chatbot.value ?
        <div className="row mt-4">          
          <div className="col">
            <TablePageable
              titulo={ <h5>Los siguientes numeros de telefono no participaran en el chatbot {chatbot.label}</h5> }
              lista= { telefonos }
              columnas={
                [
                  { columna: "telefono", label: "Télefono" },
                  { columna: "delete", label: "Eliminar",
                    form: (e)=>{
                      return <button className="btn btn-danger"
                                onClick={()=>{console.log(e);
                                  getfuntion("delete", "", e.idChatbot+"/"+e.telefono, "chatbot_skyangel_telefonos", "telefono");
                                  }}>Eliminar
                            </button> }
                  }
                ]
              }
              id= "telefono"
              //selected={(e)=>{ setTelefono(e) }}
              //csvname= {"Telefonos "+ (chatbot.data?nombre: "")}
              //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
              //resaltarColor={"rgba(223, 58, 58, 0.47)"}
            />
          </div>
        </div>
        : null
      }
    </div>
  )
}

export default NumerosInternos
