import React from "react";
import * as ACTIONS from "../store/actions/actions";
import { connect } from "react-redux";
import ModalLay from "./ModalLay/ModalLay";
import { Crud_Catalogos, Crud_error } from "../herramientas/Crud_Catalogos";
import {
  Form,
  Grid,
  Card,
  Table,
  Alert,
  Icon,
  Dimmer,
  Button,
} from "tabler-react";
import {
  ImportarMT,
  ImportarT,
  Exportar,
  Plantilla,
  Terminados,
  Activados,
} from "../herramientas/Excel";
import Select from "react-select";
import EntregaFrom from "../Monitoristas/Monitoreo/panelForms/Entregas/EntregaForm";
import MonitoreoMensajeria from "../socket/MonitoreoMensajeria.react";
import IncompletosTerminados from "./IncompletosTerminados";
import { timeToMilisegundos, timeToDatefull, timeToTimeFull } from "../herramientas/DateFormat";
import MovimientoIncidenciasTerrestre from "../Monitoristas/Monitoreo/MovimientoIncidenciasTerrestre";
import IncidenciasTraxion from "../Monitoristas/Monitoreo/IncidenciasTraxion";
import classes from "./Especifico.module.css";
import { MovsTerrToObjectsList } from "./MovsTerrestresToObjects";
var user = JSON.parse(sessionStorage.getItem("usuario"));
var currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
function myFormato(date) {
  function pad(number) {
    if (number < 10) {
      return "0" + number;
    }
    return number;
  }
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    " " +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds())
  );
}
let movimientosCopy= [];
class Catalogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elementos: this.props.elementos || [],
      tableheaderItems: [],
      test: this.categorias_object,
      hiddenModals: false, //Cierra o No el Modal
      readyData: false,
      message: "",
      ErrorMessage: [],
      showErrormessage: false,
      selectedRow: -1,
      currentMovimiento: null,
      alertasT: {},
      gpsT: {},
      plataforma: { value: 0, label: "Todas ...", search: "" },
      funcionalidadesCol: [],
      search: "",
      metodo: "get",
      colToFilter: "origen", //columna a filtrar
      AscDesc: true,
      topics: [],
      Navigator: false,
      movActToExpor: [],
    };
    this.usuariosLogObject = {
      idBitacoraMonitoreo: 0,
      idUsuario: 0,
      idMovimiento: 0,
      accionFecha: "",
      descripcion: "",
      idPlataformaTipo: 2,
    };
    this.colToFilter = "origen";
    this.AscDesc = true;
    this.ErrorMessage = "";
    this.ErrorMessageArray = [];
    this.catalogo = this.props.Ctlogo;
    this.catalogoTem = "";
    this.Catalogo_form = this.props.FCtlogo;
    this.tamanio = this.props.tamanio;
    this.getfuntion = this.getfuntion.bind(this);
    this.content = [];
    //this.colum = this.props.colum | [];
    this.columna = [];
    this.fila = [];
    this.get_Store_Elements = null;
    this.update_Store_Elements = null;
    //this.hiddenmodal = this.hiddenmodal.bind(this);
    this.getMensage = false;
    this.movimientoudated = true;
    this.plataformas = [];
    this.mensaje = {
      mensaje: "",
      plataforma: "",
      corredor: "",
      vista: "",
      data: null,
      accion: "get",
    };
  }

  hideNav = () => {
    this.setState((prev) => ({
      Navigator: !prev.Navigator,
    }));
  };

  estaPrivilegioActivo = (clave) => {
    var privilegios = this.props.privilegios_object;
    var perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (privilegios[i].clave === clave) {
        if (
          perfil.privilegios.filter(
            (word) => word.idPrivilegio == privilegios[i].id
          ).length > 0
        ) {
          return true;
        } else {
          return false;
        }
        break;
      }
    }
  };

  filter(event) {
    var text = event.target.value;
    const data = this.state.productoBackup;
    const newData = data.filter(function (item) {
      const itemDataTitle = item.titulo.toUpperCase();
      const itemDataDescp = item.descripcion.toUpperCase();
      const campo = itemDataTitle + " " + itemDataDescp;
      const textData = text.toUpperCase();
      return campo.indexOf(textData) > -1;
    });
    this.setState({
      producto: newData,
      text: text,
    });
  }

  estiloRenglon(elemento) {
    if (this.state.currentMovimiento) {
      if (this.state.currentMovimiento.id === elemento.id) {
        return { "backgroundColor": "rgba(95, 231, 50, 0.30)" };
      }
    }
    if (elemento) {
      if( [22, 25].includes(elemento.plataforma.id) ){//oilsa
        //operacion tipo
        /*
          48	ADAMA
          49	ORION ELECTROMATERIALS
          20	DOW QUIMICA MEXICANA
          21	CORTEVA AGRISCIENCE
          22	DOW CORNING
          43	DUPONT
          205	AXALTA
          304	SYNGENTA
        */
        if ( [48,49,20,21,22,43,205,304].includes(elemento.operacionTipo.id) ) {
          let tiempo1= new Date( timeToDatefull(new Date())+ " 20:00:00" ).getTime();
          let tiempo2= new Date( timeToDatefull(new Date())+ " 05:00:00" ).getTime();
          if( !elemento.deRegreso
            && new Date().getTime() > tiempo1
            || new Date().getTime() < tiempo2
            ){
            return { "backgroundColor": "rgba(250, 225, 5, 0.30)" };
          }
        }
      }
      if (elemento.deRegreso == 1) {
        return { "backgroundColor": "rgba(100, 233, 231, 0.30)" };
      }
      if (elemento.custodia == 1) {
        return { "backgroundColor": "rgba(220, 133, 231, 0.30)" };
      }

      return;
    }
  }

  componentDidUpdate = () => {
    if (this.props.idToFilter) {
      this.sortElements("id");
      this.setState({ search: this.props.idToFilter.toString() });
      this.props.changeColToFilter(0);
    }
    if (this.props.alertasT !== this.state.alertasT) {
      this.setState({ alertasT: this.props.alertasT });
    }
    if (this.props.gpsTerrestreReciente !== this.state.gpsT) {
      this.setState({ gpsT: this.props.gpsTerrestreReciente });
    }
    if (this.state.funcionalidadesCol !== this.props.columnas) {
      this.setState({ funcionalidadesCol: this.props.columnas });
      this.table_headers(true);
    }
    if (this.usuariosCorredores !== this.props.usuariosCorredores) {
      this.usuariosCorredores = this.props.usuariosCorredores;
      var topics = [];
      for (let item of this.props.usuariosCorredores) {
        topics.push(
          "/topic/" + item.idPlataforma + "-" + item.idOperacionCliente
        );
      }
      this.setState({ topics: topics });
    }
    if( movimientosCopy !== this.state.elementos ){
      movimientosCopy = this.state.elementos;
      var dataTemp = [];
      if (this.state.elementos) {
        this.state.elementos.map((filas, index) => {
          Object.keys(filas).forEach((key) => {
            if (!dataTemp[index]) {
              dataTemp[index] = {};
            }
            if (key !== "movimientoGps") {
              dataTemp[index][key] = [];
            }
            if (filas[key] !== null) {
              if (typeof filas[key] === "object") {
                switch (key) {
                  case "creationDate":
                    dataTemp[index][key] = timeToDatefull(new Date(filas[key].creationDate).getTime()).toString();
                    break;
                  case "persona":
                    dataTemp[index][key] =
                      "" +
                      filas[key].nombre +
                      " " +
                      filas[key].aPaterno +
                      " " +
                      filas[key].aMaterno;
                    break;
                  case "movimientoGps":
                    filas[key].map((gps, indice) =>{
                        dataTemp[index]["gps-" + gps.gpsTipo.nombre] = gps.gps.nombre;
                        dataTemp[index]["gps(Username)-" + gps.gpsTipo.nombre] = gps.gps.nombreUsuario;
                    });
                    break;
                  default:
                    if (filas[key].nombre !== "" && filas[key].nombre) {
                      dataTemp[index][key] = filas[key].nombre;
                    } else {
                      if (filas[key].id !== "" && filas[key].id) {
                        dataTemp[index][key] = filas[key].id;
                      } else {
                        dataTemp[index][key] = "Vari(@)s ";
                      }
                    }
                }
              } else {
                dataTemp[index][key] = filas[key];
              }
            } else {
              dataTemp[index][key] = "";
            }
          });
        });
        this.setState({ movActToExpor: dataTemp });
      }
    }
  };
  updateMessage = (sms) => {
    this.setState({ message: sms });
  };
  componentDidMount = () =>{
    this.getCatalogos();
    /*
      var topics = [];
      for (let item of this.props.usuariosCorredores) {
        topics.push("/topic/" + item.idPlataforma + "-" + item.idOperacionCliente);
      }
      this.setState({ topics: topics });
    */
  };
  getCatalogos =async()=>{
    let catalogosArray= [];
    Object.keys(this.props.catalogos).forEach((key) => catalogosArray.push(this.props.catalogos[key]));
    if (this.catalogo !== "" || this.catalogo !== null) {
      for(let c of catalogosArray){
        switch (c) {
          case "terrestreMovimientos":
            this.update_Store_Elements = this.updateStatus_elementos;//this.updateStatus_elementos;     this.props.movimientos_terrestres_update          
            if (currentUser[0].perfil.movimientosBy) {
              switch (currentUser[0].perfil.movimientosBy) {
                case 2:
                  await this.getfuntion(
                    "get",
                    [],
                    "",
                    [],
                    c + "/get_activos_usuario_corredores_test/" + user.id,
                    this.update_Store_Elements
                  );
                  break;
                case 3:
                  await this.getfuntion(
                    "get",
                    [],
                    "",
                    [],
                    c + "/transportistas/" + user.id,
                    this.update_Store_Elements
                  );
                  break;
                default:
                  await this.getfuntion(
                    "get",
                    [],
                    "",
                    [],
                    c + "/usuario/" + user.id,
                    this.update_Store_Elements
                  );
                  break;
              }
            } else {
              await this.getfuntion(
                "get",
                [],
                "",
                [],
                c + "/usuario/" + user.id,
                this.update_Store_Elements
              );
            }
            break;
          case "clientes": {
            this.update_Store_Elements = this.props.clientes_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              "catalogos/" + c,
              this.update_Store_Elements
            );
            break;
          }
          case "movimientosTipo": {
            this.update_Store_Elements = this.props.movimientosTipo_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "navieras": {
            this.update_Store_Elements = this.props.navieras_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "transportistas": {
            this.update_Store_Elements = this.props.transportistas_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
          }
            break;
          case "modalidades": {
            this.update_Store_Elements = this.props.modalidades_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "origenCatalogoMabe": {
            this.update_Store_Elements = this.props.origenCatalogoMabe_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "otd": {
            this.update_Store_Elements = this.props.otd_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "plataformas": {
            this.update_Store_Elements = this.props.plataformas_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "rutasEstatus": {
            this.update_Store_Elements = this.props.rutasEstatus_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "corredores": {
            this.update_Store_Elements = this.props.corredores_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "funcionalidades": {
            this.update_Store_Elements = this.props.funcionalidades_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "privilegios": {
            this.update_Store_Elements = this.props.privilegios_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }

          case "operacionesClientes": {
            this.update_Store_Elements = this.props.operacionesClientes_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "operacionesEstatus": {
            this.update_Store_Elements = this.props.operacionesEstatus_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "operacionesTipo": {
            this.update_Store_Elements = this.props.operacionesTipo_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "operadores": {
            this.update_Store_Elements = this.props.operadores_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
          }
            break;
          case "unidades": {
            this.update_Store_Elements = this.props.unidades_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }
          case "incidencias": {
            this.update_Store_Elements = this.props.incidencias_update;
            await this.getfuntion(
              "get",
              [],
              "",
              [],
              c,
              this.update_Store_Elements
            );
            break;
          }

          default:
            return;
        }
      }
    }
    this.plataformas.push({ value: 0, label: "Todas ...", search: "" });
    currentUser.map((usr, index) => {
      if (usr.plataforma.plataformaTipo.nombre.toUpperCase() === "TERRESTRE") {
        this.plataformas.push({
          value: usr.plataforma.id,
          label: usr.plataforma.nombre,
          search: usr.plataforma.nombre,
        });
      }
    });
  }
  hiddenmodal = () => {
    this.setState({ hiddenModals: !this.state.hiddenModals });
  };
  getfuntion = (
    metodo = "get",
    params = [],
    id = "",
    arraytoUpdate = [],
    catalogoTem = this.catalogo,
    storeFunction = this.update_Store_Elements
  ) => {
    //let idsTemp = JSON.parse("{"+ params +"}");
    this.usuariosLogObject.idPlataformaTipo = 2;
    let objTemp = params;
    if (catalogoTem !== "") {
      return Crud_Catalogos(
        catalogoTem,
        this.catalogo,
        metodo,
        id,
        params,
        storeFunction,
        this.state.elementos,
        this.hiddenmodal,
        arraytoUpdate
      )
        .then((returnVal) => {
        //this.updateStatus_elementos();
          /*
        this.mensaje.accion= metodo
        this.mensaje.vista= catalogoTem;*/
          this.setState({ metodo: metodo });
          switch (metodo) {
            case "get":
              {
                if (catalogoTem === this.catalogo + "/usuario/" + user.id
                  || catalogoTem === this.catalogo + "/get_activos_usuario_corredores_test/" + user.id) {
                  //this.table_headers();
                  this.setState({ readyData: true });
                }
                if (
                  catalogoTem ===
                  this.catalogo + "/transportistas/" + user.id
                ) {
                  //this.table_headers();
                  this.setState({ readyData: true });
                }
                if (
                  catalogoTem.includes("terrestreMovimientos/cv/") &&
                  returnVal.id
                ) {
                  let mTemp = this.state.elementos;
                  mTemp.push(returnVal);
                  this.setState({ elementos: mTemp });
                }
              }
              break;
            case "post": {
              /*
              this.mensaje.data= returnVal;
              this.mensaje.corredor= returnVal.corredor.id;
              this.mensaje.plataforma= returnVal.plataforma.id;
              this.mensaje.mensaje = "Nuevo Movimiento Terrestre ("+returnVal.id+", plataforma: "+returnVal.plataforma.nombre+")";
              */
              //this.props.updateStatusMensaje(true);
              this.usuariosLogObject.idBitacoraMonitoreo = 1;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 2;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Nuevo Movimiento Terrestre Post id=" + returnVal.id;
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject,
                "",
                "",
                this.hiddenmodal,
                ""
              );
              break;
            }
            case "put": {
              /*
              this.mensaje.data= returnVal.data;
              this.mensaje.corredor= returnVal.data.corredor.id;
              this.mensaje.plataforma= returnVal.data.plataforma.id;
              this.mensaje.mensaje = "Actualización ("+returnVal.data.id+", plataforma "+returnVal.data.plataforma.nombre+")";
              */
              //this.props.updateStatusMensaje(true);
              this.usuariosLogObject.idBitacoraMonitoreo = 2;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.idMovimiento = objTemp.id; //returnVal.id;
              this.usuariosLogObject.idPlataformaTipo = 2;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Actualizacion de Movimiento Terrestre Put id:" + objTemp.id; //returnVal.id;
              if (objTemp.operacionEstatus.id === 2) {
                this.usuariosLogObject.idBitacoraMonitoreo = 3;
                this.usuariosLogObject.descripcion =
                  "Actualizacion de Movimiento Terrestre Put id:" +
                  objTemp.id +
                  " Editar Movimiento";
              }
              if (objTemp.operacionEstatus.id === 3) {
                this.usuariosLogObject.idBitacoraMonitoreo = 3;
                this.usuariosLogObject.descripcion =
                  "Actualizacion de Movimiento Terrestre Put id:" +
                  objTemp.id +
                  " Actualizacion de Horario";
              }
              if (objTemp.operacionEstatus.id === 4) {
                this.usuariosLogObject.idBitacoraMonitoreo = 4;
                this.usuariosLogObject.descripcion =
                  "Actualizacion de Movimiento Terrestre Put id:" +
                  objTemp.id +
                  " Atender Alerta";
              }
              //if (returnVal.idOperacionEstatus = 5) {
              if (objTemp.operacionEstatus.id === 5) {
                this.usuariosLogObject.idBitacoraMonitoreo = 5;
                this.usuariosLogObject.descripcion =
                  "Actualizacion de Movimiento Terrestre Put id:" +
                  objTemp.id +
                  " Movimiento Finalizado";
              }
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject,
                "",
                "",
                this.hiddenmodal,
                ""
              );
              break;
            }
            case "delete": {
              this.usuariosLogObject.idBitacoraMonitoreo = 10;
              this.usuariosLogObject.idUsuario = user.id;
              this.usuariosLogObject.accionFecha = myFormato(new Date());
              this.usuariosLogObject.descripcion =
                "Delete Movimiento Terrestre: id:" + objTemp.id;
              Crud_Catalogos(
                "usuariosLog",
                this.catalogo,
                "post",
                "",
                this.usuariosLogObject,
                "",
                "",
                this.hiddenmodal,
                ""
              );
              break;
            }
            default:
              return;
          }
        })
        .catch((err) => {
          console.log("Error: " + err);
          if (err.response) {
            this.setState({ readyData: false });
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(err.response.status, catalogoTem);
            this.ErrorMessageArray.push(
              <Alert type="danger" icon="alert-octagon">
                {" "}
                {this.ErrorMessage}{" "}
              </Alert>
            );
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          } else {
            this.setState({ readyData: false });
            this.setState({ showErrormessage: true });
            this.ErrorMessage = Crud_error(404, catalogoTem);
            this.ErrorMessageArray.push(
              <Alert type="danger" icon="alert-octagon">
                {" "}
                {this.ErrorMessage}{" "}
              </Alert>
            );
            this.setState({ ErrorMessage: this.ErrorMessageArray });
          }
        });
    }
  };
  updateStatus_elementos = (data) => {
    /*this.setState({ elementos: this.props.movimientos_terrestres_object });
    this.props.setMovimientosTerrestres(
      this.props.movimientos_terrestres_object
    );
    */
   if(data && data.length && data[0].id_movimiento){
      (async () => {
        const movimientosTemp= await MovsTerrToObjectsList(data, this.props);
        this.setState({ elementos: [...movimientosTemp]});
        this.props.movimientos_terrestres_update([...movimientosTemp]);      
        this.props.setMovimientosTerrestres( [...movimientosTemp] );
        //console.log(await getData())
        this.update_Store_Elements = this.props.movimientos_terrestres_update;//this.updateStatus_elementos;     this.props.movimientos_terrestres_update
      })();      
   }else{
    if(data && data.length){
      this.setState({ elementos: data });
      this.props.movimientos_terrestres_update(data);      
      this.props.setMovimientosTerrestres( data );
    }else{
      this.setState({ elementos: this.props.movimientos_terrestres_object });
      this.props.setMovimientosTerrestres( this.props.movimientos_terrestres_object );
    }
   }
  };
  table_headers = (asc) => {
    this.content = [];
    this.props.columnas.map((columna, index) => {
      this.content.push({
        content: (
          <div onClick={() => this.sortElements(columna.nombre)}>
            {columna.label}
            {this.colToFilter === this.props.columnas[index].nombre ? (
              <Icon name={asc ? "chevron-up" : "chevron-down"} />
            ) : null}
          </div>
        ),
      });
    });
    this.setState({ tableheaderItems: this.content });
  };
  isobject = () => {
    if (typeof this.columna === "object") {
      if (this.columna.length > 1) {
        for (let item of this.columna) {
          this.colum.push(
            <Table.Col>
              <div className="row" style={{ "fontSize": "80%" }}>
                {
                  <ModalLay
                    tamanio={this.tamanio}
                    title="Modificar"
                    accion="modificar"
                    name={item["nombre"] + " ..."}
                    formulario={this.Catalogo_form}
                    datosForm={{
                      accion: "modificar",
                      elementos: this.fila,
                      getfuntion: this.getfuntion,
                      stor: this.props,
                    }}
                  />
                }
              </div>
            </Table.Col>
          );
          break;
        }
      } else {
        if (this.columna.length === 1) {
          for (let item of this.columna) {
            this.colum.push(
              <Table.Col>
                <div className="row" style={{ "fontSize": "20%" }}>
                  {item["nombre"]}
                </div>
              </Table.Col>
            );
            break;
          }
        } else {
          switch (this.campo) {
            case "operador": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "80%" }}>
                    {this.columna["nombre"] +
                      " " +
                      this.columna["paterno"] +
                      " " +
                      this.columna["materno"]}
                    <br></br>
                    {this.columna["medioComunicacion"]}
                  </div>
                </Table.Col>
              );
              break;
            }
            case "nombreIncidencia": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "60%" }}>
                    {this.columna["nombreIncidencia"]}
                  </div>
                </Table.Col>
              );
              break;
            }
            case "ultimaIncidencia": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "80%" }}>
                    {this.columna["ultimaIncidencia"]}
                  </div>
                </Table.Col>
              );
              break;
            }
            case "nombreIncidencia": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "60%" }}>
                    {this.columna["nombreIncidencia"]}
                  </div>
                </Table.Col>
              );
              break;
            }
            case "idUltimaEntrega": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "80%" }}>
                    {this.columna["idUltimaEntrega"]}
                  </div>
                </Table.Col>
              );
              break;
            }
            case "fechaEntrega": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "80%" }}>
                    {this.columna["fechaEntrega"]}
                  </div>
                </Table.Col>
              );
              break;
            }
            case "ultimaEntrega": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "80%" }}>
                    {this.columna["ultimaEntrega"]}
                  </div>
                </Table.Col>
              );
              break;
            }
            case "corredor": {
              this.colum.push(
                <Table.Col>
                  <div className="row" style={this.columna["nombre"]=="Servicio Delicado"?{"fontSize":"80%",backgroundColor: 'yellow',color:"red"}:{"fontSize": "80%"}}>
                    {this.columna["nombre"]}
                  </div>
                </Table.Col>
              );
              break;
            }

            default:
              //this.colum.push(<Table.Col>{elemento[columna.columna]}</Table.Col>)
              this.colum.push(
                <Table.Col>
                  <div className="row" style={{ "fontSize": "80%" }}>
                    {this.columna["nombre"]
                      ? this.columna["nombre"]
                      : this.columna["username"]}
                  </div>
                </Table.Col>
              );
              break;
          }
        }
      }
    }
  };

  searching = (event) => {
    this.setState({ search: event.target.value });
    if (
      this.colToFilter === "cartaPorte" &&
      this.state.elementos.filter((m) =>
        m.cartaPorte.includes(event.target.value)
      ).length === 0
    ) {
      this.getfuntion(
        "get",
        [],
        "",
        [],
        "terrestreMovimientos/cv/" + event.target.value,
        ""
      );
    }
  };
  changePlataformas = (event) => {
    this.setState({ plataforma: event });
  };
  sortElements = (columna) => {
    let oldCol = this.colToFilter;
    if (this.colToFilter == columna) {
      this.AscDesc = !this.AscDesc;
    } else {
      this.AscDesc = true;
    }
    this.colToFilter = columna;
    this.setState({ colToFilter: columna });
    if (oldCol == columna) {
      this.table_headers(this.AscDesc);
    } else {
      this.table_headers(true);
    }
    var originalArray = this.state.elementos;
    if (!originalArray[0]) {
      return;
    }
    if (this.AscDesc) {
      originalArray = originalArray.sort(function (a, b) {
        if (
          typeof a[columna] === "object" &&
          typeof b[columna] === "object" &&
          a[columna] !== null &&
          b[columna] !== null
        ) {
          if (a[columna] === null || b[columna] === null) {
            return 1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre > b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre < b[columna].nombre) {
            return -1;
          }
          return 0;
        } else {
          if (a[columna] === null) {
            return -1;
          }
          if (a[columna] > b[columna]) {
            return 1;
          }
          if (a[columna] < b[columna]) {
            return -1;
          }
          return 0;
        }
      });
      //return a[columna]-b[columna] }); //Asecendente
    } else {
      if (typeof originalArray[0][columna] == "object") {
        originalArray = originalArray.sort(function (a, b) {
          if (a[columna] === null || b[columna] === null) {
            return -1;
          } // si es nulo lo ordena al final
          if (a[columna].nombre < b[columna].nombre) {
            return 1;
          }
          if (a[columna].nombre > b[columna].nombre) {
            return -1;
          }
          return 0;
        });
      } else {
        originalArray = originalArray.sort(function (a, b) {
          if (a[columna] === null) {
            return -1;
          }
          if (a[columna] < b[columna]) {
            return 1;
          }
          if (a[columna] > b[columna]) {
            return -1;
          }
          return 0;
        });
      }
      //return b[columna]-a[columna] }); //Descendente
    }
    this.setState({ elementos: originalArray });
  };
  objectFilter = (columna) => {
    if (columna) {
      if (columna.nombre) {
        return columna.nombre;
      } else {
        return columna;
      }
    } else {
      return "";
    }
  };
  getFuncionalidad = (elemento) => {
    //if (elemento != "Importar"){
    let movActivo = null;
    if (this.state.elementos) {
      movActivo = this.state.elementos
        .filter(
          (word) =>
            word.plataforma.nombre.includes(this.state.plataforma.search) &&
            (!Number.isInteger(word[this.state.colToFilter]) //Si se filtra texto
              ? this.objectFilter(word[this.state.colToFilter])
                  .toUpperCase()
                  .includes(this.state.search.toUpperCase())
              : this.state.search != ""
              ? parseInt(word[this.state.colToFilter], 10) ===
                parseInt(this.state.search, 10)
                ? true
                : false //Si se filtra numero o boolean
              : true)
        )
        .sort()[this.state.selectedRow];
    }
    if (movActivo) {
      if (movActivo.plataforma) {
        if (this.props.PlataformasPerfiles[movActivo.plataforma.id]) {
          let funcionalidadesTemp =
            this.props.PlataformasPerfiles[movActivo.plataforma.id]
              .funcionalidades;
          if (!funcionalidadesTemp) {
            return false;
          }
          for (let item of funcionalidadesTemp) {
            if (item.nombre === elemento) {
              return true;
            }
          }
        }
      }
    }

    //}

    return false;
  };
  render() {
    return (
      <Grid.Col lg={12}>
        <div className="m-3" hidden={this.state.elementos.length}>
          { this.state.elementos.length?
            <MonitoreoMensajeria
              topic={this.state.topics}
              mensaje={this.mensaje}
              elementosT={this.state.elementos}
              storeFunction={this.update_Store_Elements}
              updateT={this.updateStatus_elementos}
              openmodal={this.state.hiddenModals}
              metodo={this.state.metodo}
              stor={this.props}
            />
          :null}
        </div>
        <i
          className={`${classes.icon} ${classes.static} ${
            this.state.Navigator ? "fa fa-angle-down" : "fa fa-angle-up"
          }`}
          style={{ cursor: "pointer" }}
          onClick={this.hideNav}
        />
        {!this.state.Navigator && (
          <div
            className={`form-row align-items-center ${classes.jumb}`}
            style={{ marginRight: "0px", marginLeft: "0px" }}
          >
            <div className="col-2">
              <Select
                value={this.state.plataforma}
                onChange={this.changePlataformas}
                options={this.plataformas}
              />
            </div>
            <div className="col-auto">
              <Form.Input
                className="lg-12"
                icon="search"
                value={this.state.search}
                placeholder={this.state.colToFilter}
                position="append"
                onChange={this.searching}
              />
            </div>
            {this.estaPrivilegioActivo("CargaMasiva") &&
              this.getFuncionalidad("Importar") && (
                <div className="col-auto">
                  <ImportarMT crud={this.getfuntion} catalogo={this.catalogo} />
                </div>
              )}
            {this.estaPrivilegioActivo("CargaMasiva") &&
              this.getFuncionalidad("Importar") && (
                <div className="col-auto">
                  <ImportarT crud={this.getfuntion} catalogo={this.catalogo} />
                </div>
              )}
            {this.getFuncionalidad("Importar") && (
              <div className="col-auto">
                <Plantilla crud={this.getfuntion} catalogo={this.catalogo} />
              </div>
            )}
            {this.getFuncionalidad("Exportar") && (
              <div className="col-auto">
                <Exportar
                  crud={this.getfuntion}
                  catalogo={this.catalogo}
                  data={this.state.movActToExpor}
                />
              </div>
            )}
            {this.getFuncionalidad("Movimientos Incompletos") && (
              <div className="col-auto">
                <Terminados
                  crud={this.getfuntion}
                  catalogo={this.catalogo}
                  data={this.state.elementos}
                  filtro={user}
                />
              </div>
            )}
            {this.getFuncionalidad("Movimientos Incompletos") && (
              <div className="col-auto">
                <Activados
                  crud={this.getfuntion}
                  catalogo={this.catalogo}
                  data={this.state.elementos}
                  filtro={user}
                />
              </div>
            )}
            {this.getFuncionalidad("Movimientos Completos") && (
              <li className="nav-item active">
                <a>
                  <ModalLay
                    tamanio={"85%"}
                    title="Terminados"
                    name={
                      <Button pill size="sm" outline color="primary">
                        <Icon name="maximize-2" />
                      </Button>
                    }
                    formulario={IncompletosTerminados}
                    datosForm={{
                      columnas: this.state.funcionalidadesCol,
                      elementos: this.state.elementos,
                      closeModal: this.hiddenmodal,
                      plataformaTipo: "Terrestre",
                    }}
                  />
                </a>
              </li>
            )}
            {this.estaPrivilegioActivo("movimientos_agregar") && (
              <li className="nav-item active">
                <a>
                  <ModalLay
                    resumirModal={this.props.resumeModal}
                    funcionReanudar={this.props.functionReanudarModal}
                    title="Nuevo"
                    name={
                      <Button pill size="sm" outline color="primary">
                        <Icon name="plus" />
                      </Button>
                    }
                    tamanio={this.tamanio}
                    formulario={this.Catalogo_form}
                    datosForm={{
                      accion: "nuevo",
                      elementos: this.state.elementos[0],
                      getfuntion: this.getfuntion,
                      stor: this.props,
                    }}
                  />
                </a>
              </li>
            )}
          </div>
        )}

        <Card>
          <Card.Body>
            <Dimmer active={!this.state.readyData} loader>
              { this.state.tableheaderItems.length?
                <Table
                  cards={true}
                  striped={false}
                  responsive={true}
                  className="table-vcenter"
                >
                  <Table.Header>
                    {this.state.tableheaderItems.map((c)=>  <Table.ColHeader>{c.content}</Table.ColHeader>)}
                  </Table.Header>
                  <Table.Body>
                    {this.state.elementos ? ( //.filter(word => word.po.toUpperCase().includes(this.state.search.toUpperCase()) || word.booking.toUpperCase().includes(this.state.search.toUpperCase()) || word.contenedor.toUpperCase().includes(this.state.search.toUpperCase())).sort()
                      this.state.elementos
                        .filter(
                          (word) =>
                            word.plataforma.nombre.includes(
                              this.state.plataforma.search
                            ) &&
                            (!Number.isInteger(word[this.state.colToFilter]) //Si se filtra texto
                              ? this.objectFilter(word[this.state.colToFilter])
                                  .toUpperCase()
                                  .includes(this.state.search.toUpperCase())
                              : this.state.search != ""
                              ? parseInt(word[this.state.colToFilter], 10) ===
                                parseInt(this.state.search, 10)
                                ? true
                                : false //Si se filtra numero o boolean
                              : true)
                        )
                        .sort()//.slice(0,100)
                        .map((elemento, index) => (
                          
                          <Table.Row
                            style={this.estiloRenglon(elemento)}
                            onClick={() => {
                              this.props.setMovimiento(elemento);
                              this.setState({ selectedRow: index });
                              this.setState({ currentMovimiento: elemento });
                            }}
                          >
                            {(this.colum = [])}
                            {this.state.funcionalidadesCol.map(
                              (columna, index) => {
                                if (
                                  typeof elemento[columna.columna] === "object" &&
                                  elemento[columna.columna] !== null
                                ) {
                                  
                                  this.columna = elemento[columna.columna];
                                  this.fila = elemento;
                                  this.campo = columna.columna;
                                  this.isobject();
                                } //Inicia No es Objeto
                                else {
                                  //Inicia Switch
                                  
                                  switch (columna.columna) {
                                    case "estatus":
                                      this.colum.push(
                                        <Table.Col>
                                          {
                                            <Form.Switch
                                              type="Checkbox"
                                              checked={elemento[columna.columna]}
                                            />
                                          }
                                        </Table.Col>
                                      );
                                      break;
                                    case "password":
                                      this.colum.push(
                                        <Table.Col>
                                          {
                                            <ModalLay
                                              title="Modificar"
                                              accion="modificar"
                                              name="*******"
                                              hiddenModal={
                                                this.state.hiddenModals
                                              }
                                              Fclose={this.hiddenmodal}
                                              formulario={this.Catalogo_form}
                                              datosForm={{
                                                accion: "modificar",
                                                elementos: elemento,
                                                getfuntion: this.getfuntion,
                                                stor: this.props,
                                              }}
                                            />
                                          }
                                        </Table.Col>
                                      );
                                      break;
                                    case "deRegreso":
                                      this.colum.push(
                                        <Table.Col>
                                          <Form.Switch
                                            type="Checkbox"
                                            name="toggle"
                                            value={elemento[columna.columna]}
                                            checked={
                                              elemento[columna.columna]
                                                ? true
                                                : false
                                            }
                                            className={"disabled"}
                                          />
                                        </Table.Col>
                                      );
                                      break;
                                    case "checklist":
                                      this.colum.push(
                                        <Table.Col>
                                          <Form.Switch
                                            type="Checkbox"
                                            name="toggle"
                                            value={elemento[columna.columna]}
                                            checked={
                                              elemento[columna.columna]
                                                ? true
                                                : false
                                            }
                                            className={"disabled"}
                                          />
                                        </Table.Col>
                                      );
                                      //this.colum.push(<Table.Col>{elemento[columna.columna]}</Table.Col>)
                                      break;
                                    case "custodia":
                                      this.colum.push(
                                        <Table.Col>
                                          <Form.Switch
                                            type="Checkbox"
                                            name="toggle"
                                            value={elemento[columna.columna]}
                                            checked={
                                              elemento[columna.columna]
                                                ? true
                                                : false
                                            }
                                            className={"disabled"}
                                          />
                                        </Table.Col>
                                      );
                                      break;

                                      case "operador_1": {
                                        this.colum.push(
                                          <Table.Col>
                                            <div className="row" >
                                              {JSON.parse(elemento.personalizados)?.operador_1}
                                            </div>
                                          </Table.Col>
                                        );
                                        break;
                                      }
                          
                                      case "operador_2": {
                                        this.colum.push(
                                          <Table.Col>
                                            <div className="row" >
                                              {JSON.parse(elemento.personalizados)?.operador_2}
                                            </div>
                                          </Table.Col>
                                        );
                                        break;
                                      }
                          
                                      case "observaciones": {
                                        this.colum.push(
                                          <Table.Col>
                                            <div className="row" >
                                              {JSON.parse(elemento.personalizados)?.observaciones}
                                            </div>
                                          </Table.Col>
                                        );
                                        break;
                                      }
                          
                                      case "observaciones_generales": {
                                        this.colum.push(
                                          <Table.Col>
                                            <div className="row" >
                                              {JSON.parse(elemento.personalizados)?.observaciones_generales}
                                            </div>
                                          </Table.Col>
                                        );
                                        break;
                                      }
                                    case "gps":
                                      {
                                        if (this.state.gpsT) {
                                          this.colum.push(
                                            <Table.Col>
                                              {this.state.gpsT[elemento.id] ? (
                                                <div>
                                                  <div
                                                    className="badge"
                                                    style={{
                                                      color:
                                                        this.state.gpsT[
                                                          elemento.id
                                                        ].iconBattery.color,
                                                      "fontSize": "80%",
                                                    }}
                                                  >
                                                    {
                                                      this.state.gpsT[elemento.id]
                                                        .nombre
                                                    }
                                                  </div>
                                                  <br></br>
                                                  <div
                                                    className="badge"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={
                                                      this.state.gpsT[elemento.id]
                                                        .nombre +
                                                      ", " +
                                                      this.state.gpsT[elemento.id]
                                                        .nivel_bateria +
                                                      " %"
                                                    }
                                                    style={{
                                                      color:
                                                        this.state.gpsT[
                                                          elemento.id
                                                        ].iconBattery.color,
                                                      "fontSize": "70%",
                                                    }}
                                                  >
                                                    <span
                                                      className={
                                                        this.state.gpsT[
                                                          elemento.id
                                                        ].iconBattery.icono
                                                      }
                                                    ></span>
                                                  </div>
                                                  <div
                                                    className="badge"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={
                                                      this.state.gpsT[elemento.id]
                                                        .ultimo_reporte
                                                    }
                                                    style={{
                                                      color:
                                                        this.state.gpsT[
                                                          elemento.id
                                                        ].iconTime.color,
                                                      "fontSize": "70%",
                                                    }}
                                                  >
                                                    <span
                                                      className={
                                                        this.state.gpsT[
                                                          elemento.id
                                                        ].iconTime.icono
                                                      }
                                                    ></span>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div>
                                                  {elemento.movimientoGps
                                                    ? elemento.movimientoGps.map(
                                                        (g, ind) => (
                                                          <span>
                                                            {" "}
                                                            {g.gps.nombre}
                                                          </span>
                                                        )
                                                      )
                                                    : ""}
                                                </div>
                                              )}
                                            </Table.Col>
                                          );
                                        } else {
                                          this.colum.push(
                                            <Table.Col>
                                              {elemento.movimientoGps
                                                ? elemento.movimientoGps.map(
                                                    (gpsTem, inc001) => (
                                                      <div>
                                                        {" "}
                                                        {gpsTem.gps.nombre}
                                                      </div>
                                                    )
                                                  )
                                                : ""}
                                            </Table.Col>
                                          );
                                        }
                                      }
                                      break;
                                    case "notificaciones":
                                      var nticacion = [];
                                      if (this.state.alertasT) {
                                        if (this.state.alertasT[elemento.id]) {
                                          let semaforo = 0; //0,1,
                                          Object.keys(
                                            this.state.alertasT[elemento.id]
                                          ).forEach((key, indice) => {
                                            if (
                                              this.state.alertasT[elemento.id][
                                                key
                                              ][0]
                                            ) {
                                              if (
                                                this.state.alertasT[elemento.id][
                                                  key
                                                ][0].tiempo
                                              ) {
                                                let timemil = timeToMilisegundos(
                                                  this.state.alertasT[
                                                    elemento.id
                                                  ][key][0].tiempo
                                                );
                                                if (timemil > 0) {
                                                  for (let alertas of this.state
                                                    .alertasT[elemento.id][key]) {
                                                    if (
                                                      new Date(
                                                        alertas.alert.fechaCreacion
                                                      ).getTime() +
                                                        timemil <
                                                      new Date().getTime()
                                                    ) {
                                                      semaforo = 2;
                                                    } else {
                                                      let diferencia =
                                                        new Date(
                                                          alertas.alert.fechaCreacion
                                                        ).getTime() -
                                                        new Date().getTime();
                                                      if (
                                                        timemil - diferencia <
                                                          2 &&
                                                        diferencia > 0 &&
                                                        semaforo < 2
                                                      ) {
                                                        semaforo = 1;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            this.state.alertasT[elemento.id][
                                              key
                                            ][0]
                                              ? nticacion.push(
                                                  <div
                                                    className="badge"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title={
                                                      this.state.alertasT[
                                                        elemento.id
                                                      ][key][0].tipo +
                                                      ", Tiempo de atención: " +
                                                      timeToMilisegundos(
                                                        this.state.alertasT[
                                                          elemento.id
                                                        ][key][0].tiempo
                                                      ) /
                                                        1000 /
                                                        60 +
                                                      " min"
                                                    }
                                                    style={{
                                                      color:
                                                        this.state.alertasT[
                                                          elemento.id
                                                        ][key][0].color,
                                                      "fontSize": "140%",
                                                    }}
                                                  >
                                                    <span className={"fa " + key}>
                                                      {
                                                        this.state.alertasT[
                                                          elemento.id
                                                        ][key].length
                                                      }
                                                    </span>
                                                  </div>
                                                )
                                              : console.log("");
                                          });
                                          this.colum.push(
                                            <Table.Col>
                                              <div
                                                className="row"
                                                style={{
                                                  "backgroundColor":
                                                    semaforo === 1
                                                      ? "rgba(228, 228, 98, 0.7)"
                                                      : semaforo === 2
                                                      ? "rgba(241, 111, 111, 0.67)"
                                                      : "rgba(73, 223, 67, 0.29)",
                                                }}
                                              >
                                                {
                                                  <div className="badge col">
                                                    {" "}
                                                    {nticacion}{" "}
                                                  </div>
                                                }
                                              </div>
                                            </Table.Col>
                                          );
                                        } else {
                                          this.colum.push(
                                            <Table.Col> </Table.Col>
                                          );
                                        }
                                      }
                                      break;
                                    case "alertas":
                                      var nticacion = [];
                                      if (this.state.alertasT) {
                                        if (this.state.alertasT[elemento.id]) {
                                          let semaforo = 0; //0,1,
                                          Object.keys(
                                            this.state.alertasT[elemento.id]
                                          ).forEach((key, indice) => {
                                            if (
                                              this.state.alertasT[elemento.id][
                                                key
                                              ][0]
                                            ) {
                                              if (
                                                this.state.alertasT[elemento.id][
                                                  key
                                                ][0].tiempo
                                              ) {
                                                let timemil = timeToMilisegundos(
                                                  this.state.alertasT[
                                                    elemento.id
                                                  ][key][0].tiempo
                                                );
                                                if (timemil > 0) {
                                                  for (let alertas of this.state
                                                    .alertasT[elemento.id][key]) {
                                                    if (
                                                      new Date(
                                                        alertas.alert.fechaCreacion
                                                      ).getTime() +
                                                        timemil <
                                                      new Date().getTime()
                                                    ) {
                                                      semaforo = 2;
                                                    } else {
                                                      let diferencia =
                                                        new Date(
                                                          alertas.alert.fechaCreacion
                                                        ).getTime() -
                                                        new Date().getTime();
                                                      if (
                                                        timemil - diferencia <
                                                          2 &&
                                                        diferencia > 0 &&
                                                        semaforo < 2
                                                      ) {
                                                        semaforo = 1;
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            if (
                                              this.state.alertasT[elemento.id][
                                                key
                                              ].filter(
                                                (word) => !word.alert.atendida
                                              ).length
                                            ) {
                                              nticacion.push(
                                                <div
                                                  className="badge"
                                                  data-toggle="tooltip"
                                                  data-placement="top"
                                                  title={
                                                    this.state.alertasT[
                                                      elemento.id
                                                    ][key][0].tipo +
                                                    ", Tiempo de atención: " +
                                                    timeToMilisegundos(
                                                      this.state.alertasT[
                                                        elemento.id
                                                      ][key][0].tiempo
                                                    ) /
                                                      1000 /
                                                      60 +
                                                    " min"
                                                  }
                                                  style={{
                                                    color:
                                                      this.state.alertasT[
                                                        elemento.id
                                                      ][key][0].color,
                                                    "fontSize": "140%",
                                                  }}
                                                >
                                                  <span className={"fa " + key}>
                                                    {
                                                      this.state.alertasT[
                                                        elemento.id
                                                      ][key].filter(
                                                        (word) =>
                                                          !word.alert.atendida
                                                      ).length
                                                    }
                                                  </span>
                                                </div>
                                              );
                                            }
                                          });
                                          if (nticacion.length) {
                                            this.colum.push(
                                              <Table.Col>
                                                <div
                                                  className="row"
                                                  style={{
                                                    "backgroundColor":
                                                      semaforo === 1
                                                        ? "rgba(228, 228, 98, 0.7)"
                                                        : semaforo === 2
                                                        ? "rgba(241, 111, 111, 0.67)"
                                                        : "rgba(73, 223, 67, 0.29)",
                                                  }}
                                                >
                                                  {
                                                    <div className="badge col">
                                                      {" "}
                                                      {nticacion}{" "}
                                                    </div>
                                                  }
                                                </div>
                                              </Table.Col>
                                            );
                                          } else {
                                            this.colum.push(
                                              <Table.Col> </Table.Col>
                                            );
                                          }
                                        } else {
                                          this.colum.push(
                                            <Table.Col> </Table.Col>
                                          );
                                        }
                                      } else {
                                        this.colum.push(<Table.Col> </Table.Col>);
                                      }
                                      break;
                                    case "editar":
                                      this.colum.push(
                                        <Table.Col>
                                          <ModalLay
                                            resumirModal={this.props.resumeModal}
                                            funcionReanudar={
                                              this.props.functionReanudarModal
                                            }
                                            title="Modificar"
                                            accion="modificar"
                                            //name="Editar"
                                            name={
                                              <div
                                                className="badge"
                                                style={{
                                                  color: "green",
                                                  "fontSize": "120%",
                                                }}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Editar movimiento"
                                              >
                                                {!this.props.resumeModal && (
                                                  <span className="fa fa-edit"></span>
                                                )}
                                              </div>
                                            }
                                            formulario={this.Catalogo_form}
                                            datosForm={{
                                              accion: "modificar",
                                              elementos: {...elemento},
                                              getfuntion: this.getfuntion,
                                              stor: this.props,
                                            }}
                                          />
                                        </Table.Col>
                                      );
                                      break;
                                    case "incidencias":
                                      let diferencia = 0;
                                      if (
                                        new Date().getTime() >
                                        new Date(
                                          elemento.ultimaIncidencia
                                        ).getTime()
                                      ) {
                                        diferencia =
                                          (new Date().getTime() -
                                            new Date(
                                              elemento.ultimaIncidencia
                                            ).getTime()) /
                                          1000 /
                                          60 /
                                          60;
                                      }
                                      this.colum.push(
                                        <Table.Col>
                                          <div
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title={elemento.comentarioIncidencia}
                                          >
                                            <div
                                              className="row"
                                              style={{ "fontSize": "80%" }}
                                            >
                                              {elemento.nombreIncidencia}
                                            </div>
                                            <div
                                              className="row"
                                              style={{
                                                "fontSize": "80%",
                                                "backgroundColor":
                                                  diferencia > 1
                                                    ? "rgba(241, 111, 111, 0.67)"
                                                    : "rgba(73, 223, 67, 0.29)",
                                              }}
                                            >
                                              {elemento.ultimaIncidencia}
                                            </div>
                                          </div>
                                          <ModalLay
                                            tamanio={"50%"}
                                            title="Nueva Incidencia"
                                            name={
                                              <Button
                                                pill
                                                size="sm"
                                                outline
                                                color="primary"
                                              >
                                                <Icon name="plus" />
                                              </Button>
                                            }
                                            formulario={
                                              [193].includes(
                                                elemento.plataforma.id
                                              ) //193 Es traxion
                                                ? IncidenciasTraxion
                                                : MovimientoIncidenciasTerrestre
                                            }
                                            datosForm={{
                                              accion: "nuevo",
                                              desde: "EspecificoTerrestre",
                                              idusuario: user.id,
                                              idmovimiento: elemento.id,
                                              idplataforma:
                                                elemento.plataforma.id,
                                              movimiento: [193].includes(
                                                elemento.plataforma.id
                                              )
                                                ? elemento
                                                : this.state.currentMovimiento,
                                              unidad: null, //recargar
                                              idEntregaActiva: null, //recargar
                                              entregas: null, //recargar
                                              getfuntion: this.getfuntion,
                                              stor: this.props,
                                              plataformaTipo: 2,
                                              gpsReciente: this.state.gpsT
                                                ? this.state.gpsT[elemento.id]
                                                  ? this.state.gpsT[elemento.id]
                                                  : null
                                                : null,
                                              latitud: null,
                                              longitud: null,
                                              closeModal: this.hiddenmodal,
                                            }}
                                          />
                                        </Table.Col>
                                      );
                                      break;
                                    case "nuevaIncidencia":
                                      this.colum.push(
                                        <Table.Col>
                                          <ModalLay
                                            tamanio={"50%"}
                                            title="Nueva Incidencia"
                                            name={
                                              <Button
                                                pill
                                                size="sm"
                                                outline
                                                color="primary"
                                              >
                                                <Icon name="plus" />
                                              </Button>
                                            }
                                            formulario={
                                              MovimientoIncidenciasTerrestre
                                            }
                                            datosForm={{
                                              accion: "nuevo",
                                              desde: "EspecificoTerrestre",
                                              idusuario: user.id,
                                              idmovimiento: elemento.id,
                                              idplataforma:
                                                elemento.plataforma.id,
                                              movimiento: elemento,
                                              stor: this.props,
                                              getfuntion: this.getfuntion,
                                              plataformaTipo: 2,
                                              gpsReciente: this.state.gpsT
                                                ? this.state.gpsT[elemento.id]
                                                  ? this.state.gpsT[elemento.id]
                                                  : null
                                                : null,
                                              closeModal: this.hiddenmodal,
                                            }}
                                          />
                                        </Table.Col>
                                      );
                                      break;
                                    case "nuevaEntrega":
                                      this.colum.push(
                                        <Table.Col>
                                          <ModalLay
                                            tamanio={"50%"}
                                            title="Nueva Entrega"
                                            name={
                                              <Button
                                                pill
                                                size="sm"
                                                outline
                                                color="primary"
                                              >
                                                <Icon name="plus" />
                                              </Button>
                                            }
                                          />
                                        </Table.Col>
                                      );
                                      break;
                                    case "entregas":
                                      this.colum.push(
                                        <Table.Col>
                                          <div
                                            className="row"
                                            style={{ "fontSize": "80%" }}
                                          >
                                            {elemento.ultimaEntrega}
                                          </div>
                                          <div
                                            className="row"
                                            style={{ "fontSize": "80%" }}
                                          >
                                            {elemento.fechaEntrega}
                                          </div>
                                          <ModalLay
                                            tamanio={"50%"}
                                            title="Nueva Entrega"
                                            name={
                                              <Button
                                                pill
                                                size="sm"
                                                outline
                                                color="primary"
                                              >
                                                <Icon name="plus" />
                                              </Button>
                                            }
                                            formulario={EntregaFrom}
                                            datosForm={{
                                              accion: "nuevo",
                                              idmovimiento: elemento.id,
                                              stor: this.props,
                                              getfuntion: this.getfuntion,
                                              closeModal: this.hiddenmodal,
                                            }}
                                          />
                                        </Table.Col>
                                      );
                                      break;
                                    default:
                                      this.colum.push(
                                        <Table.Col>
                                          {elemento[columna.columna]}
                                        </Table.Col>
                                      );
                                      break;
                                  }
                                  //Fin Switch
                                  //Fin No es Objeto
                                }
                              }
                            )}
                          </Table.Row>
                        ))
                    ) : (
                      <div>
                        <Table.Row>
                          <Table.Col> No hay Movimientos ..! </Table.Col>
                        </Table.Row>
                      </div>
                    )}
                  </Table.Body>
                </Table>: null
              }
            </Dimmer>
          </Card.Body>
        </Card>
        <div hidden={!this.state.showErrormessage}>
          {this.state.ErrorMessage}
        </div>
      </Grid.Col>
    );
  }
}
function mapStateToProps(state) {
  return {
    movimientos_naviera_object:
      state.catalogos_reducer.movimientos_naviera_object,
    movimientos_terrestres_object:
      state.catalogos_reducer.movimientos_terrestres_object,
    clientes_object: state.catalogos_reducer.clientes_object,
    movimientosTipo_object: state.catalogos_reducer.movimientosTipo_object,
    navieras_object: state.catalogos_reducer.navieras_object,
    operacionesClientes_object:
      state.catalogos_reducer.operacionesClientes_object,
    operacionesEstatus_object:
      state.catalogos_reducer.operacionesEstatus_object,
    operacionesTipo_object: state.catalogos_reducer.operacionesTipo_object,
    operadores_object: state.catalogos_reducer.operadores_object,
    transportistas_object: state.catalogos_reducer.transportistas_object,
    updateStatusMensaje_Object: state.catalogos_reducer.update_mensaje,
    otd_object: state.catalogos_reducer.otd_object,
    plataformas_object: state.catalogos_reducer.plataformas_object,
    rutasEstatus_object: state.catalogos_reducer.rutasEstatus_object,
    corredores_object: state.catalogos_reducer.corredores_object,
    funcionalidades_object: state.catalogos_reducer.funcionalidades_object,
    privilegios_object: state.catalogos_reducer.privilegios_object,
    unidades_object: state.catalogos_reducer.unidades_object,
    incidencias_object: state.catalogos_reducer.incidencias_object,
    modalidades_object: state.catalogos_reducer.modalidades_object,
    origenCatalogoMabe_object: state.catalogos_reducer.origenCatalogoMabe_object,
  };
}
function mapDispachToProps(dispach) {
  return {
    movimientos_naviera_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientos_naviera_imput(elementos)),
    movimientos_terrestres_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientos_terrestres_imput(elementos)),
    clientes_update: (elementos) =>
      dispach(ACTIONS.elementos_clientes_imput(elementos)),
    movimientosTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_movimientosTipo_imput(elementos)),
    navieras_update: (elementos) =>
      dispach(ACTIONS.elementos_navieras_imput(elementos)),
    operacionesClientes_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionesClientes_imput(elementos)),
    operacionesEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionesEstatus_imput(elementos)),
    operacionesTipo_update: (elementos) =>
      dispach(ACTIONS.elementos_operacionestipo_imput(elementos)),
    operadores_update: (elementos) =>
      dispach(ACTIONS.elementos_operadores_imput(elementos)),
    updateStatusMensaje: (status) => dispach(ACTIONS.update_mensaje(status)),
    transportistas_update: (elementos) =>
      dispach(ACTIONS.elementos_transportistas_imput(elementos)),
    otd_update: (elementos) => dispach(ACTIONS.elementos_otd_imput(elementos)),
    plataformas_update: (elementos) =>
      dispach(ACTIONS.elementos_plataformas_imput(elementos)),
    rutasEstatus_update: (elementos) =>
      dispach(ACTIONS.elementos_rutasEstatus_imput(elementos)),
    corredores_update: (elementos) =>
      dispach(ACTIONS.elementos_corredores_imput(elementos)),
    funcionalidades_update: (elementos) =>
      dispach(ACTIONS.elementos_funcionalidades_imput(elementos)),
    privilegios_update: (elementos) =>
      dispach(ACTIONS.elementos_privilegios_imput(elementos)),
    unidades_update: (elementos) =>
      dispach(ACTIONS.elementos_unidades_imput(elementos)),
    incidencias_update: (elementos) =>
      dispach(ACTIONS.elementos_incidencias_imput(elementos)),
    modalidades_update: (elementos) =>
      dispach(ACTIONS.elementos_modalidades_imput(elementos)),
    origenCatalogoMabe_update: (elementos) =>
      dispach(ACTIONS.elementos_origencatalogomabe_imput(elementos)),
  };
}
export default connect(mapStateToProps, mapDispachToProps)(Catalogo);
