import React, { useState, useEffect } from 'react';
import { FetchData } from './FetchData.react';

const CheckForm = (variables, sets) => {
 
  //Acciones del paginator
  if (variables.opciones !== undefined) {
    switch (variables.opciones.funcionalidad.label) {
      case 'Reporte General Incidencias Terrestre':
        FetchData(
          'informes/incidenciasGeneralesTerrestre/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaT.map((e) => e.value).join(',') +
            '/' +
            variables.opciones.tempOperacionEstatus
              .map((e) => e.value)
              .join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case 'Reporte General Incidencias Naviera':
        FetchData(
          'informes/incidenciasGeneralesNaviera/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaN.map((e) => e.value).join(',') +
            '/' +
            variables.opciones.tempOperacionEstatus
              .map((e) => e.value)
              .join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case 'Reporte General Alertas Terrestre':
        FetchData(
          'informes/alertasGeneralesTerrestre/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaT.map((e) => e.value).join(',') +
            '/' +
            variables.opciones.tempAlertasTipo.map((e) => e.value).join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case 'Reporte General Alertas Naviera':
        FetchData(
          'informes/alertasGeneralesNaviera/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaN.map((e) => e.value).join(',') +
            '/' +
            variables.opciones.tempAlertasTipo.map((e) => e.value).join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case 'Reporte General Movimientos Terrestre':
        FetchData(
          'informes/movimientosGeneralesTerrestre/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaT.map((e) => e.value).join(',') +
            '/' +
            variables.opciones.tempOperacionEstatus
              .map((e) => e.value)
              .join(',') +
            '/' +
            variables.opciones.tempMovimientoTipo.map((e) => e.value).join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case 'Reporte General Movimientos Naviera':
        if (variables.citaProgramada) {
          FetchData(
            'informes/movimientosGeneralesCitaProgramadaNaviera/pagina/' +
              variables.pagina +
              '/total/50/variables/' +
              variables.opciones.fechaInicio +
              '/' +
              variables.opciones.fechaFin +
              '/' +
              variables.opciones.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              variables.opciones.tempOperacionEstatus
                .map((e) => e.value)
                .join(',') +
              '/' +
              variables.opciones.tempMovimientoTipo
                .map((e) => e.value)
                .join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
          break;
        }
        FetchData(
          'informes/movimientosGeneralesNaviera/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaN.map((e) => e.value).join(',') +
            '/' +
            variables.opciones.tempOperacionEstatus
              .map((e) => e.value)
              .join(',') +
            '/' +
            variables.opciones.tempMovimientoTipo.map((e) => e.value).join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case 'Reporte General Entregas Terrestre':
        FetchData(
          'informes/entregasGeneralesTerrestre/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaT.map((e) => e.value).join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case 'Reporte General Entregas Naviera':
        FetchData(
          'informes/entregasGeneralesNaviera/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/' +
            variables.opciones.tempPlataformaN.map((e) => e.value).join(','),
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case "Reporte General GPS's acciones":
        FetchData(
          'informes/gpsAcciones/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin,
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      case "Notificaciones de eventos":
        FetchData(
          'informes/notificacionesEnviadas/pagina/' +
            variables.pagina +
            '/total/50/variables/' +
            variables.opciones.fechaInicio +
            '/' +
            variables.opciones.fechaFin +
            '/'+
            variables.opciones.tempPlataformaN.map((e) => e.value).join(',') +
            '/' +
            variables.opciones.tempNotificacionesTipo
              .map((e) => e.value)
              .join(',') +
            '/',
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
      default:
        break;
    }
    //Acciones del menú
  } else {
    //Checks
    variables.bloque.value === 0
      ? sets.setErrorBloque(true)
      : sets.setErrorBloque(false);
    variables.funcionalidad.value === 0
      ? sets.setErrorFuncionalidad(true)
      : sets.setErrorFuncionalidad(false);
    variables.fechaInicio.length === 0
      ? sets.setErrorFechaIni(true)
      : sets.setErrorFechaIni(false);
    variables.fechaFin.length === 0 ||
    Date.parse(variables.fechaInicio) > Date.parse(variables.fechaFin)
      ? sets.setErrorFechaFin(true)
      : sets.setErrorFechaFin(false);

    let errorFechaIni = variables.fechaInicio.length === 0 ? true : false;
    let errorFechaFin =
      variables.fechaFin.length === 0 ||
      Date.parse(variables.fechaInicio) > Date.parse(variables.fechaFin)
        ? true
        : false;
    let errorFuncionalidad = variables.funcionalidad.value === 0 ? true : false;

    switch (variables.funcionalidad.label) {
      case 'Reporte General Incidencias Terrestre':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          FetchData(
            'informes/incidenciasGeneralesTerrestre/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaT.map((e) => e.value).join(',') +
              '/' +
              variables.tempOperacionEstatus.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case 'Reporte General Incidencias Naviera':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          FetchData(
            'informes/incidenciasGeneralesNaviera/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              variables.tempOperacionEstatus.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case 'Reporte General Alertas Terrestre':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          FetchData(
            'informes/alertasGeneralesTerrestre/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaT.map((e) => e.value).join(',') +
              '/' +
              variables.tempAlertasTipo.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case 'Reporte General Alertas Naviera':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          FetchData(
            'informes/alertasGeneralesNaviera/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              variables.tempAlertasTipo.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case 'Reporte General Movimientos Terrestre':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          FetchData(
            'informes/movimientosGeneralesTerrestre/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaT.map((e) => e.value).join(',') +
              '/' +
              variables.tempOperacionEstatus.map((e) => e.value).join(',') +
              '/' +
              variables.tempMovimientoTipo.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case 'Reporte General Movimientos Naviera':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          if (variables.citaProgramada) {
            FetchData(
              'informes/movimientosGeneralesCitaProgramadaNaviera/pagina/' +
                0 +
                '/total/50/variables/' +
                variables.fechaInicio +
                '/' +
                variables.fechaFin +
                '/' +
                variables.tempPlataformaN.map((e) => e.value).join(',') +
                '/' +
                variables.tempOperacionEstatus.map((e) => e.value).join(',') +
                '/' +
                variables.tempMovimientoTipo.map((e) => e.value).join(','),
              'Resultados',
              sets.setData,
              sets.setLoading,
              sets.setError
            );
            break;
          }
          FetchData(
            'informes/movimientosGeneralesNaviera/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              variables.tempOperacionEstatus.map((e) => e.value).join(',') +
              '/' +
              variables.tempMovimientoTipo.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case 'Reporte General Entregas Terrestre':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          FetchData(
            'informes/entregasGeneralesTerrestre/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaT.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case 'Reporte General Entregas Naviera':
        if (!(errorFuncionalidad || errorFechaIni || errorFechaFin)) {
          FetchData(
            'informes/entregasGeneralesNaviera/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/' +
              variables.tempPlataformaN.map((e) => e.value).join(','),
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
        }
        break;
      case "Reporte General GPS's acciones":
        FetchData(
          'informes/gpsAcciones/pagina/' +
            0 +
            '/total/50/variables/' +
            variables.fechaInicio +
            '/' +
            variables.fechaFin,
          'Resultados',
          sets.setData,
          sets.setLoading,
          sets.setError
        );
        break;
        case "Notificaciones de eventos":
          FetchData(
            'informes/notificacionesEnviadas/pagina/' +
              0 +
              '/total/50/variables/' +
              variables.fechaInicio +
              '/' +
              variables.fechaFin +
              '/'+
              variables.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              variables.tempNotificacionesTipo
                .map((e) => e.value)
                .join(',') 
              ,
            'Resultados',
            sets.setData,
            sets.setLoading,
            sets.setError
          );
          break;
      default:
        break;
    }
  }
};

export default CheckForm;
