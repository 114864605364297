import React, { useState, useEffect } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FetchData } from './FetchData.react';
import { timeString } from "../../../herramientas/DateFormat";


export const ExportarReportesGenerales = ({
  total,
  opciones,
  sinColumnas,
  setLoading,
  setError,
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [csvData, setCsvData] = useState();
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (csvData !== undefined && csvData.content.length > 0) {
      setLoading(true);
      let datos = [];
      datos = csvData.content.map((row, index) => {
        let newRow;
        newRow = JSON.parse(row);
        if (
          opciones.funcionalidad.label === 'Reporte General Movimientos Naviera'
        ) {
          newRow.estadia = '';
          //Se crea la estadia
          let tiempoT = 0;
          if (
            newRow.salida_cliente_vacio &&
            newRow.arribo_cliente_lleno &&
            newRow.movimiento_tipo === 'Importación'
          ) {
            tiempoT =
              new Date(newRow.salida_cliente_vacio).getTime() -
              new Date(newRow.arribo_cliente_lleno).getTime();
            newRow.estadia = timeString(tiempoT);
          } else if (
            newRow.salida_cliente_lleno &&
            newRow.arribo_cliente_vacio &&
            newRow.movimiento_tipo === 'Exportación'
          ) {
            tiempoT =
              new Date(newRow.salida_cliente_lleno).getTime() -
              new Date(newRow.arribo_cliente_vacio).getTime();
            newRow.estadia = timeString(tiempoT);
          }

          if (newRow.lista_gps !== undefined && newRow.lista_gps !== null) {
            newRow.principal = '';
            newRow.desechable = '';
            newRow.cinturon = '';
            newRow.respaldo = '';
            newRow.candado = '';
            newRow.gps_fijo = '';
            newRow.cuenta_espejo = '';
            let listaGps = newRow.lista_gps.split(' || ');
            listaGps.forEach((gps) => {
              let dataGps = gps.split(': ');
              switch (dataGps[0]) {
                case 'Principal':
                  newRow.principal = dataGps[1] + ' || ' + newRow.principal;
                  break;
                case 'Desechable':
                  newRow.desechable = dataGps[1] + ' || ' + newRow.desechable;
                  break;
                case 'Cinturón':
                  newRow.cinturon = dataGps[1] + ' || ' + newRow.cinturon;
                  break;
                case 'Respaldo':
                  newRow.respaldo = dataGps[1] + ' || ' + newRow.respaldo;
                  break;
                case 'Candado':
                  newRow.candado = dataGps[1] + ' || ' + newRow.candado;
                  break;
                case ' GPS FIJO':
                  newRow.gps_fijo = dataGps[1] + ' || ' + newRow.gps_fijo;
                  break;
                case 'Cuenta espejo':
                  newRow.cuenta_espejo =
                    dataGps[1] + ' || ' + newRow.cuenta_espejo;
                  break;
                default:
              }
            });
          }
        }

        if (
          opciones.funcionalidad.label === 'Reporte General Entregas Terrestre'
        ) {
          if (newRow.gps !== undefined && newRow.gps !== null) {
            newRow.gps1 = '';
            newRow.gps2 = '';
            let listaGps = newRow.gps.split(' || ');
            if (listaGps[0] !== undefined) {
              newRow.gps1 = listaGps[0];
            }
            if (listaGps[1] !== undefined) {
              newRow.gps2 = listaGps[1];
            }
          }
          delete newRow['gps'];
        }
        delete newRow['lista_gps'];
        if (newRow.variables !== undefined && newRow.variables !== null) {
          let variables = JSON.parse(newRow.variables);
          newRow.ejecutivo = variables.ejecutivo;
          newRow.referencia_chubb = variables.referencia_chubb;
        }
        delete newRow['variables'];

        sinColumnas.forEach((columna) => {
          delete newRow[columna];
        });
        return newRow;
      });
      const ws = XLSX.utils.json_to_sheet(datos);
      const wb = { Sheets: { datos: ws }, SheetNames: ['datos'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    }
  }, [csvData]);

  const buscar = async () => {
    let pagina = 0;
    let paginas = Math.ceil(total / 50000);
    while (pagina < paginas) {
      setFileName(opciones.funcionalidad.label + ' ' + (pagina + 1));
      switch (opciones.funcionalidad.label) {
        case 'Reporte General Incidencias Terrestre':
          await FetchData(
            'informes/incidenciasGeneralesTerrestre/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaT.map((e) => e.value).join(',') +
              '/' +
              opciones.tempOperacionEstatus.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case 'Reporte General Incidencias Naviera':
          await FetchData(
            'informes/incidenciasGeneralesNaviera/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              opciones.tempOperacionEstatus.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case 'Reporte General Alertas Terrestre':
          await FetchData(
            'informes/alertasGeneralesTerrestre/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaT.map((e) => e.value).join(',') +
              '/' +
              opciones.tempAlertasTipo.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case 'Reporte General Alertas Naviera':
          await FetchData(
            'informes/alertasGeneralesNaviera/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              opciones.tempAlertasTipo.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case 'Reporte General Movimientos Terrestre':
          await FetchData(
            'informes/movimientosGeneralesTerrestre/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaT.map((e) => e.value).join(',') +
              '/' +
              opciones.tempOperacionEstatus.map((e) => e.value).join(',') +
              '/' +
              opciones.tempMovimientoTipo.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case 'Reporte General Movimientos Naviera':
          if (opciones.citaProgramada) {
            await FetchData(
              'informes/movimientosGeneralesCitaProgramadaNaviera/pagina/' +
                pagina +
                '/total/50000/variables/' +
                opciones.fechaInicio +
                '/' +
                opciones.fechaFin +
                '/' +
                opciones.tempPlataformaN.map((e) => e.value).join(',') +
                '/' +
                opciones.tempOperacionEstatus.map((e) => e.value).join(',') +
                '/' +
                opciones.tempMovimientoTipo.map((e) => e.value).join(','),
              'Resultados',
              setCsvData,
              setLoading,
              setError
            );
            break;
          }
          await FetchData(
            'informes/movimientosGeneralesNaviera/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaN.map((e) => e.value).join(',') +
              '/' +
              opciones.tempOperacionEstatus.map((e) => e.value).join(',') +
              '/' +
              opciones.tempMovimientoTipo.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case 'Reporte General Entregas Terrestre':
          await FetchData(
            'informes/entregasGeneralesTerrestre/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaT.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case 'Reporte General Entregas Naviera':
          await FetchData(
            'informes/entregasGeneralesNaviera/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin +
              '/' +
              opciones.tempPlataformaN.map((e) => e.value).join(','),
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
        case "Reporte General GPS's acciones":
          await FetchData(
            'informes/gpsAcciones/pagina/' +
              pagina +
              '/total/50000/variables/' +
              opciones.fechaInicio +
              '/' +
              opciones.fechaFin,
            'Resultados',
            setCsvData,
            setLoading,
            setError
          );
          break;
          case 'Notificaciones de eventos':
            await FetchData(
              'informes/notificacionesEnviadas/pagina/' +
            pagina +
            '/total/50000/variables/' +
            opciones.fechaInicio +
            '/' +
            opciones.fechaFin +
            '/'+
            opciones.tempPlataformaN.map((e) => e.value).join(',') +
            '/' +
            opciones.tempNotificacionesTipo
              .map((e) => e.value)
              .join(',') +
            '/',
              'Resultados',
              setCsvData,
              setLoading,
              setError
            );
          break;
        default:
          break;
      }
      pagina++;
    }
    setLoading(false);
  };

  return (
    <>
      <div className="row">
        <div className="d-flex flex-column align-items-end">
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={(e) => buscar()}
          >
            Exportar a Excel
          </button>
        </div>
      </div>
      <div className="row">
        <div className="d-flex flex-column align-items-end">
          <p className="font-weight-light font-italic">
            Se descargarán {Math.ceil(total / 50000)} archivo(s) de excel (el
            número máximo de registros por archivo es de 50,000).
          </p>
        </div>
      </div>
    </>
  );
};
