import React, { useEffect, useState } from "react";
import Stomp from "stompjs";
import axios from "axios";
import { NotificationManager } from "react-notifications";
//import ActiveMq from "./ActiveMq";
let clientRef = null;
let instance = axios.create();
const username = "WN8z55WbAzF?bgy!BW5S9VB9$N#$fmUPx!fDf3=ZJft3mY3c@b";
const password = "DzuJVh%k&Msvr@=k2a?zZz#4CuZ55awXSYkVm?_a#+a@xQ!pDn";
let lastheartbeatTelefono = null;
let lastheartbeatOne = null;
let socketOpened = false;

let url =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
const url1 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-1.mq.us-east-1.amazonaws.com:61619";
const url2 =  "wss://b-f2a56141-6c13-485e-849e-29123900f214-2.mq.us-east-1.amazonaws.com:61619";
const user = "WEBSOCKET";
const pass = "w3bs0k3t2024";
const usuario = JSON.parse(sessionStorage.getItem("usuario"));
let client = Stomp.client(url);
const MonitoreoOTD = ({
  topic,
  otd,
  movsUpdate
}) => {
  useEffect(() => {
    conectar();
    //console.log(otd)
  }, []);



  const conectar = () => {
    console.log("conectar al websocket OTD: ");
    client = Stomp.client(url);
    client.heartbeat = {
      incoming: 20000,
      outgoing: 20000,
    };
    client.debug = () => {};
    client.reconnect_delay = 5000;
    client.connect(
      user,
      pass,
      (frame) => {
        if (topic) {
          console.log(topic)
            client.subscribe(topic, (message) => {
              
              let  newListOtd =  otd;
              
              let body =  JSON.parse(message.body)
              let data = body.data;
              let index;
              console.log(data)
              console.log(body)
              if(body.vista == 'movimientoETA'){
                console.log("entrooo ETA")
               data =  newListOtd.filter((mov,i)=>{
                
                   if(mov.idMovimiento == data.idMovimiento){
    
                   // let dateMov = new Date(mov.citaProgramada); // some mock date
                   // let millisecondsMov = dateMov.getTime(); 

                   // let dateEta = new Date(data.eta); // some mock date
                    //let millisecondsEta = dateEta.getTime(); 

                    // console.log(dateMov)
                    // console.log(dateEta)
    
                    //   if(millisecondsMov > millisecondsEta){
                    //      mov.status = "ONTIME"
                    //   }else{
                    //     mov.status  = "DELAY"
                    //   }

                      mov.etaCalculadas.eta = data.eta
                  

                     index = i;
                      return true;
                   }
                })

                data =  data[0];

                newListOtd.splice(index,1);
                newListOtd.unshift(data)
              }

              if(body.vista == 'movimientosOTD'){
                newListOtd.unshift(data)
              }


              if(body.vista == 'movimientosOTDEliminar'){

                console.log("eliminar")

                newListOtd.filter((mov,i)=>{
                
                  if(mov.idMovimiento == data.id){
                    console.log(i)
                    index = i;
                     return true;
                  }
                  
               })

               console.log("index",index)
               newListOtd.splice(index,1);

              }


              
             
             console.log(newListOtd)
              
              if(body.accion == 1){
                NotificationManager.success( 
                  body.mensaje
                );
              }

              if(body.accion == 2){
                NotificationManager.warning( 
                  body.mensaje
                );
              }
              if(body.accion == 3){
                NotificationManager.info( 
                  body.mensaje
                );
              }
             
              movsUpdate(newListOtd)
              //console.log(message.body);
            });
          
        }
        console.log("REQUEST DATA");
      
      },
      (err) => {
        url = (url === url1) ? url2 : url1;
        //error useCallback
        if (err.toUpperCase().includes("LOST CONNECTION")) {
          setTimeout(() => {
            conectar();
          }, 5000);
          //alert(err);
        }
        console.log(err);
      }
    );
  }
  useEffect(() => {
    // if (mensaje.subject) {
    //   sendMessage(JSON.stringify(mensaje), usuario);
    // }
  }, []);
  
  const sendMessage = (msg, usuario) => {
    client.send("/topic/telefono-" + usuario, {}, msg);
  }

  const udate = (message) => {
    message = message ? JSON.parse(message) : message;
    console.log(message)
    //console.log(message);
 
  }
  return <></>;
};
export default MonitoreoOTD;
