import React, { Component } from "react";
import { Button, Form, Icon, Table, Grid, Card } from "tabler-react";
import { Crud_Catalogos, Crud_error } from '../../herramientas/Crud_Catalogos';
import TabsLayout  from "../../components/TabsLayout.react";
import { Transportistas, Plataformas, Contactos, Etiquetas, ContactosCorredor, WatsAppGrupos,Remolques,Metricas } from "./Formularios"

class  TransportistaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
                   currentTap:0,
                           id:"",
                       accion:this.props.accion, //'nuevo'// modificar,
                        ready:false
                 };
            this.tabs =[];//this.props.elementos.nombre
            this.Plataformas    ={ title:"Plataformas",    icon:"layout",   panel:<Plataformas    accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.Transportistas ={ title:"Transportistas", icon:"layout",   panel:<Transportistas plataforma={this.props.plataforma } accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.Etiquetas      ={ title:"Etiquetas", icon:"git-merge",panel:<Etiquetas   accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.Contactos      ={ title:"Contactos" ,     icon:"layout",   panel:<Contactos      accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.ContactosCorredor      ={ title:"Contactos Corredor" ,     icon:"layout",   panel:<ContactosCorredor      accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} stor= { this.props.stor }/>,close:false };
            this.WatsAppGrupos = { title: "WatsApp Grupos", icon: "layout", panel: <WatsAppGrupos transportista={this.props.elementos} stor={this.props.stor} plataforma={this.props.plataforma} />, close: false, };
            this.Remolques       ={ title:"Remolques",      icon:"layout",   panel:<Remolques       accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} data = {this.props.data} stor= { this.props.stor }  closeModal={this.props.cerrarModal} />,close:false };
            this.Metricas       ={ title:"Metricas",       icon:"layout",   panel:<Metricas       accion={ this.props.accion } elementos= { this.props.elementos } getfuntion= { this.props.getfuntion} data = {this.props.data} stor= { this.props.stor }  closeModal={this.props.cerrarModal} />,close:false };
            
          }
  componentDidMount =() => { if(this.state.accion !== 'nuevo'){ this.setState({id:this.props.elementos.id}); }else{ } };

  componentWillMount =() => {
    console.log(this.props.tipo)
    if(this.state.accion !== 'nuevo' && this.props.tipo !== 2 ){
      if (this.props.elementos.tipo===1){
        this.tabs= [
          this.Transportistas, this.Contactos, this.Etiquetas, this.ContactosCorredor
        ];
      }else{
        this.tabs= [
          this.Transportistas, this.Contactos, this.ContactosCorredor, this.Plataformas, this.Etiquetas
        ];
      }
      if( this.props.plataforma ){
        this.tabs.push(this.WatsAppGrupos);
      }
    }else{
      if(this.props.tipo ===2){
        this.tabs= [ this.Remolques ];
      }
      else{
        this.tabs= [ this.Transportistas ];
      }

      if(this.props.tipo === 3){
        this.tabs= [ this.Metricas ];
      }
    }
    
    
  }
  selectedTab =(index) => { this.setState({ currentTap:index }) }
  render() {
    return (
      <div>
        <TabsLayout tabs={ this.tabs } selectedTab= { this.state.currentTap } selectTab= { this.selectedTab } />
      </div>
    )
  }
}

export default TransportistaForm
