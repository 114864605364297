import React, { Component, useEffect, useState } from "react";
import { Button, Form, Icon, Table, Grid, Card, Dimmer } from "tabler-react";
import { Crud_Catalogos } from "../../../herramientas/Crud_Catalogos";
import ModalLay from "../../../components/ModalLay/ModalLay";
import { ClientesNavierosForm } from "./../../";
import { TablePageable } from "../../../Helpers";

/**
 * Notificaciones
 */
import { NotificationManager } from "react-notifications";

const Clientes =({elementos, stor})=> {
  const [formulario, setFormulario]= useState({
    //"id": 319,
    "idPlataforma": elementos.id,
    "nombre": "",
    "contactoNombre": "",
    "correo": "",
    "telefono": ""
  });
  const [clienteSolicitanteList, setClienteSolicitanteList]= useState([]);
  const [crudbutonEnable, setcrudbutonEnable] = useState(true);
  const [nuevo, setNuevo]= useState(false);
  useEffect(() => {
    refrescar();
  },[]);
  const refrescar=()=> {
    getfuntion(
      "get",
      "",
      "",
      "clientes_navieros/plataforma/"+elementos.id,
      "clientes"
    );
  }
  const clear =()=>{
    setFormulario({
      //"id": 319,
      "idPlataforma": elementos.id,
      "nombre": "",
      "contactoNombre": "",
      "correo": "",
      "telefono": ""
    });
    setNuevo(false);
    setcrudbutonEnable(true);
  }
  const isEnable = (Accion) => {
    let borrar = "clientes_borrar";
    let actualizar = "clientes_editar";
    let agregar = "clientes_agregar";
    const privilegios = stor.privilegios_object;
    const perfil = JSON.parse(sessionStorage.getItem("perfil"));
    for (let i = 0; i < privilegios.length; i++) {
      if (Accion === "borrar") {
        if (privilegios[i].clave == borrar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "actualizar") {
        if (privilegios[i].clave == actualizar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
      if (Accion === "agregar") {
        if (privilegios[i].clave == agregar) {
          if (
            perfil.privilegios.filter(
              (word) => word.idPrivilegio == privilegios[i].id
            ).length > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const disableSave = () => {
    console.log(formulario);
    if(
      formulario.nombre && formulario.nombre.length
      && formulario.contactoNombre && formulario.contactoNombre.length
      && formulario.telefono && formulario.telefono.length === 10
    ){
      return false;
    }
    return true;
  };

  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "clientes_navieros",
    stateVar = "clientes",
    hiddenModl = ""
  ) => {
    Crud_Catalogos(
      catalogo,
      "plataformas",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            {
              if (stateVar === "clientes") {
                setClienteSolicitanteList(returnVal);
              }
            }
            break;
          case "post":
            {
              if (stateVar === "clientes") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Cliente Solicitante guardado: " +returnVal.nombre,
                    "Nuevo"
                  );
                  refrescar();
                }
              }
            }
            break;
          case "put":
            {
              if (stateVar === "clientes") {
                if (returnVal.id) {
                  NotificationManager.info(
                    "Cliente Solicitante guardado: " +returnVal.nombre,
                    "Modificación"
                  );
                  refrescar();
                }
              }
            }
            break;
          case "delete":
              if (stateVar === "clientes") {
                if (returnVal) {
                  NotificationManager.info(
                    "Se ha borrado registro: "+obj.nombre,
                    "Borrar registro"
                  );
                }else{
                  NotificationManager.error(
                    "No ha sido posible borrar registro: "+obj.nombre,
                    "Borrar registro"
                  );
                }
                refrescar();
              }
            break;
          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const crud_delete = () => {
    if(formulario.id){
      alert("borrar");
      setcrudbutonEnable(false);
      getfuntion("delete", formulario, formulario.id, "clientes_navieros", "clientes" );
      clear();
    }
  };
  const crud_put = () => {
    if(formulario.id){
      setcrudbutonEnable(false);
      getfuntion("put", formulario, "", "clientes_navieros", "clientes" );
      clear();
    }
  };
  const crud_post = () => {
    if(!formulario.id){
      setcrudbutonEnable(false);
      getfuntion("post", formulario, "", "clientes_navieros", "clientes" );
      clear();
    }
  };


  return (
    <div className="container">
      <Button pill size="sm" outline color="primary" onClick={()=> setNuevo(true)}>
        <Icon name="plus" />
      </Button>
      <div className="row" hidden={formulario.id }>
        <div className="col-12">
          <TablePageable
            titulo={ <h5>Clientes Solicitantes de la plataforma {elementos.nombre}</h5> }
            lista= { clienteSolicitanteList }
            columnas={
              [
                { columna: "edit", label: "Editar",
                  form: (e)=>{
                    return <Button pill size="sm" outline color="primary" onClick={()=> setFormulario(e)}>
                      <Icon name="edit" />
                    </Button>
                  }
                },
                { columna: "id", label: "Id" },
                { columna: "nombre", label: "Nombre" },
                { columna: "contactoNombre", label: "Contacto Nombre" },
                { columna: "correo", label: "Correo" },
                { columna: "telefono", label: "telefono" },
              ]
            }
            id= "id"
            //selected={(e)=>{ setFormulario(e) }}
            csvname= {"Clientes Solicitantes de la plataforma "+ elementos.nombre}
            //resaltarFuncion={(e)=> !e.idCliente || ! e.idTransportista? true: false}
            //resaltarColor={"rgba(223, 58, 58, 0.47)"}
          />
        </div>
      </div>
      {/*
        "nombre": "",
        "contactoNombre": "",
        "correo": "",
        "telefono": ""
        */}
      { nuevo || formulario.id?
        <div className="row" hidden={ !nuevo && !formulario.id }>
          <div className="col-4">
            <div className="form-group">
              <label for="nombre">Nombre</label>
              <input
                type="text"
                className="form-control"
                id="nombre"
                placeholder="Ingresa nombre"
                aria-describedby="nombrelHelp"
                value={formulario.nombre}
                onChange={(e) => setFormulario({ ...formulario, nombre: e.target.value })}
              />
              <small id="nombrelHelp" className="form-text text-muted">Ingresa el nombre del cliente solicitante.</small>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label for="contactoNombre">Contacto Nombre</label>
              <input
                type="text"
                className="form-control"
                id="contactoNombre"
                placeholder="Ingresa contacto nombre"
                aria-describedby="contactoNombrelHelp"
                value={formulario.contactoNombre}
                onChange={(e) => setFormulario({ ...formulario, contactoNombre: e.target.value })}
              />
              <small id="contactoNombrelHelp" className="form-text text-muted">Ingresa el nombre Del contacto {formulario.nombre}.</small>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label for="correo">Correo</label>
              <input
                type="mail"
                className="form-control"
                id="correo"
                placeholder="correo"
                aria-describedby="correoHelp"
                value={formulario.correo}
                onChange={(e) => setFormulario({ ...formulario, correo: e.target.value })}
              />
              <small id="correoHelp" className="form-text text-muted">Ingresa el correo para {formulario.contactoNombre}.</small>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label for="telefono">Teléfono</label>
              <input
                type="number"
                className="form-control"
                id="telefono"
                placeholder="telefono"
                aria-describedby="telefonoHelp"
                value={formulario.telefono}
                onChange={(e) => setFormulario({ ...formulario, telefono: e.target.value })}
              />
              <small id="telefonoHelp" className="form-text text-muted">Ingresa el teléfono para {formulario.contactoNombre}.</small>
            </div>
          </div>
          <div className="col-12 float-right">
            {isEnable("actualizar") && isEnable("agregar") ? (
              <span class="badge">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="primary"
                  disabled={disableSave() || !crudbutonEnable}
                  onClick={()=>{ formulario.id? crud_put(): crud_post();}}
                >
                  <span class="badge">
                    <Icon link={true} name="save" />
                    Guardar
                  </span>
                </Button>
              </span>
            ) : null}
            { isEnable("borrar") ? (
              <span className="badge">
                <Button
                  target="_blank"
                  size="sm"
                  RootComponent="a"
                  color="danger"
                  onClick={() => crud_delete()}
                  disabled={!crudbutonEnable}
                >
                  <span className="badge">
                    <Icon link={true} name="trash" color="red" />
                    Borrar
                  </span>
                </Button>
              </span>
            ) : null}
            <span className="badge">
              <button type="button" className="btn btn-outline-primary btn-sm text-danger" onClick={()=>clear()}>
                <i class="fa fa-close" style={{"font-size": "24px"}}></i>
              </button>
            </span>
          </div>
        </div>
        : null
      }
    </div>
  );
}

export default Clientes;
