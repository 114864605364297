import React, { useState, useEffect} from 'react';
import SiteWrapper from "../../SiteWrapper.react";
import Select from "react-select";
import axios from 'axios';
import { NotificationManager } from "react-notifications";
import { Crud_Catalogos } from '../../herramientas/Crud_Catalogos';
import { Line } from 'react-chartjs-2';
import { timeToYearmonthDay, timeToDateFill } from "../../herramientas/DateFormat";
import * as conf from "../../config/config";
import { TablePageable } from "../../Helpers";
import MapaEvidencia from './MapaEvidencia';
import { useRef } from 'react';
let instance = axios.create();
const perfil= JSON.parse(sessionStorage.getItem("perfil"));
const token= "KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=";
let estatusTemp= {};
let estatusIndex= 0;


const ReportesAlertasCctvPage = () => {
  const [funcionalidades, setFuncionalidades]= useState([]);
  const [ videoRef, setVideoRef ] = useState([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ]);
  const [ reporte, setReporte ] = useState( []);
  const [requestBody, setRequestBody]= useState({
    "key": token,
    "dispositivos": [],//[332]
    "tipos": [],//[62]
    "starttime": timeToYearmonthDay(new Date().getTime()),
    "endtime": timeToYearmonthDay(new Date().getTime())
  });
  const [tipos, setTipos] = useState([]);
  const [tiposList, setTiposList ] = useState( [] );
  const [dispositivosList, setDispositivosList] = useState([]);
  const [dispositivos, setDispositivos ] = useState([]);
  const [alarma, setAlarma]= useState({});
  const [dispositivo, setDispositivo]= useState({});
  const [videos, setVideos]= useState({});
  const [alarmaEstatus, setAlarmaEstatus]= useState([]);
  const [currentAlarmaEstatus, setCurrentAlarmaEstatus]= useState({});
  const [playing, setPlaying]= useState(false);
  useEffect(() => {
    crud("get", "", "", "funcionalidades/reporte_alarmas_cctv/"+perfil.id, "funcionalidades");
    delete axios.defaults.headers.common["Authorization"];//:8443/video_back
    Crud_Catalogos("alarmas/alarmas_tipos?key="+token, "get", "", "").then((resp) => {
      if(resp){
        let tiposTemp= [];
        for( let tipo of resp ){
          tiposTemp.push({ value: tipo.type, label: tipo.description, data: tipo});
        }
        setTipos(tiposTemp);
      }else{
        console.log(resp);
      }
    }).catch(err =>{ console.log(err); });

    instance.get(conf.cctv+":8443/video_back/alarmas/alarmas_tipos?key=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=").then(res => {
      if(res.data){
        let tiposTemp= [];
        for( let tipo of res.data ){
          tiposTemp.push({ value: tipo.type, label: tipo.description, data: tipo});
        }
        setTipos(tiposTemp);
      }else{
        console.log(res);
      }
    }).catch(errors => console.log(errors));

    instance.get(conf.cctv+":8443/video_back/dispositivos/idproveedor?idProveedor=4&key=KKLHjlHke_tqcw9xlHC2Y3dm0D9QmFwA16buOxl8yhk=").then(res => {
      if(res.data.data){
        let dispositivosTemp= [];
        for( let d of res.data.data ){
          dispositivosTemp.push({ value: d.id, label: d.nombre, data: d});
        }
        setDispositivosList(dispositivosTemp);
      }else{
        console.log(res);
      }
    }).catch(errors => console.log(errors));

  },[]);

  useEffect(() => {
    if(funcionalidades.length && dispositivosList.length && tipos.length){
      refrescar();
    }    
  },[funcionalidades, tipos]);

  useEffect(() => {
    if(alarma.id){
      delete axios.defaults.headers.common["Authorization"];
      //obtener videos
      instance.get(conf.cctv+":8443/video_back/evidencias/video/detail?unidad="+alarma.idDispositivo+"&eid="+alarma.eid+"&key="+token).then(res => {//:8443/video_back
        const resp= res.data;
        if(resp.data && resp.data.codigo === 200 && resp.data.data.code === 200){
          setVideos(resp.data.data.data);
        }
      }).catch(errors => console.log(errors));

      //Obtener alarmas estatus
      instance.get(conf.cctv+":8443/video_back/evidencias/alarma_estatus?unidad="+alarma.idDispositivo+"&eid="+alarma.eid+"&key="+token).then(res => {//:8443/video_back
        const resp= res.data;
        if(resp.data && resp.data.codigo === 200 && resp.data.data.code === 200){
          setAlarmaEstatus(resp.data.data.data);
        }
      }).catch(errors => console.log(errors));
    }    
  },[alarma]);

  const refrescar =()=>{
    delete axios.defaults.headers.common["Authorization"];
    let requestBodyTemp= {...requestBody};
    requestBodyTemp.starttime= requestBodyTemp.starttime+" 00:00:00";
    requestBodyTemp.endtime= requestBodyTemp.endtime+" 23:59:59";
    instance.post(conf.cctv+":8443/video_back/evidencias/video/4", requestBodyTemp).then(res => {//:8443/video_back
      if(res.data.status === 200){
        for( let r of res.data.data ){
          r.servidorFecha= timeToDateFill(r.servidorFecha);
          r.alarmaFecha= timeToDateFill(r.alarmaFecha);
        }
        setReporte(res.data.data);
      }else{
        NotificationManager.error(
          res.data.message,
          "Oops ..!!"
        );
      }
    }).catch(errors => console.log(errors));
  }
  const crud=(metodo = "get", obj = [], id = "", catalogo = "", stateVar = "", hiddenModl = "") => {
    return Crud_Catalogos(catalogo, "movimientos", metodo, id, obj, "", stateVar, hiddenModl, []).then((returnVal) => {
        switch (metodo) {
            case "get":
              if( stateVar === "funcionalidades"){
                setFuncionalidades(returnVal);
              }
            break;
            default:
                break;
        }
    }).catch(err => { console.log(err); });
  }
  const seguirVideo=()=>{  
    setTimeout(() => {
      if (videoRef[0] && videoRef[0].current) {
        estatusIndex= videoRef[0].current.currentTime | 0;
        const estatus=
          alarmaEstatus[estatusIndex]
            ? alarmaEstatus[estatusIndex]
            : {};
          //console.log("Sergur video: ", estatus);
        if(!estatusTemp.time || estatusTemp.time !== estatus.time){
          estatusTemp= estatus;
          setCurrentAlarmaEstatus(estatus);
        }
        if( !videoRef[0].current.paused ){
          seguirVideo();
        }else{
          setPlaying(false);
        }
      }
    }, 400);
  }
  const getGraficaVelocidad =()=>{
    let labels= [];
    let data= [];
    for( let i= 0; i<estatusIndex; i++ ){
      if( alarmaEstatus[i]){
        labels.push(alarmaEstatus[i].time);
        data.push(alarmaEstatus[i].sateliteSpeed);
      }else{
        let videoTemp= videos.eid? videos: {};
        if(videoTemp.eid){
          let fecha= new Date(new Date(videoTemp.startTime).getTime()+(1000*estatusIndex));
          labels.push(timeToDateFill(fecha));
          data.push(0);
        }
      }
    }
    return {
      fill: true,
      labels: labels,
      datasets: [{	
        label: "Velocidad",	
        data: data,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      }]
    }
  }
  const reset=()=>{
    setAlarma({});
    setDispositivo({});
    setVideos({});
    setAlarmaEstatus([]);
    setCurrentAlarmaEstatus({});
    setPlaying(false);
    estatusTemp= {};
    estatusIndex= 0;
  }
  return (
    <SiteWrapper>
      { funcionalidades.filter(f=> f.nombre === "listar").length?
        <div className='container-fluid p-1'>
          <hr color='white'/>
          <div className='row mt-5'>
            <div className='col-12' hidden={alarma.id}>
              <TablePageable
                titulo={
                  <div>
                    <div className="row mb-7">
                      <div className='col'>
                        <label>Fecha inicio</label>
                        <input className="form-control" type="date" id="start" name="trip-start"
                          value={requestBody.starttime}
                          onChange={(e)=>{
                            setRequestBody({...requestBody, starttime: e.target.value});
                            if( new Date(e.target.value).getTime() > new Date(requestBody.endtime).getTime() ){
                              setRequestBody({...requestBody, endtime: e.target.value});
                            }else{
                              if( (new Date(requestBody.endtime).getTime() - new Date(e.target.value).getTime()) > (31*24*60*60*1000) ){                      
                                setRequestBody({...requestBody, endtime: timeToYearmonthDay(new Date(e.target.value).getTime()+(31*24*60*60*1000))});
                              }
                            }
                          }}
                          min="2024-05-01" max={timeToYearmonthDay(new Date().getTime())} />
                      </div>
                      <div className='col'>
                        <label>Fecha fin</label>
                        <input className="form-control" type="date" id="start" name="trip-start"
                          value={requestBody.endtime}
                          onChange={(e)=>{
                            setRequestBody({...requestBody, endtime: e.target.value});
                            if( new Date(e.target.value).getTime() < new Date(requestBody.starttime).getTime() ){
                              setRequestBody({...requestBody, starttime: e.target.value});
                            }else{
                              if( (new Date(e.target.value).getTime() - new Date(requestBody.starttime).getTime()) > (31*24*60*60*1000) ){                      
                                setRequestBody({...requestBody, endtime: timeToYearmonthDay(new Date(e.target.value).getTime()-(31*24*60*60*1000))});
                              }
                            }
                          }}
                          min="2024-05-01" max={timeToYearmonthDay(new Date().getTime())} />
                      </div>
                      <div className='col'>
                        <label>Tipo</label>
                        <Select
                          placeholder="Todos"
                          value={tiposList}
                          onChange={ (e)=>{
                            setTiposList(e);
                            let tiposTemp= [];
                            for(let t of e){
                              tiposTemp.push(t.value);
                            }
                            setRequestBody({...requestBody, tipos: tiposTemp});
                          }}
                          options={tipos}
                          isMulti={true}
                        />
                      </div>
                      <div className='col'>
                        <label>Dispositivos</label>
                        <Select
                          placeholder="Todos"
                          value={dispositivos}
                          onChange={ (e)=>{
                            setDispositivos(e);
                            let dispositivosTemp= [];
                            for(let d of e){
                              dispositivosTemp.push(d.value);
                            }
                            setRequestBody({...requestBody, dispositivos: dispositivosTemp});
                          }}
                          options={dispositivosList}
                          isMulti={true}
                        />
                      </div>
                      <div className='col'>
                          <i className="fa fa-refresh text-primary btn"
                            style={{ fontSize: "40px"}}
                            data-toggle="tooltip"
                            data-placement="top"
                            title={"Actualizar"}
                            onClick={()=>{
                              refrescar();             
                            }}></i>
                      </div>
                    </div>
                  </div>
                }
                lista= { reporte }
                columnas={
                  [
                    { columna: "id", label: "ID"},
                    { columna: "video", label: "Video",
                      form: (e)=>{
                        return <i className="fa fa-film" style={{ color: e.videoGenerado && e.existeVideo? "green": "red"}}/>
                      }
                    },
                    { columna: "tipoInt", label: "idTipo"},
                    { columna: "tipoString", label: "Tipo",},
                    { columna: "alarmaFecha", label: "Fecha" },
                    { columna: "idDispositivo", label: "Unidad",
                      valor: (e)=>{
                        const dispositivosTemp= dispositivosList.filter(d=> Number(d.value) === e.idDispositivo);
                        return dispositivosTemp.length === 1 ? dispositivosTemp[0].label: "Indefinido"
                      },
                      form: (e)=>{
                        const dispositivosTemp= dispositivosList.filter(d=> Number(d.value) === e.idDispositivo);
                        return dispositivosTemp.length === 1 ? dispositivosTemp[0].label: "Indefinido"
                      }
                    },
                    { columna: "latitud", label: "Latitud" },
                    { columna: "longitud", label: "Longitud" }
                  ]
                }
                id= "id"
                selected={(e)=>{
                  setAlarma(e);
                  const dispositivosTemp= dispositivosList.filter(d=> Number(d.value) === e.idDispositivo);
                  setDispositivo(dispositivosTemp.length === 1 ? dispositivosTemp[0].data: {});
                }}
                csvname= {funcionalidades.filter(f=> f.nombre === "exportar").length?("Reporte de alarmas "): null}
                /*
                csvname= "Reporte de evidencias "
                /*
                resaltarFuncion={(e)=>{
                  return incidencias.filter(i => i.incidencia.id === e.id  ).length ? true: false
                }}
                resaltarColor={(e)=>{
                  const etiquetaTemp= stor.etiquetas_object.filter(et => et.id === e.idEtiqueta);
                  if( etiquetaTemp.length !== 1){
                    return "rgba(242, 151, 151, 0.41)";
                  }
                  return "rgba(58, 223, 149, 0.25)";
                }}*/
                paginacion={true}
                elementosPorPagina={15}
              />
            </div>
            { alarma.id && dispositivo.id?
              <div className='container-fluid'>
                <div className='row'>
                  <div className='col'>
                    <h1>{alarma.tipoString}</h1>
                  </div>
                  <div className='col'>
                    <p style={{ width: "100%", height: "55px"}}>
                      <button className="btn btn-primary float-right" onClick={()=>{
                        reset();
                      }}>Regresar</button>
                    </p>
                  </div>
                </div>
                <hr style={{height: "2px", borderWidth: "0", color: "gray", backgroundColor: "gray"}}></hr>
                <div className='row'>
                  <div className='col-2'>
                    <p>
                      {alarma.id && !alarma.videoGenerado? "Video el video aun no se ha generado": ""}
                      {alarma.id && alarma.videoGenerado && !alarma.existeVideo? "El video no existe": ""}
                      {alarma.id?
                        <ul className="list-group list-group-flush p-3">
                          <li className="list-group-item"> <i className="fa fa-truck"></i> Unidad: {dispositivo? dispositivo.nombre:""}</li>
                          <li className="list-group-item"> <i className="fa fa-fa fa-bars"></i> Grupo: {dispositivo? dispositivo.grupo:""}</li>
                          <li className="list-group-item"> <i className="fa fa-calendar"></i> Fecha: {alarma.alarmaFecha}</li>
                          <li className="list-group-item"> <i className="fa fa-calendar"></i> Fecha inicio: {videos.startTime}</li>
                          <li className="list-group-item"> <i className="fa fa-calendar"></i> Fecha fin : {videos.endTime}</li>
                          <li className="list-group-item"> <i className="fa fa-bell"></i> Alarma: {alarma.tipoString}</li>
                          <li className="list-group-item"> <i className="fa fa-map-marker"></i> Coordenadas: {alarma.latitud} , {alarma.longitud}</li>
                          <li className="list-group-item"> <i className="fa fa-compass"></i> Dirección: {alarma.direction}</li>
                          <li className="list-group-item"> <i className="fa fa-angle-double-right"></i> Velocidad: {alarma.velocidad} KM/H</li>
                        </ul>
                      :""}
                    </p>
                  </div>
                  <div className='col-10'>
                    { videos.eid?
                      <div className='row bg-dark'>
                        {/**
                         * <img src={ conf.api_raiz + "evidencias/obtener_recurso?unidad="+disp+"&recurso="+videos.imageArray[i].mediaPath+"&name="+videos.imageArray[i].fileName+"&key="+token} width="100%"/>
                         */}
                        {videos.videoArray.first.map((e,i)=>{
                          return <div className='col p-0 m-auto' key={i}
                            onClick={()=>{
                              for(let ref of videoRef){
                                if (ref && ref.current) {
                                  setPlaying(ref.current.paused ? true: false);
                                  ref.current.paused ? ref.current.play() : ref.current.pause();
                                }
                              }
                              seguirVideo();
                            }}
                          >
                            <h5 className='text-light float-left'> Canal {e.channel}</h5>
                            { videos.videoArray.first[i]?
                            <video width="100%" ref={videoRef[i]}// height="240"
                              src={ conf.cctv + ":8443/video_back/evidencias/obtener_recurso?unidad="+alarma.idDispositivo+"&recurso="+videos.videoArray.first[i].mediaPath+"&name="+videos.videoArray.first[i].fileName+"&key="+token }
                              type="video/mp4"             
                              //poster="/images/videoPoster.png"
                              //autoPlay
                              controls={false}>
                              Your browser does not support the video tag.
                            </video>
                              
                            : null}
                          </div>
                        })}
                        <div className='col-3'>
                          <MapaEvidencia alarmas={reporte.filter(a=> a.idDispositivo === dispositivo.id)} alarma={alarma}/>
                        </div>
                      </div>
                    :""}
                    <hr style={{height: "3px", borderWidth: "0", color: "gray", backgroundColor: "gray"}}></hr>
                    <div className='row'>
                      <div className='col-2 p-2'>
                        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="100" cy="100" r="90" fill="#f0f0f0" stroke="black" stroke-width="2" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-90 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-80 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-70 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-60 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-50 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-40 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-30 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="green" transform="rotate(-20 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="blue"  transform="rotate(-10 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="blue"  transform="rotate(  0 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="blue"  transform="rotate( 10 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="blue"  transform="rotate( 20 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="blue"  transform="rotate( 30 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="red"   transform="rotate( 40 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="red"   transform="rotate( 50 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="red"   transform="rotate( 60 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="red"   transform="rotate( 70 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="red"   transform="rotate( 80 100 100)" />
                          <rect x="95" y="9" width="8" height="10" fill="red"   transform="rotate( 90 100 100)" />

                          <text x="25" y="105" text-anchor="middle" font-size="10">0</text>  
                          <text x="30" y="80" text-anchor="middle" font-size="10">20</text>
                          <text x="45" y="55" text-anchor="middle" font-size="10">40</text>
                          <text x="65" y="40" text-anchor="middle" font-size="10">60</text>
                          <text x="86" y="33" text-anchor="middle" font-size="10">80</text>
                          <text x="110" y="33" text-anchor="middle" font-size="10">100</text>
                          <text x="135" y="40" text-anchor="middle" font-size="10">120</text>
                          <text x="150" y="55" text-anchor="middle" font-size="10">140</text>
                          <text x="165" y="75" text-anchor="middle" font-size="10">160</text>
                          <text x="170" y="103" text-anchor="middle" font-size="10">180</text>

                          <rect
                            x="95"
                            y="40"
                            width="8"
                            height="60"
                            fill="red"
                            transform={"rotate("+(-90+(currentAlarmaEstatus.sateliteSpeed? currentAlarmaEstatus.sateliteSpeed: 0))+" 100 100)"}
                          />
                          <text x="100" y="120" text-anchor="middle" font-size="20">
                              {currentAlarmaEstatus.sateliteSpeed? currentAlarmaEstatus.sateliteSpeed: 0} km/h
                          </text>
                          <text x="100" y="160" text-anchor="middle" font-size="15">Velocidad</text>
                        </svg>
                      </div>
                      <div className='col-2 p-2'>
                      <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">  
                        <circle cx="100" cy="100" r="90" fill="#f0f0f0" stroke="black" stroke-width="2" />
                        <circle cx="100" cy="100" r="5" fill="red" stroke="red" stroke-width="2" />

                        <rect x="95" y="9" width="8" height="10" fill="red"  transform="rotate(  0 100 100)" />
                        <rect x="95" y="9" width="8" height="10" fill="red"  transform="rotate(  90 100 100)" />
                        <rect x="95" y="9" width="8" height="10" fill="red"  transform="rotate(  180 100 100)" />
                        <rect x="95" y="9" width="8" height="10" fill="red"  transform="rotate(  270 100 100)" />

                        <text x="98" y="50" text-anchor="middle" font-size="30">N</text>
                        <text x="165" y="105" text-anchor="middle" font-size="30">E</text>
                        <text x="98" y="170" text-anchor="middle" font-size="30">S</text>
                        <text x="35" y="112" text-anchor="middle" font-size="30">O</text>

                        <rect x="95" y="40" width="8" height="60" fill="red" transform={"rotate("+(alarma.direction? alarma.direction: 0)+" 100 100)"} />
                        <text x="100" y="120" text-anchor="middle" font-size="10">Dirección</text>
                      </svg>
                      </div>
                      <div className='col-8'>
                        <div className="p-3">
                          <Line
                            data={getGraficaVelocidad()}
                            width={"100%"}
                            //height={"310px"}
                            options={{ maintainAspectRatio: false }}
                          />
                        </div>
                      </div>
                    </div>'
                  </div>
                </div>
                <div className='row mb-6'>
                  <div className='col'>
                    <div className="custom-controls" width="100%">
                      <button className='btn-primary p-1' id="playPause"
                        onClick={()=>{
                          for(let ref of videoRef){
                            if (ref && ref.current) {
                              setPlaying(ref.current.paused ? true: false);
                              ref.current.paused ? ref.current.play() : ref.current.pause();
                            }
                          }
                          seguirVideo();
                        }}
                      ><i className={"fa fa-"+(playing? "pause":"play")} style={{ fontSize: "20px"}} /></button>
                    </div>
                  </div>
                  <div className='col-10'>
                    <div className="progress">
                      <div className="progress-bar" role="progressbar" style={{width: (((100/10)*estatusIndex)+"%")}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div className='col'>
                    <span>00:11 / 00:{(estatusIndex<10? "0":"")+estatusIndex}</span>
                  </div>
                </div>
              </div>
            : ""}
          </div>        
        </div>
      :null}
    </SiteWrapper>
  );
}
export default ReportesAlertasCctvPage;
